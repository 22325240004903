import Navbar from "../../../components/navbar/Navbar";
import "./clientresource.scss";
import { toast, ToastContainer } from "react-toastify";
import { TablePagination, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RefreshButton } from "../../../components/common-function/RefreshButton";
import CustomNoRowsOverlay from "../../../components/CustomEmptyOverlay";
import { STATUSES } from "../../../redux/common-status/constant";
import LoaderForm from "../../../components/common-function/LoaderForm";
import UcActionComponent from "../../../components/common-function/UcActionComponent";
import DeleteDialogModal from "../../../components/common-function/DeleteDialogModal";
import TablePaginationActions from "../../../components/TablePaginationActions";
import { DefaultFilterColumns } from "../../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../../components/common-function/GetLocalstorage";
import Sidebar from "../../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { getClientResourceList, ClientResourceStatus } from "../../../redux/features/clientResourceSlice";


const AllClientResource = () => {

    const dispatch = useDispatch();
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, clientresource_List, clientresource_Create, clientresource_Update, clientresourcesList_Status } = useSelector(state => state.clientResourceSlice)
    const [currentPage, setCurrentPage] = useState(0);
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.CLIENT_RESOURCE_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');

    useEffect(() => {
        dispatch(getClientResourceList(filterQuery))

        // eslint-disable-next-line
    }, [])

    // error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)

            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    if (reload === true && status === `${STATUSES.SUCCESS}_clientresource_Update` && clientresource_Update && clientresource_Update.message) {
        if (clientresource_Update.status === 200) {
            toast.success(clientresource_Update.message)
            dispatch(getClientResourceList(filterQuery))
        } else {
            toast.error(clientresource_Update.message)
        }
        setReload(false)
    }
    if (reload === true && status === `${STATUSES.SUCCESS}_clientresourcesList_Status` && clientresourcesList_Status && clientresourcesList_Status.message) {
        if (clientresourcesList_Status.status === 200) {
            toast.success(clientresourcesList_Status.message)
            dispatch(getClientResourceList(filterQuery))
        } else {
            toast.error(clientresourcesList_Status.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_clientresource_Create` && clientresource_Create && clientresource_Create.message) {
        if (clientresource_Create.status === 200) {
            toast.success(clientresource_Create.message)
            dispatch(getClientResourceList(filterQuery))
            console.log(status)
        } else {
            toast.error(clientresource_Create.message)
        }
        setReload(false)
        console.log(status)
    }

    // const handleOpen = () => setOpen(true);


    // if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && clientresource_Edit && clientresource_Edit.data) {
    //     let editData = clientresource_Edit.data
    //     setFormValues({
    //         name: editData.name,
    //         email: editData.email,
    //         contact_no: editData.contact_no,
    //         client: editData.client,
    //         client_skill_details_ids: editData.client_skill_details_ids,
    //         tms_role: editData.tms_role,
    //         status: editData.status,
    //         id: editData?.id
    //     })
    //     setID(editData.id)
    //     setOpen(true)
    //     setReload(false)
    // }

    // const handleClickEdit = (id) => {
    //     dispatch(ClientResourceEdit(id))
    //     setReload('edit_data')
    // }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    // const handleClickArchive = (data) => {
    //     setReload(true)
    //     setArchiveOpen(false)
    //     let addvalues = {
    //         name: data.name,
    //         email: data.email,
    //         contact_no: data.contact_no,
    //         client: data.client,
    //         tms_role: data.tms_role,
    //         client_skill_details_ids: data.client_skill_details_ids,
    //         status: !data.status,
    //         updated_by: GetLocalstorage.userDetail().id
    //     }
    //     let ID = data.id;
    //     setReload(true)
    //     // dispatch(ClientResourceUpdate({ ID, addvalues }))
    //     dispatch(ClientResourceStatus({ ID, addvalues }))
    // }
    const handleStatus = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        let ID = data.id;
        setReload(true)
        // dispatch(ClientResourceUpdate({ ID, addvalues }))
        dispatch(ClientResourceStatus({ ID, addvalues }))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getClientResourceList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getClientResourceList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(clientresource_List && clientresource_List.data && clientresource_List.data.rows && clientresource_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getClientResourceList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.CLIENT_RESOURCE_LIST_COLUMNS}] }`)
        }
    }


    const actionColumn = [
        {
            field: "name",
            headerName: "Name",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.name}
                    </div>
                );
            },
        },

        {
            field: "email",
            headerName: "Email",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.email}

                    </div>
                );
            },
        },
        {
            field: "contact_no",
            headerName: "Contact No.",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.contact_no}
                    </div>
                );
            },
        },
        {
            field: "client",
            headerName: "Client",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.client__name}
                    </div>
                );
            },
        },
        {
            field: "tms_role",
            headerName: "Tms Role",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.tms_role__role_name}
                    </div>
                );
            },
        },
        // {
        //     field: "client_skill_details_ids",
        //     headerName: "Client Skill Detail",
        //     width: 280,
        //     renderCell: (params) => {
        //         return (
        //             <div>
        //                 {params.row.client_skill_details_ids}
        //             </div>
        //         );
        //     },
        // },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="client-resource"
                            rowid={params.row.id}
                            addMoreData=""
                            // editLinkUrl={`/edit-client-resource/${params.row.id}`}
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={false}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.SERVICE_TYPE_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getClientResourceList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.SERVICE_TYPE_LIST_COLUMNS}]}`)
        }
    }
    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>All Client Resource List</h3>
                        <div>
                            <Tooltip title="Add New Role">
                                <Link to="/client-resource" className="btn-add-new ">
                                    <AddIcon />
                                </Link>
                            </Tooltip>
                            <RefreshButton api={getClientResourceList} filter={filterQuery} style={{ marginTop: 0 }} />

                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        filterMode="server"
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={clientresource_List && clientresource_List.data && clientresource_List.data.rows ? clientresource_List.data.rows : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Clent Resource`}
                    handleArchive={handleStatus}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default AllClientResource;