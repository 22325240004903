import { Add } from '@mui/icons-material'
import { TablePagination, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import DeleteDialogModal from '../../../components/common-function/DeleteDialogModal'
import GetLocalstorage from '../../../components/common-function/GetLocalstorage'
import LoaderForm from '../../../components/common-function/LoaderForm'
import { RefreshButton } from '../../../components/common-function/RefreshButton'
import UcActionComponent from '../../../components/common-function/UcActionComponent'
import CustomNoRowsOverlay from '../../../components/CustomEmptyOverlay'
import { screeningFormColumns } from '../../../components/datatable-coulms/tableCoulmns'
import Navbar from '../../../components/navbar/Navbar'
import Sidebar from '../../../components/sidebar/Sidebar'
import TablePaginationActions from '../../../components/TablePaginationActions'
import { STATUSES } from '../../../redux/common-status/constant'
import { getScreeningFormList, screeningFormUpdate, screeningFormEdit } from '../../../redux/features/candidateScreeningFormSlice'
import DefaultScreeningForm from './DefaultScreeningForm'

export default function DefaultScreeningFormList() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, screeningForm_List, screeningForm_Edit, screeningForm_Update } = useSelector(state => state.candidateScreeningForm)
    const [reload, setReload] = useState(false)
    const [editFormData, setEditFormData] = useState({})

    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    const [defaultQuestion, setDefaultQuestion] = useState([])
    const [changeStatus, setChangeStatus] = useState('');


    useEffect(() => {

        dispatch(getScreeningFormList(filterQuery))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])


    useEffect(() => {
        let Questions=[]
        if (screeningForm_List?.data?.default_questions?.length > 0)
         Questions = screeningForm_List?.data.default_questions.map((item) => {
            return { screening_question: item.question_title, screening_answer: "",question_key:item.question_key, }
        })

            setDefaultQuestion(Questions)
    }, [screeningForm_List])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])
    const handleOpen = () => setOpen(true);
    // const handleOpen = () => {


    //     const formData = {
    //         form_data: [
    //             {
    //                 "screening_question": "What is Your Current CTC",
    //                 "screening_answer": "",
    //                 "question_key": "ctc"
    //             },
    //         ],
    //         "title": "Test Screening",
    //         "status": "true",
    //         "created_by": 1
    //     };
    //     // dispatch(addDefaultScreeningForm(formData));
    // };


    const handleArchive = (id) => {
        setArchiveId(id)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
        setArchiveOpen(true)
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            title: data.title,
            form_data: data.form_data,
            form_key: data.form_key,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        let ID = data.id;
        dispatch(screeningFormUpdate({ ID, addvalues }))
    }

    const handleClickEdit = (id) => {
        dispatch(screeningFormEdit(id))
        setReload('edit_data')
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && screeningForm_Edit && screeningForm_Edit.data) {
        let editData = screeningForm_Edit.data
        setEditFormData(editData)
        setOpen(true)
        setReload(false)
    }

    // if (reload === true && status === `${STATUSES.SUCCESS}_screeningForm_Archive` && screeningForm_Archive && screeningForm_Archive.message) {
    //     if (screeningForm_Archive.status === 200) {
    //         toast.success(screeningForm_Archive.message)
    //         dispatch(getScreeningFormList(filterQuery))
    //     } else {
    //         toast.error(screeningForm_Archive.message)
    //     }
    //     setReload(false)
    // }

    // action columns
    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="source-type"
                            rowid={params.row.id}
                            addMoreData={params.row.id}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            deleteButton={() => handleArchive(params.row)} />
                    </div>
                );
            },
        },
    ];

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getScreeningFormList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getScreeningFormList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(screeningForm_List && screeningForm_List.data && screeningForm_List.data.rows && screeningForm_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getScreeningFormList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
        }
    }

    //filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getScreeningFormList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getScreeningFormList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_screeningForm_Update` && screeningForm_Update && screeningForm_Update.message) {
        if (screeningForm_Update.status === 200) {
            toast.success(screeningForm_Update.message)
            dispatch(getScreeningFormList(filterQuery))
        } else {
            toast.error(screeningForm_Update.message)
        }
        setReload(false)
    }

    return (
        <div className="listing">
            <Sidebar />
            <div className="listingContainer">
                <Navbar />
                <div className="datatable">
                    <div className="datatableTitle">
                        {/* hedding */}
                        <h3>Default Screening Questions List </h3>
                        <div>
                            <Tooltip title={"Add New Default Screening Question"}>
                                <button onClick={() => handleOpen()} className="btn-add-new" style={{ marginRight: "8px" }}><Add /></button>
                            </Tooltip>
                            <RefreshButton api={getScreeningFormList} filter={filterQuery} />
                        </div>

                    </div>
                    <ToastContainer />

                    {/* listing */}
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {/* data grid (table) */}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={screeningForm_List && screeningForm_List.data && screeningForm_List.data.rows ? screeningForm_List.data.rows : []}
                        columns={screeningFormColumns.concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>
            </div>

            {/* add screening form popup */}
            {
                open === true ?
                    <DefaultScreeningForm
                        title='defaultform'
                        closeModal={setOpen}
                        editDefaultFormData={editFormData}
                        setEditDefaultFormData={setEditFormData}
                        filterQuery={filterQuery}
                        defaultQuestion={defaultQuestion}
                    />
                    : ""
            }

            {/* delete popup */}
            <DeleteDialogModal
                open={archiveOpen}
                onClose={setArchiveOpen}
                heading={changeStatus}
                paragraph={`Are you sure to ${changeStatus} default screening qusetions`}
                handleArchive={handleClickArchive}
                id={archiveId}
            />
        </div>
    )
}
