import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import './interview.scss'
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, FormControl, Rating, Stack, TablePagination, TextField, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { interviewUpdate, getInterviewList, checkManageAvlList, getcommentInterviewerAgentList, interviewFeedbackUpdate, getAllInterviewList, getHeadsDetalsOnFeedback } from "../../redux/features/interviewSlice";
import { STATUSES } from "../../redux/common-status/constant";
import * as React from 'react';
import { Button, Paper, DialogTitle, Divider } from '@mui/material';
import LoaderForm from "../../components/common-function/LoaderForm";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Close } from "@mui/icons-material";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
//import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Select from "react-select";
import { candidateOptionList, jobPostOptionList, resourceListOption, scheduleOptionList } from "../../redux/features/optionsSlice";
import { DateRangePicker } from 'rsuite';
import moment from "moment";
import ActionComponent from "./actionComponent";
import { styled } from '@mui/material/styles';
import EmailTemplet from "../../components/email-template/EmailTemplate";
import dataService from "../../redux/services/data.service";
import AddFeedBackform from "./AddFeedBackform";
import { getInterviewerFeedbackList } from "../../redux/features/masterSlice";

import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import FileViewer from "react-file-viewer";

const ROLE = GetLocalstorage.userDetail() && GetLocalstorage.userDetail().tms_role && GetLocalstorage.userDetail().tms_role

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
}));
const initialFormData = Object.freeze({

});


// const getUnique = (arr, index) => {

//     const unique = arr
//         .map(e => e[index])

//         // store the keys of the unique objects
//         .map((e, i, final) => final.indexOf(e) === i && i)

//         // eliminate the dead keys & store unique objects
//         .filter(e => arr[e]).map(e => arr[e]);

//     return unique;
// }

// const formErrData = Object.freeze({
//     descriptionErr: "Description is required",
//     interview_typeErr: "Interviews key is required",
//     interview_nameErr: "Interviews name is required",
//     statusErr: "Status is required"
// })

const { REACT_APP_API_BASEURL } = process.env;


const Interviews = ({ title }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    // const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, interview_List, interview_Edit, interview_Update, manageAvlList, commentInterviewerAgentList, interview_feedback_Update,
        interview_reschedule_request_count_list, headDetails_feedBack } = useSelector(state => state.interview)

    const { resources_interviewer, resources_agent, jobPostOptions, candidateOptions, scheduleStatusOptions } = useSelector(state => state.dropdownOptions)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(title === 'my-interview' ? ROLE && ROLE.role_key === 'agent' ?
        `{"filter":{"agent_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"}, "columns" : [${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}],"skip":0, "limit":25}`
        : `{"filter":{"interviewer_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"}, "columns" : [${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}],"skip":0, "limit":25}`
        : `{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns" : [${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}],}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [filterInputs, setFilterInputs] = useState({})

    //const [anchorEl, setAnchorEl] = useState(null);
    const [fieldType, setFieldType] = useState("")
    const [avlStatus, setAvlStatus] = useState(false)
    const [activeColumn, setActiveColumn] = useState(false)
    const [showEmailModel, setShowEmailModel] = useState(false);
    const [shiftTime, setShiftTime] = useState("Default Time (09:30-19:15)")
    const [candidateEmail, setCandidateEmail] = useState([])

    const { interviewerFeedbackList, status: feedbackStatus } = useSelector((state) => state.master);


    //const [candidateOptions, setCandidateOption] = useState([])
    // const [interViewerOptions, setInterViewerOption] = useState([])
    // const [jobOptions, setJobOption] = useState([])

    //add feedback
    const [feedBackLoading, setFeedBackLoading] = useState(false)
    const [headData, setHeadData] = useState([])
    const [feedBackArray, setFeedBackArray] = useState([{
        interview_feedback_head: "",
        rating_value: "",
        sequence_number: "",
        rating_comment: "",
        status: true
    }])

    const [addHeadData, setAddHeadData] = useState([])
    const [opnenResume, setOpenResume] = useState(false)
    const [resumeFilePath, setResumeFilePath] = useState("")
    const [resumeFileType, setResumeFileType] = useState("")


    const countRequest = () => {
        ROLE && ROLE.role_key === 'agent' && dispatch(getAllInterviewList(`{"filter":{"agent_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True, "columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`))

    }

    // const createMeetingHyperLink = (meetLink) => {
    //     if (typeof meetLink !== "string" || meetLink.trim() === "") {
    //         return "Invalid meeting link";
    //     }

    //     let meetingPlatformName = "";

    //     if (meetLink.indexOf("meet") > -1) {
    //         meetingPlatformName = "Google Meet";
    //     } else if (meetLink.indexOf("zoom") > -1) {
    //         meetingPlatformName = "Zoom";
    //     }

    //     if (meetingPlatformName) {
    //         return `<a href="${meetLink}" target="_blank">${meetingPlatformName} Meeting</a>`;
    //     } else {
    //         return "Unknown meeting platform";
    //     }
    // };

    const updateAcceptStatus = (data) => {
        let ID = data.id
        let addvalues = {
            meeting_url: data.meeting_url,
            comment: data.comment,
            comment_by: GetLocalstorage.userDetail().id,
            status: true,
        }
        let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'in_progress' && e)
        addvalues['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
        addvalues['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
        setReload(true)
        dispatch(interviewUpdate({ ID, addvalues }))
    }

    const createMeetingHyperLink = (data, meetLink) => {

        let meetingPlatformName = "";
        if (meetLink?.indexOf("meet") > -1) {
            // if (meetLink && meetLink.indexOf("meet") > -1){
            meetingPlatformName = "Google Meet";

        } else if (meetLink?.indexOf("zoom") > -1) {
            meetingPlatformName = "Zoom";
        }
        return meetingPlatformName ? <span onClick={() => updateAcceptStatus(data)}><a href={meetLink} target="_blank"> {meetingPlatformName} </a></span> : <span> NA</span>
    }
    useEffect(() => {
        dispatch(getInterviewList(filterQuery))
        // countRequest()
        dispatch(resourceListOption())
        dispatch(jobPostOptionList())
        dispatch(candidateOptionList())
        dispatch(scheduleOptionList())
        // dispatch(getInterviewerFeedbackList())
        // if (status === STATUSES.FAILURE && error) {
        //     setReload(false)
        //     errorHandling(error, navigate)
        // }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])



    // if (loadingOption === true && interview_AllList && interview_AllList.message) {
    //     let candiArray = []
    //     let interArray = []
    //     let jobArray = []
    //     if (interview_AllList.status === 200 && interview_AllList.data) {
    //         interview_AllList.data && interview_AllList.data.rows.map(e => {
    //             if (e.candidate_id) {
    //                 candiArray.push({
    //                     value: e.candidate_id,
    //                     label: e.candidate.candidate_name,
    //                 })
    //             }
    //             if (e.interviewer_id) {
    //                 interArray.push({
    //                     value: e.interviewer_id,
    //                     label: e.interviewer.display_name,
    //                 })
    //             }
    //             if (e.job_id) {
    //                 jobArray.push({
    //                     value: e.job_id,
    //                     label: e.job.job_title,
    //                 })
    //             }

    //         })
    //     }
    //     //setCandidateOption(getUnique(candiArray, 'value'))
    //     //setInterViewerOption(getUnique(interArray, 'value'))
    //     //setJobOption(getUnique(jobArray, 'value'))
    //     setLoadingOption(false)
    // }


    //const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);
        setErrStatus(false)
        dispatch(checkManageAvlList('type'))
        dispatch(getcommentInterviewerAgentList('type'))
        setAvlStatus(false)
        setAddHeadData([])
        setHeadData([])
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_interview_Update` && interview_Update && interview_Update.message) {
        if (interview_Update.status === 200) {
            toast.success(interview_Update.message)
            dispatch(getInterviewList(filterQuery))
            countRequest()
            handleClose()
        } else {
            toast.error(interview_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_interview_feedback_Update` && interview_feedback_Update && interview_feedback_Update.message) {
        if (interview_feedback_Update.status === 200) {
            toast.success(interview_feedback_Update.message)
            dispatch(getInterviewList(filterQuery))
            countRequest()
            handleClose()
        } else {
            toast.error(interview_feedback_Update.message)
        }
        setReload(false)
    }

    // heads details on the based on story_id
    if (feedBackLoading === true && status === `${STATUSES.SUCCESS}_headDetails_feedBack` && headDetails_feedBack && headDetails_feedBack.message) {
        if (headDetails_feedBack.status === 200) {
            let feedBackHead = headDetails_feedBack && headDetails_feedBack.data && headDetails_feedBack.data.rows
            setHeadData(feedBackHead)
            let array = []
            // eslint-disable-next-line
            feedBackHead && feedBackHead.map((item, i) => {
                array.push({
                    status: true,
                    interview_feedback_head: item.interview_feedback_head__id,
                    sequence_number: i + 1,
                    rating_comment: "",
                    rating_value: ""
                })
            })
            setFeedBackArray(array)
            // toast.success(headDetails_feedBack.message)
        } else {
            setHeadData([])
            // toast.error(headDetails_feedBack.message)
        }
        setFeedBackLoading(false)
    }

    const handleChange = (evt, subType) => {
        const { name, value } = evt.target || evt;
        if (name === 'scheduled_date') {
            setAvlStatus(false)
            dispatch(checkManageAvlList('type'))
        }
        setFormValues({ ...formValues, [name]: value })
    }

    const handleChangeFeedBack = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = feedBackArray.length > 0 && feedBackArray.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setFeedBackArray([...valueArray])
    }

    const handleCancelCreate = async (e) => {
        e.preventDefault()
        if (!formValues.comment) {
            setErrStatus(true)
        } else {
            const req = await dataService.resource_view(formValues.obj.interviewer_id)
            const res = req.data

            if (req && req.data) {
                setShiftTime(res.data.shift && res.data.shift.start_time ? res.data.shift.start_time + "-" + res.data.shift.end_time : "Default Time (09:30-19:15)")
            }
            const request = await dataService.get_task_By_id(formValues.obj.story_id)
            const response = request.data
            if (response && response.data) {
                if (response.data.candidate_email && response.data.candidate_email.length > 0) {
                    setCandidateEmail([response.data.candidate_email[0].email])

                }
            }
            // handleClose()
            setOpen(false)
            setShowEmailModel(true)
        }

    }

    const closeEmailModel = () => {
        setShowEmailModel(false)
    }


    const submitData = (e, type) => {
        e.preventDefault()
        let flag = false
        let dataIs = ""

        if (fieldType === 'r' && formValues.scheduled_date && formValues.scheduled_time && formValues.comment) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'reschedule' && e)
            dataIs = { ...formValues }
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            flag = true
            setErrStatus(false)
        } else {
            setErrStatus(true)
        }

        // request for reschedule section
        if (fieldType === 'rr' && formValues.scheduled_date && formValues.scheduled_time && formValues.comment) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'reschedule_request' && e)
            dataIs = { ...formValues }
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['requested_reschedule_date'] = formValues.scheduled_date ? formValues.scheduled_date : ""
            dataIs['requested_reschedule_time'] = formValues.scheduled_time ? formValues.scheduled_time : ""
            dataIs['requested_reschedule_comment'] = formValues.comment ? formValues.comment : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            flag = true
            setErrStatus(false)
        } else {
            setErrStatus(true)
        }

        if (fieldType === 'cr' && formValues.comment) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => type === 'cs' ? e.status_key === 'reschedule' : e.status_key === 'cancel_interview' && e)
            dataIs = { ...formValues }

            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['requested_schedule_date'] = formValues.obj.scheduled_date ?? ' '
            dataIs['requested_schedule_time'] = formValues.obj.scheduled_time ?? ' '
            delete dataIs['obj']

            flag = true
            setErrStatus(false)

        } else {
            setErrStatus(true)
        }

        if (fieldType === 'ci' && formValues.comment) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'cancel_interview' && e)
            dataIs = formValues
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            flag = true
            setErrStatus(false)
        } else {
            setErrStatus(true)
        }

        if (fieldType === 'd' && formValues.comment) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'done' && e)
            dataIs = formValues
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            flag = true
            setErrStatus(false)

        }
        if (fieldType === 'i' && formValues.comment) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'interrupted' && e)
            dataIs = formValues
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            flag = true
            setErrStatus(false)

        }
        else {
            setErrStatus(true)
        }

        if (fieldType === 'af' && formValues.comment) {
            // let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'done' && e)
            // dataIs = formValues
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            // dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            // dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            // dataIs['feedback_by_interviewer'] = formValues.comment
            // dataIs['feedback_by_interviewer_resource'] = GetLocalstorage.userDetail().id

            flag = true
            setErrStatus(false)

        } else {
            setErrStatus(true)
        }

        if (fieldType === 'as') {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'accept_schedule' && e)
            dataIs = formValues
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""

            flag = true
            setErrStatus(false)

        } else {
            setErrStatus(true)
        }

        if (fieldType === 's' && formValues.scheduled_date) {
            let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'in_progress' && e)
            dataIs = { ...formValues }
            //dataIs['interview_status'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            dataIs['status_key'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].status_key : ""
            dataIs['status_id'] = interviewer_status_id.length > 0 ? interviewer_status_id[0].id : ""
            flag = true
            setErrStatus(false)
        } else {
            setErrStatus(true)
        }

        if (flag === true) {

            setReload(true)
            let addvalues = dataIs
            // if (fieldType === 'af') {
            //     delete addvalues['comment']
            //     delete addvalues['comment_by']
            // }
            if (fieldType !== 'r' || fieldType !== 'rr') {
                delete addvalues['scheduled_date']
                delete addvalues['scheduled_time']
            }
            delete addvalues['interviewer_id']
            delete addvalues['interview_status']
            if (ID > 0) {
                if (fieldType === 'af') {
                    let finalData = {
                        feedback: formValues.comment,
                        interview_details: formValues.id,
                        jira_rtms_issue: formValues.story_id,
                        feedback_details: headData && headData.length > 0 ? feedBackArray : addHeadData
                    }
                    dispatch(interviewFeedbackUpdate(finalData))
                } else if (fieldType === 'rr') {
                    dispatch(interviewUpdate({ ID, addvalues }))
                } else if (fieldType === 's') {
                    dispatch(interviewUpdate({ ID, addvalues }))
                    window.open(addvalues.meeting_url)

                } else {
                    dispatch(interviewUpdate({ ID, addvalues }))
                }
            }
        }

        // if (formValues.interview_type === "" || formValues.interview_name === "" || formValues.description === "" || formValues.status === "") {
        //     setErrStatus(true)
        // }

        // if (formValues.interview_type !== "" && formValues.interview_name !== "" && formValues.description !== "" && formValues.status !== "") {
        //     let userId = GetLocalstorage.userDetail().id
        //     let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
        //     setErrStatus(false)
        //     setReload(true)
        //     handleClose()
        //     if (ID) {
        //         dispatch(interviewUpdate({ ID, addvalues }))
        //     } else {
        //         dispatch(interviewCreate(addvalues))
        //     }
        // }
    }


    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && interview_Edit && interview_Edit.data) {
        let editData = interview_Edit.data
        setFormValues({
            interview_name: editData.interview_name,
            interview_type: editData.interview_type,
            description: editData.description,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    // const handleClickEdit = (id) => {
    //     dispatch(interviewEdit(id))
    //     setReload('edit_data')
    // }
    const onfilterChange = (evt) => {
        const { value, name } = evt.target || evt
        if (name === 'interview_link') {
            setFilterInputs({ ...filterInputs, [name]: filterInputs.interview_link ? !filterInputs.interview_link : true })

        } else if (name === 'attachemnt') {
            setFilterInputs({ ...filterInputs, [name]: filterInputs.attachemnt ? !filterInputs.attachemnt : true })

        } else {
            setFilterInputs({ ...filterInputs, [name]: value })
        }

    }

    const refresh = () => {
        dispatch(getInterviewList(filterQuery))
        countRequest()
    }

    const clear = () => {
        setFilterInputs({
            interviewer_name: null,
            candidate_name: null,
            status: null,
            scheduled_by: null,
            job_title: '',
            feedback_by_interviewer: "",
            interview_link: "",
            meeting_url: "",
            date: '',
            time: '',
            interview_message: ''
        })
        let filter = title === 'my-interview' ? ROLE && ROLE.role_key === 'agent' ?
            `{"filter":{"agent_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
            : `{"filter":{"interviewer_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
            : `{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
        setFilterQuery(filter)
        dispatch(getInterviewList(filter))
        dispatch(getcommentInterviewerAgentList('type'))
        countRequest()
    }

    const search = () => {
        let filter = { "filter": {}, "order_by": { "column": "-id" }, "skip": 0, "limit": limit, "columns": DefaultFilterColumns.INTERVIEW_LIST_COLUMNS_JSON }
        if (title === 'my-interview' && GetLocalstorage.userDetail().is_interviewer === true && ROLE && (ROLE.role_key !== 'agent' || ROLE.role_key !== 'admin')) {
            filter = { "filter": { "interviewer_id": { "value": GetLocalstorage.userDetail().id, "operation": "eq" } }, "skip": 0, "limit": limit, "order_by": { "column": "-id" }, "columns": DefaultFilterColumns.INTERVIEW_LIST_COLUMNS_JSON }
        }
        else if (title === 'my-interview' && ROLE && ROLE.role_key === 'agent') {
            filter = { "filter": { "agent_id": { "value": GetLocalstorage.userDetail().id, "operation": "eq" } }, "skip": 0, "limit": limit, "order_by": { "column": "-id" }, "columns": DefaultFilterColumns.INTERVIEW_LIST_COLUMNS_JSON }

        }
        if (filterInputs.interviewer_name) {
            if (title === 'my-interview' && GetLocalstorage.userDetail().is_interviewer === true && ROLE && (ROLE.role_key !== 'agent' || ROLE.role_key !== 'admin')) {
                filter['filter']['interviewer_id'] = { "value": GetLocalstorage.userDetail().id, "operation": "eq" }
            } else {
                filter['filter']['interviewer_id'] = { "value": filterInputs.interviewer_name.value, "operation": "eq" }

            }
        }
        if (filterInputs.interviewer_name === "") {
            delete filter['filter']['interviewer_id']
        }
        if (filterInputs.candidate_name) {
            filter['filter']['candidate_id'] = { "value": filterInputs.candidate_name.value, "operation": "eq" }
        }
        if (filterInputs.candidate_name === '') {
            delete filter['filter']['candidate_id']
        }
        if (filterInputs.interview_message) {
            filter['filter']['interview_message'] = { "value": filterInputs.interview_message, "operation": "contains" }
        }
        if (filterInputs.interview_message === '') {
            delete filter['filter']['interview_message']
        }
        if (filterInputs.feedback_by_interviewer) {
            filter['filter']['feedback_by_interviewer'] = { "value": filterInputs.feedback_by_interviewer, "operation": "contains" }
        }
        if (filterInputs.feedback_by_interviewer === '') {
            delete filter['filter']['feedback_by_interviewer']
        }
        if (filterInputs.interview_link) {
            filter['filter']['meeting_url'] = { "value": filterInputs.interview_link, "operation": "eq" }
        }
        if (filterInputs.attachemnt) {
            filter['filter']['attachemnt'] = { "value": filterInputs.attachemnt, "operation": "eq" }
        }
        if (filterInputs.job_title) {
            filter['filter']['job_id'] = { "value": filterInputs.job_title.value, "operation": "eq" }
        }
        if (filterInputs.job_title === '') {
            delete filter['filter']['job_id']
        }
        if (filterInputs.time) {
            filter['filter']['scheduled_time'] = { "value": filterInputs.time, "operation": "eq" }
        }
        if (filterInputs.date) {
            let date_filter = { "value": [moment(filterInputs.date[0]).format('YYYY-MM-DD'), moment(filterInputs.date[1]).format('YYYY-MM-DD')], "operation": "range" }
            filter['filter']['scheduled_date'] = date_filter
        }
        if (filterInputs.scheduled_by) {
            if (title === 'my-interview' && ROLE && ROLE.role_key === 'agent') {
                filter['filter']['agent_id'] = { "value": GetLocalstorage.userDetail().id, "operation": "eq" }

            } else {
                filter['filter']['agent_id'] = { "value": filterInputs.scheduled_by.value, "operation": "eq" }

            }
        }
        if (filterInputs.scheduled_by === '') {
            delete filter['filter']['agent_id']
        }
        if (filterInputs.status) {
            filter['filter']['interview_status_id'] = { "value": filterInputs.status.value, "operation": "eq" }
        }
        if (filterInputs.status === '') {
            delete filter['filter']['interview_status_id']
        }
        setFilterQuery(JSON.stringify(filter))
        dispatch(getInterviewList(JSON.stringify(filter)))
        countRequest()

    }

    const activeModel = (obj, type) => {
        setID(obj.id)
        // let key = type === 'rr' || type === 'as' ||  type === 'af' ? 'comment_by_interviewer' : 'comment_by_agent'
        // if (type === 'ci' || type === 'ad') {
        //     if (GetLocalstorage.userDetail().tms_role && GetLocalstorage.userDetail().tms_role.role_key === 'agent' && GetLocalstorage.userDetail().id === obj.agent_id) {
        //         key = 'comment_by_agent'
        //     } else {
        //         key = 'comment_by_interviewer'
        //     }
        // }
        if (type === 'sc') {
            let filter = { "filter": { "interviewer_details_id": { "value": obj.id, "operation": "eq" } }, "order_by": { "column": "-id" }, "no_limit": 'True' }
            //let filter = { "filter": { "interviewer_detail_id": { "value": 27, "operation": "eq" }}}

            dispatch(getcommentInterviewerAgentList(JSON.stringify(filter)))
        }


        if (type === 'af') {
            setFeedBackLoading(true)
            dispatch(getHeadsDetalsOnFeedback(obj.story_id))
            setFormValues(obj)

        } else if (type === 'rr') {
            setFormValues({ scheduled_date: obj.scheduled_date, scheduled_time: obj.scheduled_time, comment: "", comment_by: GetLocalstorage.userDetail().id })
        } else if (type === "vf") {
            dispatch(getInterviewerFeedbackList(obj.story_id))

        } else if (type === 'cr') {
            setFormValues({ comment: "", comment_by: GetLocalstorage.userDetail().id, obj: obj })

        } else if (type === 's') {
            setFormValues({ scheduled_date: obj.scheduled_date, scheduled_time: obj.scheduled_time, meeting_url: obj.meeting_url, comment: "", comment_by: GetLocalstorage.userDetail().id, interview_status: obj.interview_status__status_key, interviewer_id: obj.interviewer_id, status: obj.status })
        }
        else {
            setFormValues({ scheduled_date: obj.scheduled_date, scheduled_time: obj.scheduled_time, meeting_url: obj.meeting_url, comment: "", comment_by: GetLocalstorage.userDetail().id, interview_status: obj.interview_status__status_key, interviewer_id: obj.interviewer_id, status: obj.status })
        }


        setFieldType(type)
        setOpen(true)
    }

    const checkAvailability = (e) => {
        e.preventDefault()
        //let filter = { "interviewer_id": 14, "schedule_date": '2023-04-29' }
        let filter = { "interviewer_id": formValues.interviewer_id ? formValues.interviewer_id : GetLocalstorage.userDetail().id, "schedule_date": formValues.scheduled_date }
        //let filter = { "filter": { "resource_id": { "value": formValues.interviewer_id ? formValues.interviewer_id : GetLocalstorage.userDetail().id, "operation": "eq" }, "schedule_date": { "value": formValues.scheduled_date, "operation": "eq" } } }
        setAvlStatus(true)
        dispatch(checkManageAvlList(JSON.stringify(filter)))
    }

    const filterRescheduleRequest = () => {
        // eslint-disable-next-line
        let interviewer_status_id = scheduleStatusOptions.filter(e => e.status_key === 'reschedule_request' || e.status_key === 'request_to_reschedule' && e)
        let filter = { "filter": { "agent_id": { "value": GetLocalstorage.userDetail().id, "operation": "eq" } }, "order_by": { "column": "-id" }, "skip": 0, "limit": limit, "columns": DefaultFilterColumns.INTERVIEW_LIST_COLUMNS_JSON }

        if (interviewer_status_id.length > 0 && activeColumn === false) {
            filter['filter']['interview_status_id'] = { "value": interviewer_status_id[0].id, "operation": "eq" }
            setFilterQuery(JSON.stringify(filter))
            dispatch(getInterviewList(JSON.stringify(filter)))
            countRequest()
        } else {
            setFilterQuery(JSON.stringify(filter))
            dispatch(getInterviewList(JSON.stringify(filter)))
            countRequest()
        }
        setActiveColumn(!activeColumn)

    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getInterviewList(JSON.stringify(newFilterData)))
        countRequest()
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getInterviewList(JSON.stringify(newFilterData)))
        countRequest()
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(interview_List && interview_List.data && interview_List.data.rows && interview_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getInterviewList(JSON.stringify(newFilterData)))
            countRequest()
        } else {
            let filter = title === 'my-interview' ? ROLE && ROLE.role_key === 'agent' ?
                `{"filter":{"agent_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
                : `{"filter":{"interviewer_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
                : `{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
            setFilterQuery(filter)
            // setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
        }
    }


    const actionColumn = [
        { field: "id", headerName: "ID", width: 70, filterable: false, },
        {
            field: "candidate", headerName: "Candidate Name", width: 230, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div onClick={() => handleResumeOpen(params.row)} className={`cellWithStatus`} style={{ cursor: "pointer" }}>
                        <Tooltip title={params.row.candidate__candidate_name}>
                            <p>{params.row.candidate__candidate_name}</p>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            field: "interviewer", headerName: "Interviewer Name", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.interviewer__display_name}
                    </div>
                )
            }
        },
        {
            field: "agent_id", headerName: "Scheduled By", width: 230, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.agent__display_name}
                    </div>
                )
            }
        },

        {
            field: "meeting_url", headerName: "Meetings", width: 300, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        <Tooltip title={params.row.meeting_url}>
                            {/* <p>{params.row.meeting_url}</p> */}
                            {createMeetingHyperLink(params.row, params.row.meeting_url)}
                        </Tooltip>
                    </div>

                )
            }
        },
        {
            field: "scheduled_date", headerName: "Scheduled Date", width: 150, filterable: false, type: 'date',
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.scheduled_date ? moment(params.row.scheduled_date).format('DD-MM-YYYY') : "N/A"}
                    </div>
                );
            },
        },
        { field: "scheduled_time", headerName: "Scheduled Time", width: 150, filterable: false },
        {
            field: "job", headerName: "Job Title", width: 230, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        <Tooltip title={params.row.job__job_title}>
                            <p>{params.row.job__job_title}</p>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            field: "subject", headerName: "Interview Message", width: 300, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        <Tooltip title={params.row.subject}>
                            <p>{params.row.subject}</p>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            field: "feedback_by_interviewer", headerName: "Interviewer Feedback", width: 300, filterable: false, sortable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>

                        <Tooltip title={params.row.feedback_by_interviewer}>
                            <p>{params.row.feedback_by_interviewer}</p>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            field: "interview_status_id",
            headerName: "Interview Status",
            width: 300,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>

                        {params.row.interview_status__status_name}
                        {params.row.interviewer_id === GetLocalstorage.userDetail().id && params.row.interview_status__status_key !== 'accept_schedule' && params.row.interview_status__status_key !== 'done' && params.row.interview_status__status_key !== 'cancel_interview'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <ActionComponent
                            activeModel={activeModel}
                            params={params}
                            userId={GetLocalstorage.userDetail().id}
                        />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}},"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS_JSON}]`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getInterviewList(filterData))
            countRequest()
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            let filter = title === 'my-interview' ? ROLE && ROLE.role_key === 'agent' ?
                `{"filter":{"agent_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
                : `{"filter":{"interviewer_id":{"value":${GetLocalstorage.userDetail().id}, "operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
                : `{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.INTERVIEW_LIST_COLUMNS}]}`
            setFilterQuery(filter)
            // setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getInterviewList(filter))
            countRequest()
        }
    }


    const handleResumeOpen = (data) => {
        const resumeId = data?.candidate__file_upload__id
        const fileUrlSplit = data?.candidate__file_upload__file_path.split(".")
        const fileTypeIndex = fileUrlSplit.length - 1
        setResumeFilePath(REACT_APP_API_BASEURL + "recruitment/resume/download/" + resumeId)
        setResumeFileType(fileUrlSplit[fileTypeIndex])
    }

    useEffect(() => {
        if (resumeFilePath !== "" && resumeFileType !== "") {
            setOpenResume(true)
        }
    }, [resumeFilePath, resumeFileType])

    const handleResumeClose = () => {
        setOpenResume(false)
    }



    return (
        <div className="interviewListing">
            <Sidebar />
            <div className="interviewListingContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>{title === 'my-interview' ? 'My' : 'All'} Interviews List</h3>
                        <div>
                            {/* <Tooltip title={"Add Interviews"}>
                                <button style={{ marginRight: 5 }} onClick={handleOpen} className="btn-add-new ">
                                    <Add
                                    />
                                </button>
                            </Tooltip> */}
                            <RefreshButton api={getInterviewList} filter={filterQuery} style={{ marginTop: 0 }} />
                        </div>
                    </div>
                    {(status === `${STATUSES.LOADING}_interview` || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <div style={{ display: "flex", width: '100%', height: '30px', marginTop: '20px' }}>
                        <FormControl style={{ flexBasis: "30%", marginRight: '3px' }}>
                            {/* <input
                                type="text"
                                name={'interviewer_name'}
                                onChange={(evt) => onfilterChange(evt)}
                                defaultValue={filterInputs.interviewer_name}
                                placeholder="Interviewer Name"
                                style={{
                                    minHeight: '38px',
                                    paddingLeft: '10px',
                                    borderRadius: '5px',
                                    borderWidth: '0.5px',
                                    borderColor: '#a6a6a66b'
                                }}

                            /> */}
                            <Select
                                name="interviewer_name"
                                placeholder="Select Interviewer Name"
                                value={title === 'my-interview' && ROLE && ROLE.role_key !== 'agent' ? { value: GetLocalstorage.userDetail().id, label: GetLocalstorage.userDetail().display_name } : filterInputs.interviewer_name}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, interviewer_name: evt }) }}
                                width='300px'
                                isDisabled={title === 'my-interview' && ROLE && ROLE.role_key !== 'agent' ? true : false}
                                options={resources_interviewer} />
                        </FormControl>

                        <FormControl style={{ flexBasis: "30%", marginRight: '3px' }}>
                            {/* <input
                                type="text"
                                name={'candidate_name'}
                                onChange={(evt) => onfilterChange(evt)}
                                defaultValue={filterInputs.candidate_name}
                                placeholder="Candidate Name"
                                style={{
                                    minHeight: '38px',
                                    paddingLeft: '10px',
                                    borderRadius: '5px',
                                    borderWidth: '0.5px',
                                    borderColor: '#a6a6a66b'
                                }}


                            /> */}
                            <Select
                                name="candidate_name"
                                placeholder="Select Candidate Name"
                                value={filterInputs.candidate_name}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, candidate_name: evt }) }}
                                width='300px'
                                options={candidateOptions} />
                        </FormControl>
                        <FormControl style={{ flexBasis: "30%", marginRight: '3px' }}>
                            <Select
                                name="scheduled_by"
                                placeholder="Select Scheduled By"
                                value={title === 'my-interview' && ROLE && ROLE.role_key === 'agent' ? { value: GetLocalstorage.userDetail().id, label: GetLocalstorage.userDetail().display_name } : filterInputs.scheduled_by}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, scheduled_by: evt }) }}
                                width='300px'
                                isDisabled={title === 'my-interview' && ROLE && ROLE.role_key === 'agent' ? true : false}
                                options={resources_agent} />
                        </FormControl>
                        <FormControl style={{ flexBasis: "30%" }}>
                            <Select
                                name="status"
                                placeholder="Select Status"
                                value={filterInputs.status}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, status: evt }) }}
                                width='300px'
                                options={scheduleStatusOptions} />
                        </FormControl>
                        {/* <FormControl style={{ flexBasis: "30%", marginRight: '3px' }}>
                          
                            <Select
                                name="job_title"
                                placeholder="Select Job Title"
                                value={filterInputs.job_title}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, job_title: evt }) }}
                                width='300px'
                                options={jobPostOptions} />
                        </FormControl> */}
                        {/* <FormControl style={{ flexBasis: "30%", marginRight: '3px' }}>
                            <input
                                type="text"
                                name={'interview_message'}
                                onChange={(evt) => onfilterChange(evt)}
                                value={filterInputs.interview_message}
                                placeholder="Interview Message"
                                style={{
                                    minHeight: '38px',
                                    paddingLeft: '10px',
                                    borderRadius: '5px',
                                    borderWidth: '0.5px',
                                    borderColor: '#a6a6a66b'
                                }}


                            />
                        </FormControl> */}
                        {/* <FormControl style={{ flexBasis: "30%" }}>
                            <input
                                type="text"
                                name={'feedback_by_interviewer'}
                                onChange={(evt) => onfilterChange(evt)}
                                value={filterInputs.feedback_by_interviewer}
                                placeholder="Feedback by Interviewer"
                                style={{
                                    minHeight: '38px',
                                    paddingLeft: '10px',
                                    borderRadius: '5px',
                                    borderWidth: '0.5px',
                                    borderColor: '#a6a6a66b'
                                }}


                            />
                        </FormControl> */}

                    </div>
                    <div style={{ display: "flex", width: '100%', marginTop: 20, marginBottom: 30 }}>
                        <FormControl style={{ flexBasis: "30%", marginRight: '3px' }}>

                            <Select
                                name="job_title"
                                placeholder="Select Job Title"
                                value={filterInputs.job_title}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, job_title: evt }) }}
                                width='300px'
                                options={jobPostOptions} />
                        </FormControl>
                        <FormControl style={{ flexBasis: "20%", marginRight: '3px' }}>
                            <DateRangePicker
                                format="dd-MM-yyyy"
                                placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
                                showMeridian
                                value={filterInputs.date}
                                onChange={(evt) => { setFilterInputs({ ...filterInputs, date: evt }) }}
                            />
                            {/* <TextField
                                id="interviewdate"
                                type="date"
                                name={'date'}
                                onChange={(evt) => onfilterChange(evt)}
                                value={filterInputs.date}
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            inputProps={{ min: minDate, max: null }}
                            /> */}
                        </FormControl>
                        <FormControl style={{ flexBasis: "10%", marginRight: '3px' }}>
                            <TextField
                                //style={{ height: '35px', borderColor: '#ddd' }}
                                id="interviewtime"
                                type="time"
                                name="time"
                                value={filterInputs.time}
                                onChange={(evt) => { onfilterChange(evt) }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                                sx={{ width: 150 }}


                            />
                        </FormControl>
                        {/* <FormControl>
                            <Checkbox name="interview_link" onChange={(evt) => { onfilterChange(evt) }} checked={filterInputs.interview_link === true ? true : false} />
                        </FormControl>
                        <p style={{ textAlign: 'left', flexBasis: "8%", marginTop: '11px' }}>Interview Link</p>
                        <FormControl>
                            <Checkbox name="attachemnt" onChange={(evt) => { onfilterChange(evt) }} checked={filterInputs.attachemnt === true ? true : false} />
                        </FormControl>
                        <p style={{ textAlign: 'left', flexBasis: "8%", marginTop: '11px' }}>Attachment</p> */}
                        {ROLE && ROLE.role_key === 'agent' && <button disabled={(status === `${STATUSES.LOADING}__interview`) ? true : false} className="btn-sm filter-btn-primary" style={(status === `${STATUSES.LOADING}__interview`) ? { flexBasis: "auto", marginLeft: "auto", backgroundColor: 'grey' } : { flexBasis: "auto", marginLeft: "auto" }} onClick={() => filterRescheduleRequest()} >Reschedule Requests ({interview_reschedule_request_count_list.length})</button>}
                        <button disabled={(status === `${STATUSES.LOADING}__interview`) ? true : false} className="btn-sm filter-btn-primary" style={(status === `${STATUSES.LOADING}__interview`) ? { flexBasis: "auto", marginLeft: ROLE && ROLE.role_key === 'agent' ? "6px" : "auto", backgroundColor: 'grey' } : { flexBasis: "auto", marginLeft: ROLE && ROLE.role_key === 'agent' ? "6px" : "auto" }} onClick={() => search()} >Search</button>
                        <button disabled={(status === `${STATUSES.LOADING}__interview`) ? true : false} className="btn-sm filter-btn-primary" style={(status === `${STATUSES.LOADING}__interview`) ? { flexBasis: "auto", marginLeft: "6px", backgroundColor: 'grey' } : { flexBasis: "auto", marginLeft: "6px" }} onClick={() => clear()} >Clear</button>
                        <button disabled={(status === `${STATUSES.LOADING}__interview`) ? true : false} className="btn-sm filter-btn-primary" style={(status === `${STATUSES.LOADING}__interview`) ? { flexBasis: "auto", marginLeft: "6px", backgroundColor: 'grey' } : { flexBasis: "auto", marginLeft: "6px" }} onClick={() => refresh()}>Refresh</button>
                    </div>

                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={interview_List && interview_List.data && interview_List.data.rows ? interview_List.data.rows : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={opnenResume}
                    onClose={handleResumeClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleResumeClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}> Candidate Resume</DialogTitle>

                    <DialogContent>
                        {
                            resumeFileType === "doc" ?
                                <iframe className="google-docs iframe" src={`https://docs.google.com/viewer?url=${resumeFilePath}&embedded=true`} frameborder="0"
                                    scrolling="no" marginHeight="0" marginWidth="0" role="document" aria-label="PDF document" title="PDF document" style={{ borderStyle: 'none', width: '100%', height: '800px' }}></iframe>

                                : <FileViewer fileType={resumeFileType} filePath={resumeFilePath} />
                        }

                    </DialogContent>

                </Dialog>

                {
                    feedbackStatus === "loading" ?
                        <LoaderForm />
                        :
                        <Dialog
                            hideBackdrop
                            fullWidth
                            maxWidth={"md"}
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="child-modal-title"
                            aria-describedby="child-modal-description"
                        >
                            <Button
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                style={{ position: "absolute", top: "10px", right: "10px" }}>
                                <Close />
                            </Button>
                            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                                {fieldType === 'rr' ? 'Reschedule Request' :
                                    fieldType === 'af' ? 'Add Feedback' :
                                        fieldType === 'cr' ? 'Confirm Reschedule' :
                                            fieldType === 'ci' ? 'Cancel Interview' :
                                                fieldType === 'as' ? 'Accept Schedule' :
                                                    fieldType === 'd' ? ' Interview Done Request' :
                                                        fieldType === 's' ? 'Start' :
                                                            fieldType === 'vf' ? 'view feedback' :
                                                                fieldType === 'i' ? 'Interrupted' :
                                                                    fieldType === 'sc' ? ` Show Comments ${commentInterviewerAgentList && commentInterviewerAgentList.data ? `- (${commentInterviewerAgentList.data.count})` : ''}` :
                                                                        'Reschedule'}</DialogTitle>

                            <DialogContent>
                                {fieldType === 'sc' ?
                                    <div>
                                        {commentInterviewerAgentList && commentInterviewerAgentList.data && commentInterviewerAgentList && commentInterviewerAgentList.data.rows && commentInterviewerAgentList.data.rows.map((e, i) => {
                                            return (
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td class="text-dark">Comment </td>
                                                            <td> :</td>
                                                            <td> {e.comment}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-dark">Comment By  </td>
                                                            <td> :</td>
                                                            <td> {e.comment_by && e.comment_by.display_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-dark">Against Request </td>
                                                            <td> :</td>
                                                            <td> {e.previous_interview_status ? e.previous_interview_status.status_name : ''} - {e.interview_status ? e.interview_status.status_name : ''}</td>
                                                        </tr>
                                                    </table>
                                                    {/* <p style={{display:'flex'}}><span style={{marginRight : 5, fontWeight:'bold'}}>Comment : </span> {e.comment}</p>
                                            <p style={{display:'flex', marginBottom:'15px'}}><span style={{marginRight : 5, fontWeight:'bold'}}>Comment By: </span> {e.comment_by && e.comment_by.display_name}</p>
                                            <p style={{display:'flex'}}><span style={{marginRight : 5, fontWeight:'bold'}}>Against Request: </span>{e.interviewer_detail ? e.interviewer_detail.interview_status ? e.interviewer_detail.interview_status.status_name : '' : ''}</p> */}
                                                </div>
                                            )
                                        })}
                                        {commentInterviewerAgentList && commentInterviewerAgentList.status === 400 &&

                                            <p>{commentInterviewerAgentList.message}</p>
                                        }
                                    </div>
                                    :
                                    fieldType === 'cr' ?
                                        <div>
                                             <p><span style={{ fontWeight: 'bold' }}>Job Title: </span>{formValues && formValues.obj ? formValues?.obj?.job__job_title : 'No job found'}</p>
                                            <p><span style={{ fontWeight: 'bold' }}>Candidate Name :</span> {formValues && formValues.obj && formValues.obj.candidate__candidate_name && formValues.obj.candidate__candidate_name}</p>
                                            <p><span style={{ fontWeight: 'bold' }}>Interviewer Name : </span> {formValues && formValues.obj && formValues.obj.interviewer__display_name && formValues.obj.interviewer__display_name}</p>
                                            <p style={{ padding: '10px' }}><span style={{ fontWeight: 'bold' }}>Scheduled Date : </span>{formValues && formValues.obj && formValues.obj.scheduled_date} <span style={{ fontWeight: 'bold', marginLeft: '20px' }}> Scheduled Time : </span>{formValues && formValues.obj && formValues.obj.scheduled_date && formValues.obj.scheduled_time + "-" + formValues.obj.scheduled_end_time}</p>
                                            <p style={{ padding: '10px' }}><span style={{ fontWeight: 'bold' }}>New Scheduled Date:</span> {formValues && formValues.obj && formValues.obj.requested_reschedule_date} <span style={{ fontWeight: 'bold', marginLeft: '20px' }}>New Scheduled Time : </span>{formValues && formValues.obj && formValues.obj.requested_reschedule_time}</p>
                                            <p style={{ marginBottom: '10px' }}><span style={{ fontWeight: 'bold' }}>Comment for reschedule request : </span>{formValues && formValues.obj && formValues.obj.requested_reschedule_comment}</p>
                                            <form>
                                                <div className="add-new-user-form">
                                                    <div className="formInput" style={{ flexBasis: '100%', marginTop: '20px' }}>
                                                        <label >
                                                            Comment/ Reason
                                                        </label>

                                                        <textarea defaultValue={formValues.comment} name={'comment'} onChange={handleChange} rows={3} placeholder={'comment'}></textarea>
                                                        {errStatus === true && (!formValues.comment || formValues.comment === "") && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}
                                                    </div>
                                                </div>
                                            </form>
                                            {reload === false && <button style={{ backgroundColor: '#0052cc', color: '#fff', padding: '12px', borderRadius: '6px', fontWeight: '500', margin: '10px' }} onClick={(e) => submitData(e, 'cs')} >Confirm schedule</button>}
                                            {reload === false && <button style={{ backgroundColor: '#e31616', color: '#fff', padding: '12px', borderRadius: '6px', margin: '10px', fontWeight: '500' }} onClick={(e) => submitData(e, 'ci')}>Cancel Interview</button>}
                                            {reload === false && <button style={{ backgroundColor: '#00bcd4', color: '#fff', padding: '12px', borderRadius: '6px', margin: '10px', fontWeight: '500' }} onClick={(e) => handleCancelCreate(e)}>Cancel & Create New</button>}

                                        </div>
                                        :
                                        fieldType === 'vf' ?
                                            <div>
                                                <div>
                                                    <table>
                                                        <thead>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                interviewerFeedbackList && interviewerFeedbackList?.data && interviewerFeedbackList?.data.map((feedback, i) => (
                                                                    <div className="widget" style={{ marginBottom: "16px", width: "600px", height: "auto" }}>
                                                                        <div className="left">
                                                                            <Tooltip title="Interviewer Name">
                                                                                <span className="counter" style={{ fontSize: "20px" }}>{feedback?.interviewer_name || ""}</span>
                                                                            </Tooltip>
                                                                            <Tooltip title="Interview Date">
                                                                                <span className="title">{feedback?.interview_date || ""}</span>
                                                                            </Tooltip>
                                                                            <Tooltip title="Interviewer Feedback">
                                                                                <span className="title">{feedback?.feedback || ""}</span>
                                                                            </Tooltip>
                                                                            <Tooltip title={"Overall Average Rating :" + feedback?.avg_rating}>
                                                                                <span className="counter">
                                                                                    <Rating
                                                                                        value={feedback?.avg_rating || 0}
                                                                                        name={'avg_rating'}
                                                                                        precision={0.1}
                                                                                        readOnly
                                                                                    />
                                                                                </span>
                                                                            </Tooltip>
                                                                            <Divider />
                                                                            <br />
                                                                            {feedback && feedback.feedback_details && feedback.feedback_details.length > 0 && feedback.feedback_details.map((item) => {
                                                                                return <>
                                                                                    <span className="title" style={{ fonstSize: "14px", fontWeight: "bold" }}>{item?.head_comment_name || ""}
                                                                                        <Rating
                                                                                            value={item?.rating_value || 0}
                                                                                            name={'rating_value'}
                                                                                            readOnly
                                                                                            precision={0.1}

                                                                                        />
                                                                                    </span>
                                                                                    <span className="title">{item?.rating_comment || ""}</span>
                                                                                </>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            : <form>
                                                <div className="add-new-user-form">
                                                    {(fieldType === 'r' || fieldType === 'rr' || fieldType === 'cr' || fieldType === 'as') &&
                                                        <FormControl>
                                                            <TextField
                                                                id="interviewdate"
                                                                type="date"
                                                                name={'scheduled_date'}
                                                                onChange={(evt) => handleChange(evt)}
                                                                value={formValues.scheduled_date}
                                                                sx={{ width: 220 }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={(fieldType === 's' || fieldType === 'as') ? true : false}
                                                                inputProps={{ min: moment().format('YYYY-MM-DD'), max: null }}
                                                            />
                                                            {((fieldType === 'r' || fieldType === 'rr' || fieldType === 'cr') && errStatus === true && (!formValues.scheduled_date || formValues.scheduled_date === "")) && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}

                                                        </FormControl>
                                                    }

                                                    {(fieldType === 's') &&
                                                        <div>
                                                            <label> Meeting Url</label> <br />
                                                            <FormControl>
                                                                <TextField
                                                                    id=""
                                                                    type="text"
                                                                    name={''}
                                                                    onChange={(evt) => handleChange(evt)}
                                                                    value={formValues.meeting_url}
                                                                    sx={{ width: 220, my: 1 }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    disabled={true}
                                                                />

                                                            </FormControl> <br />
                                                            {/* <button onClick={() => {
                                                    window.open(formValues.meeting_url)
                                                    dispatch(interviewUpdate())
                                                    submitData()
                                                }}
                                                    type="button"
                                                    style={{ padding: '5px 10px', background: '#0052cc', color: 'white', borderRadius: '5px' }}
                                                >

                                                    Join Meeting
                                                </button> */}
                                                        </div>
                                                    }
                                                    {(fieldType === 'r' || fieldType === 'rr' || fieldType === 'cr' || fieldType === 'as') &&
                                                        <FormControl style={{ flexBasis: '20%', marginRight: '432px' }} >
                                                            <TextField
                                                                //style={{ height: '35px', borderColor: '#ddd' }}
                                                                id="interviewtime"
                                                                type="time"
                                                                name="scheduled_time"
                                                                value={formValues.scheduled_time}
                                                                onChange={(evt) => { handleChange(evt) }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={fieldType === 'as' ? true : false}
                                                                inputProps={{
                                                                    step: 300, // 5 min
                                                                }}
                                                                sx={{ width: 150 }}

                                                            />
                                                            {((fieldType === 'r' || fieldType === 'rr' || fieldType === 'cr') && errStatus === true && (!formValues.scheduled_time || formValues.scheduled_time === "")) && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}

                                                        </FormControl>
                                                    }
                                                    {(fieldType === 'r' || fieldType === 'cr') && formValues.scheduled_date && manageAvlList && manageAvlList.data && manageAvlList.data.default_availability_from_settings &&
                                                        <FormControl>
                                                            <Stack spacing={{ xs: 1, sm: 2 }} style={{ marginTop: '10px' }} direction="row" useFlexGap flexWrap="wrap">
                                                                {
                                                                    manageAvlList.data.default_availability_from_settings.length > 0 && manageAvlList.data.default_availability_from_settings.map((e, i) => {
                                                                        return (
                                                                            <Item key={i}>{e.availability_from} - {e.availability_to}</Item>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    manageAvlList.data.default_availability_from_settings.length === 0 &&
                                                                    <Item>No schedule found for this date !</Item>

                                                                }

                                                            </Stack>
                                                        </FormControl>}

                                                    {(fieldType === 'r' || fieldType === 'cr') && formValues.scheduled_date && manageAvlList && !manageAvlList.data && manageAvlList.status === 400 &&
                                                        <FormControl>
                                                            <Stack spacing={{ xs: 1, sm: 2 }} style={{ marginTop: '10px' }} direction="row" useFlexGap flexWrap="wrap">
                                                                <Item>{manageAvlList.message}</Item>
                                                            </Stack>
                                                        </FormControl>}

                                                    {(fieldType === 'r' || fieldType === 'cr') && avlStatus === false && formValues.scheduled_date &&
                                                        <FormControl>
                                                            <button className="submit-modal" onClick={checkAvailability}>
                                                                show availability
                                                            </button>
                                                        </FormControl>
                                                    }

                                                    {fieldType === 'af' &&
                                                        // <div>
                                                        //     <table>
                                                        //         <tr>
                                                        //             <td class="text-dark">Star Rating </td>
                                                        //             <td>
                                                        //                 <Rating
                                                        //                     value={formValues.rating}
                                                        //                     name={'rating'}
                                                        //                     onChange={(evt) => {
                                                        //                         handleChange(evt);
                                                        //                     }}
                                                        //                 />
                                                        //             </td>
                                                        //         </tr>
                                                        //     </table>
                                                        //     <table>
                                                        //         <tr>
                                                        //             <th>Specific feedback like :</th>
                                                        //         </tr>
                                                        //         <tr>
                                                        //             <td class="text-dark">Coding skills  </td>
                                                        //             <td>
                                                        //                 <Rating
                                                        //                     value={formValues.skill}
                                                        //                     name={'skill'}
                                                        //                     onChange={(evt) => {
                                                        //                         handleChange(evt, 'skill');
                                                        //                     }}
                                                        //                 />
                                                        //             </td>
                                                        //         </tr>
                                                        //         <tr>
                                                        //             <td class="text-dark">Logic</td>
                                                        //             <td>
                                                        //                 <Rating
                                                        //                     value={formValues.logic}
                                                        //                     name={'logic'}
                                                        //                     onChange={(evt) => {
                                                        //                         handleChange(evt, 'logic');
                                                        //                     }}
                                                        //                 />
                                                        //             </td>
                                                        //         </tr>
                                                        //     </table>

                                                        //     {/* <label >
                                                        //     Star Rating
                                                        // </label>
                                                        // <Rating
                                                        //     value={formValues.rating}
                                                        //     name={'rating'}
                                                        //     onChange={(evt) => {
                                                        //         handleChange(evt);
                                                        //     }}
                                                        // />
                                                        // <br /> <br />
                                                        // <label >
                                                        //     Specific feedback like
                                                        // </label> */}
                                                        //     {/* <div className="formInput" style={{ flexBasis: '100%' }}> */}
                                                        //     {/* <br />
                                                        // <label >Coding skills</label>
                                                        // <Rating
                                                        //     value={formValues.skill}
                                                        //     name={'skill'}
                                                        //     onChange={(evt) => {
                                                        //         handleChange(evt, 'skill');
                                                        //     }}
                                                        // /> */}
                                                        //     {/* </div> */}

                                                        //     {/* <div className="formInput" style={{ flexBasis: '100%' }}> */}
                                                        //     {/* <br />
                                                        // <label >Logic</label>
                                                        // <Rating
                                                        //     value={formValues.logic}
                                                        //     name={'logic'}
                                                        //     onChange={(evt) => {
                                                        //         handleChange(evt, 'logic');
                                                        //     }}
                                                        // /> */}
                                                        //     {/* </div> */}
                                                        //     {/* <div className="formInput" style={{ flexBasis: '100%' }}> */}
                                                        //     <br />
                                                        //     <label >Attitude </label>
                                                        //     <RadioGroup
                                                        //         row
                                                        //         aria-labelledby="demo-row-radio-buttons-group-label"
                                                        //         name={'attitude'}
                                                        //         value={formValues.attitude}
                                                        //         onChange={(evt) => {
                                                        //             handleChange(evt, 'attitude');
                                                        //         }}
                                                        //     >
                                                        //         <FormControlLabel value={true} control={<Radio />} label="Postive" />
                                                        //         <FormControlLabel value={false} control={<Radio />} label="Negative" />
                                                        //     </RadioGroup>
                                                        //     {/* </div> */}
                                                        // </div>
                                                        <AddFeedBackform
                                                            formValues={formValues}
                                                            headData={headData}
                                                            handleChangeFeedBack={handleChangeFeedBack}
                                                            addHeadData={addHeadData}
                                                            setAddHeadData={setAddHeadData}
                                                        />
                                                    }
                                                    {(fieldType === 'r' || fieldType === 'rr' || fieldType === 'ci' || fieldType === 'af' || fieldType === 'd' || fieldType === 'i') &&
                                                        <div className="formInput" style={{ flexBasis: '100%', marginTop: '20px' }}>
                                                            <label >
                                                                {fieldType === 'af' ? "Remark" : "Comment/ Reason"}
                                                            </label>

                                                            <textarea defaultValue={formValues.comment} name={'comment'} onChange={handleChange} rows={3} placeholder={'comment'}></textarea>

                                                            {((fieldType === 'r' || fieldType === 'rr' || fieldType === 'ci' || fieldType === 'af' || fieldType === 'd' || fieldType === 'i') && errStatus === true && (!formValues.comment || formValues.comment === "")) && <p style={{ color: "#96332c", fontSize: 13 }}>{'This field is required'}</p>}
                                                        </div>}
                                                </div>
                                                {formValues.interview_status__status_key === 'cancel_interview' && fieldType === 'ci' ?
                                                    <button disabled style={{ backgroundColor: '#ddd', padding: '10px', borderRadius: '5px' }}>Cancelled interview</button>
                                                    :
                                                    fieldType !== 'sc' && reload === false &&
                                                    <button className="submit-modal" onClick={submitData}>{
                                                        fieldType === 'rr' ? 'Request for Reschedule' :
                                                            fieldType === 'af' ? 'submit' :
                                                                fieldType === 'cr' ? 'Confirm Reschedule' :
                                                                    fieldType === 'ci' ? 'Submit' :
                                                                        fieldType === 'as' ? 'Accept Schedule' :
                                                                            fieldType === 'd' ? 'Interview Done' :
                                                                                fieldType === 's' ? 'Join Meeting' :
                                                                                    fieldType === 'i' ? 'Interrupted' :


                                                                                        'Submit'}</button>
                                                }
                                            </form>
                                }
                            </DialogContent>

                        </Dialog>
                }
                {showEmailModel && (
                    <EmailTemplet
                        setShowEmailModel={closeEmailModel}
                        to_email={candidateEmail}
                        project_id={
                            formValues && formValues.obj && formValues.obj.epic__project_id
                        }
                        data={formValues.obj}
                        story_id={formValues.obj && formValues.obj.story_id}
                        module={'interviewer'}
                        shiftTime={shiftTime}
                        interviewer_status={scheduleStatusOptions.filter(e => e.status_key === 'cancel_interview' && e)}
                        interviewerFormValues={formValues}
                        interviewUpdateApi={interviewUpdate}
                        setInterviewReload={setReload}

                    />
                )}
            </div>
        </div >
    )
}

export default Interviews
