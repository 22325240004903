import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, Button } from '@mui/material'
import React, { useState } from 'react'
import LoaderForm from '../../components/common-function/LoaderForm';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resourceListOption } from '../../redux/features/optionsSlice';
// import Select from "react-select";
import { toast } from 'react-toastify';
import { sendEmail } from '../../redux/features/emailSlice';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';

const { REACT_APP_API_BASEURL } = process.env;

const ShareModule = ({ open, close, filter, apiServiceName, dowunloadResponse, moduleName, subject, apiServiceDownload }) => {

    const { REACT_APP_ENV } = process.env;
    const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    let user_name = rtm_user.user && rtm_user.user.data && rtm_user.user.data.name

    const dispatch = useDispatch()
    const [filterCall, setFilterCall] = useState(true)
    const [copyUrl, setCopyUrl] = useState("")
    const [loading, setLoading] = useState(false)
    const [formValues, setFormValues] = useState({ "resource": "", "description": "" })
    const [formErr] = useState({
        resource: 'Select at least one user or email',
        description: 'Description is required'
    })
    const [formErrStatus, setFormErrStatus] = useState(false)
    const { resources } = useSelector(state => state.dropdownOptions)
    const { error, send_email } = useSelector(state => state.emailSlice)


    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        if (name === 'description') {
            setFormValues({ ...formValues, [name]: value })

        } else {
            setFormValues({ ...formValues, 'resource': evt })

        }
    }

    const onResetForm = () => {
        setFormValues({ "resource": "", "description": "" })
        setFormErrStatus(false)
    }

    if (loading === "submit" && send_email && send_email.status) {
        if (send_email.status === 200) {
            toast.success(send_email.message)
            close()
            onResetForm()
        } else {
            toast.error(send_email.message)
        }
        setLoading(false)
    }

    if (loading === 'submit' && error) {
        toast.error(error.message)
        setLoading(false)
    }

    const submit = (e) => {
        e.preventDefault()
        let mailArray = []
        if (formValues && formValues.resource && formValues.resource.length > 0) {
            setFormErrStatus(false)

            formValues.resource.map(e => {
                return mailArray.push(e.email)
            })
            let data = {
                "mail_type": "html",
                "to": mailArray,
                "cc": [""],
                "subject": subject +
                    " | Shared by: " +
                    user_name +
                    " | Date: " +
                    moment().format("DD-MM-YYYY"),
                "body": `<div> 
                   <b>${subject} </b> : <br/>
                   <a href="${copyUrl}">Click here to download</a> <br/>
                   <p>${formValues.description}</p>
                 </div>`
            }
            const formData = new FormData();
            formData.append("data", JSON.stringify(data));
            dispatch(sendEmail(formData))
            setLoading('submit')
        } else {
            setFormErrStatus(true)
        }
    }

    if (open === true && filter && filterCall === true) {
        filter['for_download'] = 'True';
        if (moduleName === "agentCallPerformance" || moduleName === "resumeAnalysis") {
            filter['no_limit'] = "True"
            dispatch(apiServiceName(filter))
        } else {
            dispatch(apiServiceDownload(JSON.stringify(filter)))
        }
        setFilterCall(false)
    }

    if (dowunloadResponse && dowunloadResponse.data && copyUrl === '') {
        let api_url = dowunloadResponse && dowunloadResponse.data.download_api_url
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            setCopyUrl(url.replace("api//", "api/"))
            delete filter['for_download']
            if (moduleName === "agentCallPerformance" || moduleName === "resumeAnalysis") {
                delete filter['no_limit']
                dispatch(apiServiceName(filter))
            } else {
                dispatch(apiServiceName(JSON.stringify(filter)))
            }
        }
    }

    useEffect(() => {
        dispatch(resourceListOption())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {loading === "submit" && <LoaderForm />}
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"sm"}
                open={open}
                onClose={() => { close(); onResetForm() }}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Button
                    edge="start"
                    color="inherit"
                    onClick={() => { close(); onResetForm() }}
                    aria-label="close"
                    style={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "15px" }}>Share: {subject}</DialogTitle>

                <DialogContent>
                    <form>
                        <div className="add-new-user-form">
                            <div className="formInput" style={{ flexBasis: "100%" }}>
                                {/* <Select
                                    value={formValues.resource}
                                    onChange={handleChange}
                                    isMulti
                                    isClearable
                                    name="resource"
                                    placeholder="Enter name ,group or email"
                                    options={resources} /> */}
                                <CreatableSelect
                                    value={formValues.resource}
                                    onChange={handleChange}
                                    isMulti
                                    isClearable
                                    name="resource"
                                    placeholder="Enter name ,group or email"
                                    options={resources} />

                                <p style={{ fontSize: 12 }}>Recipients will see the name of the page and your message</p>
                                {formErrStatus === true && formValues && (formValues.resource === "" || formValues.resource.length === 0) && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.resource}</p>}

                            </div>

                            <div className="formInputDescription">
                                <textarea
                                    defaultValue={formValues.description}
                                    onChange={handleChange}
                                    name="description"
                                    rows="3"
                                    placeholder="Add a message">
                                </textarea>
                                {/* {formErrStatus === true && formValues && formValues.description === "" && <p style={{ color: "#96332c", fontSize: 10 }}>{formErr.description}</p>} */}

                            </div>
                        </div>
                        <button
                            className="btn btn-submit"
                            onClick={(e) => submit(e)}
                            disabled={loading === "submit" ? true : false}
                            style={{ marginBottom: "60px", marginTop: "10px", backgroundColor: loading === "submit" ? "gray" : "" }}
                        >Send
                        </button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ShareModule