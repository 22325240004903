import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TablePagination, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScreeningMasterCreate, ScreeningMasterEdit, ScreeningMasterUpdate, getScreeningMasterList } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import { InputFields, InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";

const initialFormData = Object.freeze({
    test_type: "",
    test_type_key: "",
    description: "",
    status: "",
    is_for_agent_panel: false,
    is_mcq: false,
});

const formErrData = Object.freeze({
    testTypeErr: "Name is required",
    descriptionErr: "Description is required",
    keyErr: "Test type key is required",
    statusErr: "Status is required"
});


const ScreeningMaster = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, screeningmaster_List, screeningmaster_Create, screeningmaster_Edit, screeningmaster_Update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.SCREENING_MASTER_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [isAgentPanel, setIsAgentPanel] = useState(false);
    const [isMcq, setIsMcq] = useState(false)

    useEffect(() => {
        dispatch(getScreeningMasterList(filterQuery))

        // eslint-disable-next-line
    }, [])

    // error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)

            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])
    useEffect(() => {
        if (isAgentPanel) {
            setIsMcq(true);
        } else {
            setIsMcq(false);
        }
    }, [isAgentPanel]);


    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setIsMcq(false);
        setIsAgentPanel(false);
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_screeningmaster_Update` && screeningmaster_Update && screeningmaster_Update.message) {
        if (screeningmaster_Update.status === 200) {
            toast.success(screeningmaster_Update.message)
            handleClose()
            dispatch(getScreeningMasterList(filterQuery))
        } else {
            toast.error(screeningmaster_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_screeningmaster_Create` && screeningmaster_Create && screeningmaster_Create.message) {
        if (screeningmaster_Create.status === 200) {
            toast.success(screeningmaster_Create.message)
            handleClose()
            dispatch(getScreeningMasterList(filterQuery))
        } else {
            toast.error(screeningmaster_Create.message)
        }
        setReload(false)
    }
    const handleOpen = () => setOpen(true);

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.test_type === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.test_type !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id
            let addvalues = {
                ...formValues, created_by: userId, assigned_by: userId,
                is_mcq: isMcq,
                is_for_agent_panel: isAgentPanel
            }
            setErrStatus(false)
            setReload(true)
            if (ID) {
                dispatch(ScreeningMasterUpdate({ ID, addvalues }))
            } else {
                dispatch(ScreeningMasterCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && screeningmaster_Edit && screeningmaster_Edit.data) {
        let editData = screeningmaster_Edit.data
        setFormValues({
            test_type: editData.test_type,
            description: editData.description,
            test_type_key: editData.test_type_key,
            status: editData.status,
            is_mcq: editData.is_mcq,
            is_for_agent_panel: editData.is_mcq

        })
        setIsMcq(editData.is_mcq)
        setIsAgentPanel(editData.is_for_agent_panel)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        setID(id)
        dispatch(ScreeningMasterEdit(id))
        setReload('edit_data')

    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === 1) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            test_type: data.test_type,
            description: data.description,
            test_type_key: data.test_type_key,
            status: data.status === 0 ? 1 : 0,
            updated_by: GetLocalstorage.userDetail().id
        }
        let ID = data.id;
        setReload(true)
        dispatch(ScreeningMasterUpdate({ ID, addvalues }))
    }
    const handleChangeForAgentPanel = (evt) => {
        const { value } = evt.target;
        setIsAgentPanel(value === "true");
        if (value === "true") {
            setIsMcq(true);
        }
    };

    // const handleChangeForAgentPanel = (evt) => {  
    //     const {value} = evt.target
    //     setIsAgentPanel(value)
    //     if(value === "true"){
    //         setIsMcq(true)
    //     }else{
    //         setIsMcq(false)
    //     }


    // }
    const handleChangeForMcq = (evt) => {
        if (!isAgentPanel) {
            setIsMcq(evt.target.value === "true");
        }
    };

    // const handleChangeForMcq = (evt) => {
    //     setIsMcq(evt.target.value)
    //     if (isAgentPanel === true) {
    //         setIsMcq(true)
    //     } else {
    //         setIsMcq(evt.target.value)
    //     }
    // }


    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getScreeningMasterList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getScreeningMasterList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(screeningmaster_List && screeningmaster_List.data && screeningmaster_List.data.rows && screeningmaster_List.data.row_count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getScreeningMasterList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.SCREENING_MASTER_LIST_COLUMNS}] }`)
        }
    }


    const actionColumn = [
        {
            field: "test_type",
            headerName: "Test Type Name",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.test_type}
                    </div>
                );
            },
        },

        {
            field: "test_type_key",
            headerName: "Test Type Key",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.test_type_key}

                    </div>
                );
            },
        },
        {
            field: "description",
            headerName: "Description",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.description}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            renderCell: (params) => {
                return (
                    // <div className={`cellWithStatus  ${params.row.status}`}>
                    <div className={params.row.status === 1 ? 'cellWithStatus true' : 'cellWithStatus false'}>

                        {params.row.status === 1 ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="test-type"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.SCREENING_MASTER_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getScreeningMasterList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.SCREENING_MASTER_LIST_COLUMNS}]}`)
            dispatch(getScreeningMasterList(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.SCREENING_MASTER_LIST_COLUMNS}]}`))
        }
    }
    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Screening Master</h3>
                        <div>
                            <Tooltip title=" Screening Master">
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getScreeningMasterList} filter={filterQuery} style={{ marginTop: 0 }} />

                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={screeningmaster_List && screeningmaster_List.data && screeningmaster_List.data.rows ? screeningmaster_List.data.rows : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Screening Master </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Test Type"}
                                    name="test_type"
                                    defaultValue={formValues.test_type}
                                    type="text"
                                    placeholder="Test Type"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.testTypeErr}
                                />

                                {formValues.id ? <div className="formInput">
                                    <label >
                                        Test Type Key
                                    </label>
                                    <input
                                        readOnly
                                        label={"Test Type Key"}
                                        name="test_type_key"
                                        defaultValue={formValues.test_type_key}
                                        type="text"
                                        placeholder="Test-Type-Key"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                    />
                                </div> : ""}

                                <InputTextareaField
                                    label={"Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />

                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                    <RadioGroup
                                        value={formValues.status}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="status"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                        <FormControlLabel value={0} control={<Radio />} label="InActive" />

                                    </RadioGroup>
                                </FormControl>

                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Is for Agent Panel</FormLabel>
                                    <RadioGroup
                                        value={isAgentPanel}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="is_for_agent_panel"
                                        onChange={handleChangeForAgentPanel}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={false} control={<Radio />} label="No" />

                                    </RadioGroup>
                                </FormControl>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Is MCQ</FormLabel>
                                    <RadioGroup
                                        value={isMcq.toString()}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="is_mcq"
                                        onChange={handleChangeForMcq}
                                        disabled={isAgentPanel} // Disable if isAgentPanel is true
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>


                                {/* <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Is MCQ</FormLabel>
                                    <RadioGroup
                                        value={isMcq}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="is_mcq"
                                        onChange={handleChangeForMcq}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={false} control={<Radio />} label="No" />

                                    </RadioGroup>
                                </FormControl> */}


                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Test Type`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default ScreeningMaster;