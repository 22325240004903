import React from 'react'
import './departments.scss'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { toast, ToastContainer } from 'react-toastify';
import { useEffect, useState } from "react"
import DynamicTable from "../../components/dynamic-table/DynamicTable"
import dataService from "../../redux/services/data.service"
import RegularizeModal from "../../components/common-function/RegularizeModal"
import { domainList } from "../../redux/features/domainSlice"
import { getDepartmentList, getDepartmentDomains } from "../../redux/features/departmentSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { RefreshButton } from '../../components/common-function/RefreshButton';
const { REACT_APP_ENV } = process.env;

const DepartmentDomain = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;

  const { domains } = useSelector(state => state.domain)
  const { departmentList, departmentDomainsList, count, status } = useSelector(state => state.department)

  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [params, setParams] = useState({})
  const [setFieldName] = useState("")
  const [setDefaultValue] = useState("")

  // Modal Related data
  const [open, setOpen] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [updating, setUpdating] = useState(false);
  const [editable, setEditable] = useState(false);

  const whereClause = { ...params, "skip": skip, "limit": pageSize || 25 }

  const [departmentsData, setDepartmentsData] = useState({})

  const modalDetail = {
    title: "Department-Domains Details",
    editModalTitle: "Assign Domains",
    addModalTitle: "Add Domains",
    formInputs: [
      { id: 1, name: "name", label: "Department Name", placeholder: "Enter Name", type: "textWithLabel", required: true, disabled: false },
      {
        id: 3, name: "status", label: "Status", placeholder: "Enter For Date", type: "status",
        options: [
          { id: 1, label: "Active", value: true },
          { id: 2, label: "Inactive", value: false },
        ],
        required: true, disabled: false
      },
      {
        id: 2, name: "domain", label: "Domains", placeholder: "Enter Domains", type: "multiSelect",
        options: domains.map(e => ({ id: e.id, label: e.name, value: e.id })),
        required: true, disabled: false
      },
    ],
  }

  const departmentDomainColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Department Name",
      width: 275,
    },
    {
      field: "code",
      headerName: "Department code",
      width: 300,
    },
    {
      field: "departmentDomainsList",
      headerName: "Domains",
      width: 480,
      hideable: false,
      filterable: false,
      sortable: false,
      type: 'singleSelect',
      valueOptions: domains,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.domains && params.row.domains.length ?
              params.row.domains.map((domain, index) => {
                return (
                  <p key={index} style={{}}>
                    {index === 0 && domain.name} {index === 0 && params.row.domains.length > 1 && ","}
                    {params.row.domains.length > 1 && index === 1 && domain.name} {index === 1 && params.row.domains.length > 2 && ","}
                  </p>
                )
              })
              : "N/A"
            }
            {(params.row.domains && params.row.domains.length > 2) ? `..[+${params.row.domains.length - 2}]` : ""}

          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 120,

      type: 'singleSelect',
      valueOptions: [
        { label: "Active", value: "True" },
        { label: "Inactive", value: "False" },
      ],
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`} >
            <p>{params.row.status === true ? 'Active' : 'Inactive'}</p>
          </div>
        );
      },
    },
  ];

  // Submit Assignment Form
  const onSubmit = async (data) => {
    const finalData = { ...departmentsData, ...data }
    const postData = {
      department: finalData.department_id,
      "domain": finalData?.domain ? finalData.domain.map(e => { return e.id }) : departmentsData.domain.map(e => e.id),
      "status": true,
      created_by: userId
    };
    const res = await dataService.assign_department_domain(postData);
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message)
      dispatch(getDepartmentDomains())
      dispatch(getDepartmentList(whereClause))
      onCloseModal();
    }
    else {
      toast.error(res.data.message)
    }
  }

  // Additional Action (Assign Domains)
  const onAdditionalAction = async (data, key) => {
    if (key === "assign_domain") {
      setDepartmentsData(
        {
          ...delete (departmentsData.id),
          name: data.name,
          domain: data.domains,
          department_id: data.id,
          status: data.status,
          created_by: userId
        });
      setUpdating(true)
      setEditable(true);
      setOpen(true);
    }
  }
  //  Open Modal
  const onOpenModal = () => {
    setOpen(true)
  }

  //  Close Modal
  const onCloseModal = () => {
    setOpen(false)
    setUpdating(false);
    setEditable(false);
    setDepartmentsData({});
  }

  useEffect(() => {
    dispatch(getDepartmentList(whereClause))
    dispatch(domainList());
    dispatch(getDepartmentDomains())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, params])

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <div className="top-headings">
          <h3 >Department-Domain </h3>
          <div>
            <RefreshButton api={getDepartmentList} filter={whereClause} style={{ marginTop: 0 }} />
            <Tooltip title="Back">
              <button onClick={() => navigate(-1)} className="btn-add-new ">
                <ArrowBackIcon />
              </button>
            </Tooltip>
          </div>
        </div>

        <ToastContainer />
        <>
          {
            <DynamicTable
              moduleName="holidays"
              tableColumns={departmentDomainColumns}
              tableRows={
                departmentList.map((dept, index) => ({
                  ...dept,
                  // eslint-disable-next-line
                  domains: departmentDomainsList.map(deptDomain => {
                    if (dept.id === deptDomain.department.id) {
                      const domain = deptDomain.domain;
                      return domain;
                    }
                  }).filter(e => e !== undefined && e)
                }))
                || []}

              currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
              setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
              setFieldName={setFieldName} setDefaultValue={setDefaultValue}
              paginationMode={"server"}
              rowCount={count}
              loading={status === STATUSES.LOADING}
              columnVisibilityModel={{
                id: false,
              }}

              additionalAction={[{ id: 1, key: "assign_domain", title: "Assign Domain" }]}
              onAdditionalAction={onAdditionalAction}
            // onViewDetail={onView}
            // onEdit={handleEdit}
            // deleteRow={deleteHoliday} 
            />
          }
        </>
        {
          <RegularizeModal
            modalDetail={modalDetail}
            open={open}
            onOpen={onOpenModal}
            onClose={onCloseModal}
            // onCancel={onCancel}
            onSubmit={onSubmit}
            hideBackdrop
            fullWidth
            maxWidth
            updating={updating}
            editData={departmentsData}
            editable={editable}
            formLoading={formLoading}
            setFormLoading={setFormLoading}
            additionalActions={modalDetail.additionalActions}
          // onAdditionalAction={() => {return;}}
          />
        }
      </div>
    </div>
  )
}

export default DepartmentDomain