// import LoaderForm from "../../../components/common-function/LoaderForm";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllHeadsList, getClientSideInterviewerList } from "../../../redux/features/hiringRequestSlice";

const StepFormFive = ({ submitData, formValues, errStatus, formErr, setFormValues, handleChangeSelect,
    reload, }) => {
    let client_id = formValues && formValues.client && formValues.client.value
    let is_internal = formValues && formValues.client && formValues.client.is_internal

    const dispatch = useDispatch()
    const { heads_List, client_interviewer_List } = useSelector(state => state.hiringRequest)

    useEffect(() => {
        dispatch(getAllHeadsList())
        dispatch(getClientSideInterviewerList(`{"filter":{"status":{"value":"True","operation":"eq"},"client_id":{"value":${client_id},"operation":"eq"},"tms_role__role_key":{"value":"client-interviewer","operation":"icontains"}},"no_limit":True}`))
        // eslint-disable-next-line
    }, [])

    const submitFormData = (e) => {
        submitData(e, null)
    }

    // handle Change for Specification
    const handleChange = (evt) => {
        let finalArray = []
        if (evt && evt.length > 0) {
            // eslint-disable-next-line
            evt.map((item) => {
                if (item && item.__isNew__) {
                    finalArray.push({
                        value: item.value,
                        new: true
                    })
                } else {
                    finalArray.push({
                        id: item.value,
                    })
                }
            })
            setFormValues({ ...formValues, "head_ids": evt, "head_label": finalArray });
        } else {
            setFormValues({ ...formValues, "head_ids": "" });
        }
    }

    return <>
        {/* {(reload === true || reload === 'submit') ? <LoaderForm /> : */}
        <form onSubmit={(e) => submitFormData(e, null)}>
            <h4>Step 4 INTERVIEWER DETAILS </h4>
            <br />
            <div className="add-new-user-form">
                <div className="formInput" style={{ flexBasis: "36%" }}>
                    <label> Client side Interviewer </label>
                    <Select
                        isDisabled={is_internal === true ? true : false}
                        className="basic-single"
                        classNamePrefix="select"
                        divstyle={{ flexBasis: '100%' }}
                        placeholder={'Client side Interviewer'}
                        onChange={handleChangeSelect}
                        defaultValue={formValues.client_interviewer}
                        isSearchable={true}
                        name={'client_interviewer'}
                        options={client_interviewer_List && client_interviewer_List.map((option) => {
                            return {
                                label: option.label,
                                value: option.value,
                                name: 'client_interviewer',

                            }
                        })}
                    />
                    {/* {errStatus === true && formValues && (formValues.client_interviewer === "" || !formValues.client_interviewer) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.clientInterviewerErr}</p>} */}
                </div>

                <div className="formInput" style={{ flexBasis: "36%" }}>

                    <label>Select feedback heads</label>
                    <CreatableSelect
                        value={formValues.head_ids}
                        onChange={handleChange}
                        isMulti
                        isClearable
                        name="head_ids"
                        placeholder="Select feedback heads ,or add new feedback heads"
                        options={
                            heads_List && heads_List.map((option) => {
                                return {
                                    label: option.label,
                                    value: option.value,
                                    name: "head_ids"
                                }
                            })
                        } />
                    {errStatus === true && formValues && (formValues.head_ids === "" || !formValues.head_ids) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.headErr}</p>}

                </div>

                <div className="formInput" style={{ flexBasis: "20%" }}>  </div>

            </div>

            {/* <button className="btn btn-submit">Save</button> */}
        </form>
        {/* } */}
    </>
}
export default memo(StepFormFive)