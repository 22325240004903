import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./report.scss"
import { DataGrid, GridToolbarContainer, GridCsvExportMenuItem } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { useNavigate } from "react-router-dom";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { resourceList } from "../../redux/features/resourceSlice";
import { attandanceReport } from "../../redux/features/calendarSlice";
import { FormControl, TextField, Button, TablePagination, Tooltip } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from "moment";
import Autocomplete from '@mui/material/Autocomplete';
import { ToastContainer, toast } from "react-toastify";
import { ArrowBack, Download } from '@mui/icons-material';
import { AttendanceReportColumns } from "../../components/datatable-coulms/tableCoulmns";
import TablePaginationActions from "../../components/TablePaginationActions";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
const { REACT_APP_API_BASEURL } = process.env;

const ReportOfAttendance = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data } = useSelector(state => state.resource)
    const { status, error, attendaceReportsList } = useSelector(state => state.calender)
    const [currentPage, setCurrentPage] = useState(0);
    const [resourceId, setResourceId] = useState('select resources');
    const [loader, setLoader] = useState(true)
    const [value, setValue] = useState();
    const [list, setList] = useState([]);
    const [startMaxDate, setStartMaxDate] = useState([null])
    const [fromDate, setFromDate] = useState([null])
    const [toDate, setToDate] = useState([null])
    const [minToDate, setMinToDate] = useState([null])
    const [rowCount, setRowCount] = useState(50)
    const [downliadFile, setDownloadFile] = useState(false)

    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [filterQuery, setFilterQuery] = useState(`{,"order_by":{"column":"-id"}, "skip":0,"limit":25}`)
    //columns visibility
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setLoader(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        daysInMonth(moment().format('MM'), moment().format('YYYY'))
        let monthDays = new Date(moment().format('YYYY'), moment().format('MM'), 0).getDate()
        let startMonth = `${moment().format('YYYY')}-${moment().format('MM')}-1`
        let endMonth = `${moment().format('YYYY')}-${moment().format('MM')}-${monthDays}`

        let filter = `{"filter":{"punchin_date":{"value":["${startMonth}","${endMonth}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`
        dispatch(resourceList(`{"filter":{"status":{"value":"True","operation": "eq"}},"order_by":{"column":"name"},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`))
        setFilterQuery(filter)
        dispatch(attandanceReport(filter))
        if (status === STATUSES.FAILURE) {
            errorHandling(error, navigate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (evt) => {
        if (evt) {
            const { name, value } = evt.target || evt;
            if (name === 'month') {
                setLoader(true)
                const d = new Date();
                let year = d.getFullYear();
                daysInMonth(value + 1, year);

            }
            if (name === 'resourceId') {
                setLoader(true)
                let filter = `{"filter":{"resource_id":{"value":"${value}","operation":"eq"},"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`
                setFilterQuery(filter)
                dispatch(attandanceReport(filter))
                setResourceId(value);
            }
        } else {
            // setList([])
            setLoader(true)
            setResourceId('select resources');
            let filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`
            setFilterQuery(filter)
            dispatch(attandanceReport(filter))
        }

    }
    if (loader === true && status === `${STATUSES.SUCCESS}_attendaceReportsList` && attendaceReportsList && attendaceReportsList.data) {
        setLoader(false)
        let reportRows = attendaceReportsList.data.rows
        let reportCount = attendaceReportsList.data.count
        let reportSummary = attendaceReportsList.data.summary
        let reportList = [];
        let summaryExtra1 = {
            "id": -1
        }
        let summaryExtra2 = {
            "id": -2
        }
        let summaryExtra3 = {
            "id": -5,
            "punch_out_time": "No Data Found!"
        }
        let summaryHeader = {
            "id": -6,
            "punchin_date": "No Of Days",
            "name": "Total Break Hours",
            "break_hours": "Total Effective Working Hours",
            "effective_working_hours": "Total Working Hours",
            "working_hours": "Total Expected Working Hours"
        }

        let summaryBody = {
            "id": -4,
            "punchin_date": reportSummary.no_of_days,
            "name": reportSummary.total_break_hours,
            "break_hours": reportSummary.total_effective_working_hours,
            "effective_working_hours": reportSummary.total_working_hours,
            "working_hours": reportSummary.total_expected_working_hours
        }
        reportRows && reportRows.length > 0 && reportRows.map((e, i) => {
            return reportList.push({
                id: i,
                break_hours: e.break_hours,
                created_on: e.created_on,
                date: e.date,
                effective_working_hours: e.effective_working_hours,
                emp_code: e.emp_code,
                expected_punch_in_date_time: e.expected_punch_in_date_time,
                expected_punch_out_date_time: e.expected_punch_out_date_time,
                expected_working_hours: e.expected_working_hours,
                name: e.name,
                punch_out_date: e.punch_out_date,
                punch_out_time: e.punch_out_time,
                punchin_date: e.punchin_date,
                punchin_time: e.punchin_time,
                resource_id: e.resource_id,
                working_hours: e.working_hours
            })
        })
        if (reportList && reportList.length > 0) {
            reportList.push(summaryExtra1)
            reportList.push(summaryExtra2)
            reportList.push(summaryHeader)
            reportList.push(summaryBody)
            setList(reportList)
        } else {
            reportList.push(summaryExtra1)
            reportList.push(summaryExtra3)
            reportList.push(summaryExtra2)
            reportList.push(summaryHeader)
            reportList.push(summaryBody)
            setList(reportList)
        }
        if (reportCount > 0) {
            setRowCount(reportCount)
        }

    }


    const setYearAndMonth = (newValue) => {
        const d = new Date(newValue);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        daysInMonth(month, year);
        let monthDays = new Date(year, month, 0).getDate()
        let startMonth = (`${year}-${month}-1`)
        let endMonth = (`${year}-${month}-${monthDays}`)
        if (resourceId > 0) {
            setLoader(true)
            let filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${startMonth}","${endMonth}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`
            setFilterQuery(filter)
            dispatch(attandanceReport(filter))
            setValue(newValue)
        } else {
            setLoader(true)
            let filter = `{"filter":{"punchin_date":{"value":["${startMonth}","${endMonth}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`;
            setFilterQuery(filter)
            dispatch(attandanceReport(filter))
            setValue(newValue)
        }
    }

    const daysInMonth = (month, year) => {
        let monthDays = new Date(year, month, 0).getDate()
        setMinToDate(`${year}-${month}-1`)
        setStartMaxDate(`${year}-${month}-${monthDays}`)
        setFromDate(`${year}-${month}-1`)
        setToDate(`${year}-${month}-${monthDays}`)
        return new Date(year, month, 0).getDate();
    }

    const setToDateFunction = (newValue) => {
        let to_date = new Date(newValue)
        let from_date = new Date(fromDate)
        let filter = '';
        setLoader(true)
        setToDate(newValue)
        if (resourceId > 0) {
            filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(from_date).format('YYYY-MM-DD')}","${moment(to_date).format('YYYY-MM-DD')}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`;
        } else {
            filter = `{"filter":{"punchin_date":{"value":["${moment(from_date).format('YYYY-MM-DD')}","${moment(to_date).format('YYYY-MM-DD')}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`;
        }
        setFilterQuery(filter)
        dispatch(attandanceReport(filter))

    }

    const setFromDateFunction = (newValue) => {
        let from_date = new Date(newValue);
        let to_date = startMaxDate;
        setLoader(true)
        let filter = '';
        if (resourceId > 0) {
            filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(from_date).format('YYYY-MM-DD')}","${moment(to_date).format('YYYY-MM-DD')}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`;
        } else {
            filter = `{"filter":{"punchin_date":{"value":["${moment(from_date).format('YYYY-MM-DD')}","${moment(to_date).format('YYYY-MM-DD')}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`;
        }
        setFromDate(newValue)
        setMinToDate(newValue)
        // setList([])
        setFilterQuery(filter)
        dispatch(attandanceReport(filter))
    }

    const downloadCsvFile = () => {
        let filter;
        if (resourceId > 0) {
            filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"for_download":"True","no_limit":"True"}`
        } else {
            filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"for_download":"True","no_limit":"True"}`
        }
        setFilterQuery(filter)
        setDownloadFile(true)
        dispatch(attandanceReport(filter))
    }
    const handleClick = (url) => {
        setDownloadFile(false)
        window.open(url);
    }

    if (downliadFile === true) {
        let api_url = attendaceReportsList && attendaceReportsList.data.download_api_url
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            if (url) {
                handleClick(url.replace("api//", "api/"))
            }
        }
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridCsvExportMenuItem style={{ backgroundColor: '#3f51b5', color: '#fff' }} />
            </GridToolbarContainer>
        );
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        setLoader(true)
        let filter;
        if (resourceId > 0) {
            filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${pageSize}}`
        } else {
            filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${pageSize}}`
        }
        let newFilterData = JSON.parse(filter);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(attandanceReport(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setLoader(true)
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let filter;
        if (resourceId > 0) {
            filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${newPageSize.target.value}}`
        } else {
            filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format('YYYY-MM-DD')}","${moment(toDate).format('YYYY-MM-DD')}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${newPageSize.target.value}}`
        }
        let newFilterData = JSON.parse(filter);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(attandanceReport(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={rowCount || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }
    return (
        <div className="report">
            <Sidebar />
            <div className="reportContainer">
                <Navbar />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Daily Attendance Report</h3>
                        <div>
                            <RefreshButton api={attandanceReport} filter={filterQuery} />
                            <Tooltip title={"Back"}>
                                <button onClick={() => navigate(-1)} className="btn-add-new ">
                                    <ArrowBack />
                                </button>
                            </Tooltip>
                        </div>

                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "30px", marginBottom: "30px" }}>

                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%" }}>
                            <DatePicker

                                views={['month', 'year']}
                                label="Year and Month"
                                minDate={dayjs('2012-03-01')}
                                //maxDate={dayjs('2023-06-01')}

                                value={value}
                                onChange={(newValue) => {
                                    setYearAndMonth(newValue);
                                }}
                                format="YYYY-MM"
                                renderInput={(params) => <TextField disabled={true} {...params} helperText={null} />}
                            />
                        </LocalizationProvider>
                        <FormControl style={{ flexBasis: "18%" }} >

                            <Autocomplete
                                id="controllable-states-demo"
                                onChange={(event, newValue) => {
                                    handleChange(newValue);
                                }}

                                options={data && data.data && data.data.rows.map((e, key) => {
                                    return { key: e.id, name: "resourceId", value: e.id, label: e.name + "(" + e.email + ")" }
                                })}
                                // sx={{ width: 400 }}
                                renderInput={(params) => <TextField {...params} label="Select resources" />}
                            />

                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%" }}>
                            <DatePicker

                                label="From  Date"
                                //minDate={startMinDate}
                                //maxDate={startMaxDate}
                                value={fromDate}
                                onChange={(newValue) => {
                                    setFromDateFunction(newValue);
                                }}
                                format="DD-MM-YYYY"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flexBasis: "18%" }}>
                            <DatePicker

                                minDate={minToDate}
                                //maxDate={startMaxDate}
                                label="To Date"
                                value={toDate}
                                onChange={(newValue) => {
                                    setToDateFunction(newValue);
                                }}
                                format="DD-MM-YYYY"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <Button startIcon={<Download />} style={{ backgroundColor: '#3f51b5', flexBasis: "14%", color: '#fff', padding: "14px", }} onClick={() => downloadCsvFile()}>CSV(server)</Button>
                    </div>
                    {(loader === true || status === STATUSES.LOADING) && <LoaderForm />}
                    <ToastContainer />

                    <DataGrid
                        className="datagrid"
                        rows={list || []}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        columns={AttendanceReportColumns}
                        components={{
                            Toolbar: CustomToolbar,
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        pageSize={pageSize === 100 ? pageSize : pageSize + 4}
                        style={{ fontSize: 16 }}
                    />

                </div>
            </div>
        </div>
    )
}

export default ReportOfAttendance