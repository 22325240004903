import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./task.scss";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
//import CONSTANTS from "../../components/constant/constantComponents";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { STATUSES } from "../../redux/common-status/constant";
import { getSprintList, getTaskList, getTaskType, jireStatus, getCommentId, project_List, taskUpdateById, taskAssigneeUpdateById, taskStatusUpdate, getMemberList, resourceProjectList, getMYTaskList, postCommentCreate } from "../../redux/features/taskSlice";
import { useNavigate } from "react-router-dom";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { resourceListForTaskEdit } from "../../redux/features/resourceSlice";
// import AttachFileIcon from '@mui/icons-material/AttachFile';
// import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { CKEditor } from 'ckeditor4-react';
//import FormControl from '@mui/material/FormControl';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { Select, Autocomplete, Box, MenuItem, TextField, useTheme, Tooltip } from '@mui/material';
import LoaderForm from "../../components/common-function/LoaderForm";
import DataService from "../../redux/services/data.service";
import { Avatar, Button } from '@mui/material';
import moment from "moment";
// import Multiselect from 'multiselect-react-dropdown';
import { TaskModal } from "./TaskModal";
// import { InputMultiSelectField } from "../../components/common-function/InputFields";
import { Close, Check, Mail, ArrowBack, InsertComment } from '@mui/icons-material';
import CallingComponent from "../../components/common-function/CallingComponent";
import SelectSearch from "react-select";
import EmailTemplet from "../../components/email-template/EmailTemplate";
import IconButton from '@mui/material/IconButton';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

// import RichTextEditor from "./RichTextEditor";
// import { URTM_000, URTM_61 } from "./DemoData";
import TaskBreadCrumb from "../../components/breadcrumb/TaskBreadCrumb";
import { ContentParser } from "./ContentParser";
const user = LocalStorageDataService.userDetail();
const tmsRole = user && user.tms_role
const roleWiseFilter = user && tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ? `{"skip":0, "limit":10}` : user && `{"or":{"assignee":{"value":${user.id},"operation": "eq"},"reported_by":{"value":${user.id},"operation": "eq"}}, "skip":0, "limit":10}`

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
function firstLetterWord(str) {
    let result = "";

    // Traverse the string.
    let v = true;
    for (let i = 0; i < str.length; i++) {
        // If it is space, set v as true.
        if (str[i] === ' ') {
            v = true;
        }

        // Else check if v is true or not.
        // If true, copy character in output
        // string and set v as false.
        else if (str[i] !== ' ' && v === true) {
            result += (str[i]);
            v = false;
        }
    }
    return result;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: firstLetterWord(name),
    };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const initialFormData = Object.freeze({
    project: "",
    task: "",
    sprint: "",
    comment: "",
    assignee: "",
    reporter: LocalStorageDataService.userDetail() && LocalStorageDataService.userDetail().display_name,
    t_status: "",
    t_description: "",
    task_type: "",
    priority: "",
    summary: "",
    is_jira_issue: ""
});


export const TaskEdit = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const location = useLocation()
    const { taskId } = useParams()
    const [formValues, setFormValues] = useState(initialFormData)
    // const [errStatus, setErrStatus] = useState(false)
    // const [activeId, setActiveId] = useState(false)
    const [summaryActive, setSummaryActive] = useState(false)
    const [discription, setDiscriptionActive] = useState(false)
    const [descriptionData, setDescriptionData] = useState('')
    // const [fields, setFields] = useState([{ value: null }]);
    const [statusData, setStatusData] = useState([]);
    const [syncLoading, setSyncLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [selected, setSelected] = useState([]);
    const [memberLoader, setMemberLoader] = useState(true)
    const [selectedMember, setSelectedMember] = useState([]);
    // const [member_List, setMember_List] = useState([]);
    const [activeStoryModal, setActiveStoryModal] = useState(false)

    const { error, status, taskTypeList, createTask, updateTaskData, commentById, taskAssigneeUpdate, taskStatusData, taskStoryList, memberList, myTaskList } = useSelector(state => state.task)

    // const { error, status, projectList, jireStatusList, taskTypeList, taskList, sprintList, createTask, updateTaskData, commentById, taskAssigneeUpdate, taskStatusData } = useSelector(state => state.task)
    const { resourceListForTsk } = useSelector(state => state.resource)
    const resource_data_list = resourceListForTsk && resourceListForTsk.data && resourceListForTsk.data.rows ? resourceListForTsk.data.rows : [];
    // const taskId = location && location.state && location.state.taskId ? location.state.taskId : "";
    const theme = useTheme();

    const [mobileNumberOptions, setMobileNumberOptions] = useState([])
    const [emailOptions, setEmailOptions] = useState([])
    const [showEmailModel, setShowEmailModel] = useState(false)

    const [phoneDetails, setPhoneDetails] = useState({
        destination_number: "",
        story_id: taskId
    })
    const [emailDetails, setEmailDetails] = useState({
        email: "",
    })
    const [commentActive, setCommentActive] = useState(false)
    const [comment, setComment] = useState("")
    const [show, setShow] = useState(false)

    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setPersonName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        if (name === "project" && taskTypeList && taskTypeList.length === 0) {
            // setActiveId(true)
            dispatch(getTaskType(value))
        }
        if (name === "project" && value === 'Select') {
            // setActiveId(false)
        }
        setFormValues({ ...formValues, [name]: value });
    }



    // const handleChangeEvent = (i, event) => {
    //     const values = [...fields];
    //     values[i].value = event.target.value;
    //     setFields(values);
    // }

    // const handleAdd = () => {
    //     const values = [...fields];
    //     values.push({ value: null });
    //     setFields(values);
    // }

    // const handleRemove = (i) => {
    //     const values = [...fields];
    //     values.splice(i, 1);
    //     setFields(values);
    // }


    // const handleChangeSelector = (event) => {
    //     const {
    //         target: { value }
    //     } = event;
    //     setAssigneeName(
    //         // On autofill we get a the stringified value.
    //         typeof value === "string" ? value.split(",") : value
    //     );
    // }

    const loadData = async () => {
        const res = await DataService.get_task_By_id(taskId)
        if (res && res.data && res.data.data) {
            const editData = res.data.data

            editData.candidate_phone && setMobileNumberOptions(editData.candidate_phone)
            editData.candidate_email && setEmailOptions(editData.candidate_email)

            setFormValues({
                ...formValues,
                project: editData.project && editData.project.id,
                issue_type: editData.issue_type,
                ticket_key: editData.ticket_key,
                // ticket_id: editData.id,
                sprint: editData.sprint && editData.sprint.id,
                comment: editData.comments && editData.comments.length > 0 ? editData.comments.length : [],
                assignee: editData.assignee && editData.assignee.id,
                assignee_name_default: editData.assignee && { label: editData.assignee.name, value: editData.assignee.id },
                reporter: editData.reported_by && editData.reported_by.display_name,
                t_status: editData.status && editData.status,
                t_description: editData.description && editData.description,
                task_type: editData.issue_type && editData.issue_type.id,
                issue_name: editData.issue_type && editData.issue_type.issue_name,
                priority: editData.priority && editData.priority,
                summary: editData.summary && editData.summary,
                is_jira_issue: editData.is_jira_issue && editData.is_jira_issue,
                labels: editData.labels && editData.labels,
                story_point: editData.story_point,
                ticket_jira_key: editData.ticket_jira_key,
                project_name: editData.project && editData.project.project_name,
                epic_id: editData.epic_id,
                epic_details: editData.epic_details && editData.epic_details,
                created_by: editData.created_by,
                candidate_phone: editData.candidate_phone && editData.candidate_phone,
                candidate_email: editData.candidate_email && editData.candidate_email,
                candidate_info: editData.candidate_info && editData.candidate_info,
            })

            //setAssigneeName([editData.assignee && editData.assignee.id]);
            if (editData.labels) {
                let labels = editData.labels;
                if (typeof labels === 'string') {
                    setSelected(JSON.parse(editData.labels))
                } else {
                    setSelected(editData.labels)
                }
            }
            if (editData.project && editData.project.id) {
                // setActiveId(true)
            }
            if (editData.status_data) {
                setStatusData(editData.status_data)
            }

            if (editData.id) {
                dispatch(getCommentId(editData.id))

            }
            if (editData) {
                const filterForProject = `{"filter":{"project":{"value":${editData.project && editData.project.id},"operation":"eq"}}, "order_by":{"column":"-created_on"}, "no_limit":True}`;
                dispatch(resourceProjectList(filterForProject))
                dispatch(getMYTaskList(`{"filter":{"epic_id":{"value":${editData.id}, "operation":"eq"}}}`))
            }
        }
    }

    if (memberLoader === true && memberList && memberList.data) {
        let array = []
        // memberList.data.rows.map(e => {
        memberList.data.rows.forEach(e => {
            array.push({ display_name: e.resource.display_name, id: e.resource_id, jira_rtms_issue_id: e.jira_rtms_issue_id, actual_id: e.id })
        })
        // setMember_List(array)
        setSelectedMember(array)
        setMemberLoader(false)
    }

    // const openModalVisible = () => {
    //     if (formValues.epic_id) {
    //         const filterForEpic = `{"filter":{"project":{"value":${formValues.project},"operation":"eq"}, "epic_id":{"value":${formValues.epic_id},"operation":"eq"}}, "order_by":{"column":"-created_on"}, "no_limit":True}`;
    //         dispatch(getTaskStoryList(filterForEpic))
    //         setOpenModal(true)
    //     }
    // }

    useEffect(() => {
        if (taskId) {
            loadData()
            dispatch(getMemberList(`{"filter":{"jira_rtms_issue_id":{"value":${taskId},"operation":"eq"}}, "no_limit":True}`))

            // dispatch(getMemberList(`{"filter":{"created_by":{"value":${LocalStorageDataService.userDetail().id},"operation":"eq"},"jira_rtms_issue_id":{"value":${taskId},"operation":"eq"}}, "no_limit":True}`))

        }
        if (status === STATUSES.FAILURE) {
            toast.error("Some Server Issue ! ")
            errorHandling(error, navigate)
        }
        if (taskId) {
            dispatch(getTaskType(taskId))
        }
        dispatch(project_List())
        dispatch(resourceListForTaskEdit())
        dispatch(jireStatus(`{"order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(getTaskList(roleWiseFilter))
        dispatch(getSprintList())

        //dispatch(getCommentId(taskId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const editCommentChanges = (name) => {
        setCommentActive(false)
        const parser = new DOMParser();
        const doc = parser.parseFromString(comment, 'text/html');

        // might be a more efficient way to do this. just querying for anchors
        // and filtering out the ones that don't have a <sup> child
        const anchors = [...doc.querySelectorAll('a')]

        let data = {
            "jira_issue_id": formValues.ticket_jira_key,
            "rtms_issue": taskId,
            "comment_url": anchors && anchors.length > 0 ? anchors[0].innerText : "",
            "is_jira_attachment": false,
            "body": comment,
            "created_resource": LocalStorageDataService.userDetail().id,
            "status": true,
            "created_by": LocalStorageDataService.userDetail().id

        }
        setSyncLoading('comment_true')
        dispatch(postCommentCreate(data))
    }

    if (syncLoading === 'comment_true' && status === `${STATUSES.SUCCESS}_createComment`) {
        setSyncLoading(false)
        dispatch(getCommentId(taskId))
    }

    // const submitData = (e) => {
    //     e.preventDefault()

    //     if (formValues.project === "" || formValues.task_type === "" || formValues.is_jira_issue === "" || formValues.assignee === "" ||
    //         formValues.reporter === "" || formValues.t_status === "" || formValues.summary === "") {
    //         // setErrStatus(true)
    //     }

    //     if (formValues.project !== "" && formValues.task_type !== "" && formValues.is_jira_issue !== "" && formValues.assignee !== "" &&
    //         formValues.reporter !== "" && formValues.summary !== "" || formValues.t_status !== "") {

    //         let addvalues = {
    //             reported_by: LocalStorageDataService.userDetail().id,
    //             assignee: formValues.assignee,
    //             description: formValues.t_description,
    //             summary: formValues.summary,
    //             issue_type: formValues.task_type,
    //             parent_issue_id: 0,
    //             story_point: 0,
    //             sprint: formValues.sprint,
    //             fixed_version: "",
    //             labels: ["API", "UI"],
    //             priority: formValues.priority,
    //             original_estimate: 0,
    //             component: [],
    //             is_jira_issue: formValues.is_jira_issue,
    //             project: formValues.project,
    //             status: formValues.t_status,
    //             created_by: LocalStorageDataService.userDetail().id,
    //             comments: formValues.comments,

    //         }
    //         // setErrStatus(false)
    //         if (taskId) {
    //             setSyncLoading(true)
    //             //let editValues = { ...addvalues, comment:fields}
    //             delete addvalues.reported_by;
    //             delete addvalues.created_by;
    //             dispatch(taskUpdateById({ id: taskId, addvalues: addvalues }))
    //         }
    //     }

    // }


    if (syncLoading === true && status === STATUSES.SUCCESS && updateTaskData && updateTaskData.message) {
        if (updateTaskData.message === "Success") {
            //toast('Task Update Successfully!')
            toast.success(updateTaskData.message)
        } else {
            toast(createTask.message)
        }

        setTimeout(() => {
            setSyncLoading(false)
            loadData()
            // if (location && location.pathname === '/my-task/edit') {
            //     navigate('/my-task')
            // } else {
            //     navigate('/task')
            // }
        }, 1000);
    }

    if (syncLoading === true && status === `${STATUSES.SUCCESS}_taskAssigneeUpdate` && taskAssigneeUpdate && taskAssigneeUpdate.message) {
        if (taskAssigneeUpdate.message === "Success") {
            // toast('Task Assignee Successfully!')
            toast.success(taskAssigneeUpdate.message)
        } else {
            toast(taskAssigneeUpdate.message)
        }

        setTimeout(() => {
            setSyncLoading(false)
            loadData()
        }, 1000);
    }
    if (syncLoading === true && status === `${STATUSES.SUCCESS}_taskStatusData` && taskStatusData && taskStatusData.message) {
        if (taskStatusData.message === "Success") {
            // toast('Task Status Successfully!')
            toast.success(taskStatusData.message)
            setSyncLoading(false)
            loadData()
        } else {
            toast.error(taskStatusData.message)
        }

        // setTimeout(() => {
        //     setSyncLoading(false)
        //     loadData()
        // }, 1000);
    }


    // const goback = () => {
    //     if (location && location.pathname === `/my-task/edit/${taskId}`) {
    //         navigate('/my-task')
    //     } else {
    //         navigate('/task')
    //     }
    // }
    const assignToMe = (e) => {
        e.preventDefault()
        let userId = LocalStorageDataService.userDetail().id
        formValues.assignee_name_default = { label: LocalStorageDataService.userDetail().display_name, value: userId }

        setSyncLoading(true)
        changeStatus({ key: userId, label: LocalStorageDataService.userDetail().display_name + "(" + LocalStorageDataService.userDetail().email + ")", value: userId, name: 'assignee' })
        //dispatch(taskAssigneeUpdateById({ issue_id: taskId, resource_id: userId }))

    }
    const changeStatus = (evt) => {
        if (evt) {
            const { name, value } = evt.target || evt;
            if (name === "status") {
                setSyncLoading(true)
                const transition = statusData.filter(x => (x.status_id).toString() === value ? x : "")
                dispatch(taskStatusUpdate({ issue_id: taskId, from_status_id: formValues.status_id ? formValues.status_id : value, to_status_id: value, transition_id: transition.length > 0 ? transition[0].transition_id : null }))
            }
            if (name === 'assignee') {
                setSyncLoading(true)
                dispatch(taskAssigneeUpdateById({ issue_id: taskId, resource_id: value, assign_to_me: 0 }))
            }
            if (name === "lables") {
                setSelected(value)
                setFormValues({ ...formValues, labels: value })
            }
            if (evt.length > 0) {
                setSelectedMember(evt)
                setFormValues({ ...formValues, team_members: evt })
            }
        }
    }
    // const updateTeamMember = (data) => {
    //     // if (data) {
    //     //     let teamMemberData = {
    //     //         "id": data.id,
    //     //         "jira_rtms_issue": data.jira_rtms_issue_id,
    //     //         "resource": data.resource_id,
    //     //         "status":1
    //     //     }
    //     //     console.log("teamMemberData", teamMemberData);
    //     //     //dispatch(postTeamMemberUpdate(data))
    //     // } else {
    //     //     let teamMemberData = {
    //     //         "jira_rtms_issue": formValues.is_jira_issue === false ? "" : formValues.is_jira_issue,
    //     //         "resource": data.resource_id,
    //     //         "status": 1,
    //     //         "created_by": LocalStorageDataService.userDetail().id
    //     //     }
    //     //     console.log("teamMemberData", teamMemberData);
    //     //     ///dispatch(postTeamMemberCreate(teamMemberData))

    //     // }
    // }

    const editFormChanges = (name) => {
        let value = ""

        if (name === 'summary') {
            setSummaryActive(false)
            value = formValues.summary
        }
        if (name === 'description') {
            setDiscriptionActive(false)
            value = descriptionData
        }

        setSyncLoading(true)
        dispatch(taskUpdateById({ id: taskId, addvalues: { [name]: value } }))
    }


    const inputHandler = (evt) => {
        var newContent = evt.editor.getData();
        setDescriptionData(newContent)
        // Define your onSubmit function here
        // ...
        // for example, setData() here

    }

    const inputCommentHandler = (evt) => {
        var newContent = evt.editor.getData();
        setComment(newContent)
    }

    const activeLink = (params) => {
        setSyncLoading(true)
        setActiveStoryModal(false)
        setTimeout(() => {
            setSyncLoading(false)
            navigate(`/task/edit/${params.row.id}`)
        }, 2000);

    }
    const checkAssignedfield = () => {
        let status = true
        if (selectedMember.length > 0) {
            selectedMember.map(e => {
                if (e.id === LocalStorageDataService.userDetail().id) {
                    status = false
                }
                return status
            })
        }
        if (formValues.created_by === LocalStorageDataService.userDetail().id) {
            status = false
        }
        return status
    }

    const changePhoneNumber = (evt) => {
        if (evt) {
            const { name, value } = evt.target || evt;
            setPhoneDetails({ ...phoneDetails, [name]: value });
        } else {
            setPhoneDetails({
                destination_number: "",
                story_id: taskId
            });
        }
    }

    const changeEmail = (evt) => {
        if (evt) {
            const { name, value } = evt.target || evt;
            setEmailDetails({ ...emailDetails, [name]: value });
        } else {
            setEmailDetails({
                email: ""
            });
        }
    }
    let descriptionType = formValues && formValues.t_description && (formValues.t_description).match("{") ? ContentParser(JSON.parse(formValues.t_description)) : formValues.t_description

    return (
        <div className="task">
            <Sidebar />
            <div className="taskContainer">

                <Navbar />
                <div className="row">
                    <div className="col ss-top-icon" style={{ textAlign: "end" }}>
                        <Tooltip title="Close">
                            <Button
                                style={{
                                    marginTop: "3px",
                                    backgroundColor: "rgb(201 201 201)",
                                    color: "rgb(110 110 110)"
                                }}
                                onClick={() => navigate(-1)}
                            >
                                <ArrowBack />
                            </Button>
                        </Tooltip>
                    </div></div>
                <div className="top-headings">
                    {/* <h3>Edit Task</h3> */}
                    <div>
                        <TaskBreadCrumb
                            formValues={{
                                ...formValues,
                                project: { id: formValues.project, project_name: formValues.project_name }
                            }}
                        />
                        {summaryActive === false && <button style={{ borderWidth: 0, backgroundColor: '#fff' }} ><h3 className='story-epic-summary'>{formValues.summary}</h3></button>}
                    </div>

                    <div className="btn-section-top">
                        {
                            user && user.id === formValues.assignee &&
                            <div className="btn-section-top">
                                <div style={{ marginRight: "10px", display: "flex" }} id="phone">
                                    {
                                        formValues.candidate_phone &&
                                        <SelectSearch
                                            className="task-dropdown"
                                            classNamePrefix="select"
                                            placeholder="Select mobile number"
                                            isSearchable={true}
                                            isClearable
                                            options={
                                                mobileNumberOptions && mobileNumberOptions.length && mobileNumberOptions.map((option) => {
                                                    return ({ label: option.phone_number, value: option.phone_number, name: "destination_number" })
                                                })
                                            }
                                            onChange={changePhoneNumber}
                                        />
                                    }
                                    {phoneDetails.destination_number &&
                                        <CallingComponent data={phoneDetails} title="" className="btn btn-add-new" />
                                    }
                                </div>
                                <div style={{ display: "flex" }} id="email">
                                    {
                                        formValues.candidate_email &&
                                        <SelectSearch
                                            className="task-dropdown"
                                            classNamePrefix="select"
                                            placeholder="Select email"
                                            isSearchable={true}
                                            isClearable
                                            options={
                                                emailOptions && emailOptions.length && emailOptions.map((option) => {
                                                    return ({ label: option.email, value: option.email, name: "email" })
                                                })
                                            }
                                            onChange={changeEmail}
                                        />
                                    }
                                    {emailDetails.email &&
                                        <Tooltip title="Mail">
                                            <button onClick={() => setShowEmailModel(true)} className="btn-add-new"><Mail /></button>
                                        </Tooltip>
                                        // <a href={`mailto:${emailDetails.email}`} target="_blank" className="btn btn-add-new" style={{ height: "21px" }}>
                                        //     <Mail />
                                        // </a>
                                        // <EmailTemplet />
                                    }
                                    {showEmailModel && <EmailTemplet setShowEmailModel={setShowEmailModel} to_email={emailDetails.email} project_id={formValues.project} data={formValues} />}
                                </div>
                            </div>
                        }

                    </div>
                    <div className="ss-section-top ">
                        <Tooltip title="Comment">
                            <Button
                                style={{
                                    marginTop: "3px",
                                    height: "37px",
                                    marginLeft: "3px",
                                    backgroundColor: "#0052cc",
                                    color: "#fff",
                                }}
                            >
                                <a
                                    style={{ color: "#fff" }}
                                    href='#ss-comment-scroll'
                                    onClick={() => {
                                        setShow(false);
                                        setCommentActive(true);
                                    }}
                                >
                                    <InsertComment />
                                </a>
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <ToastContainer />
                {syncLoading === true && <LoaderForm />}
                <div className="edit-details-row">
                    <div className={`edit-details-col-left ${show ? 'show-hide-left-side-colum1' : ''}`}>
                        {summaryActive === true && <div style={{ padding: 10 }}>
                            <input
                                name="summary"
                                defaultValue={formValues.summary}
                                type="text"
                                onChange={handleChange}
                                style={{ padding: 20, borderWidth: 0, marginBottom: 20, fontSize: 20, color: '#42526e', fontWeight: 'bold' }}
                            />
                            <Check onClick={() => editFormChanges('summary')} />
                            <Close onClick={() => setSummaryActive(false)} />
                        </div>}
                        {/* <ul className="top-title-buttons">
                            <li><Link to={"/"}><AttachFileIcon /> Attach</Link></li>
                            <li><Link to={"/"}><TaskAltIcon />Create subtask</Link></li>
                        </ul> */}
                        <div className="left-description">
                            <h6>Description
                                <Tooltip title="Open Right Side Details">
                                    <span style={{ float: "right" }}>
                                        <IconButton onClick={() => { setShow(!show) }} aria-label="delete">
                                            <FormatAlignJustifyIcon />
                                        </IconButton>

                                    </span>
                                </Tooltip>
                            </h6>
                            {/* onClick={() => setDiscriptionActive(true)} */}
                            {discription === false && !formValues.is_jira_issue && <p style={{ marginBottom: 20 }} > <span dangerouslySetInnerHTML={{ __html: formValues.t_description }} /></p>}
                            {discription === false && formValues.is_jira_issue && formValues?.t_description &&
                                <div style={{ marginBottom: 20 }} >
                                    {/* const bodyContent=ContentParser(data) */}
                                    <p style={{ marginBottom: 20 }} > <span dangerouslySetInnerHTML={{ __html: formValues && descriptionType }} /></p>

                                    {/* {formValues?.t_description ? <RichTextEditor data={JSON.parse(formValues.t_description)} /> : ""} */}
                                </div>
                            }
                            {discription === true &&
                                <div>
                                    <CKEditor
                                        name="t_description"
                                        initData={formValues.t_description}
                                        config={LocalStorageDataService.ckEditor_config_toolbar()}
                                        onChange={(e) => inputHandler(e)}
                                    />
                                    <button onClick={() => editFormChanges('description')} className="save-btn">Save</button>
                                    <button onClick={() => setDiscriptionActive(false)} className="cancel-btn">Cancel</button>
                                </div>
                            }



                            <h6>Comments</h6>
                            <div className="bottom-comments-row" id="ss-comment-scroll">
                                <div className="bottom-commrnt-col-left">
                                    <span style={{ textTransform: 'capitalize' }}><Avatar {...stringAvatar(LocalStorageDataService.userDetail().display_name)} /></span>
                                </div>
                                {commentActive === false &&
                                    <div className="bottom-commrnt-col-right">
                                        <input type="text" className="comment-single-text-box" placeholder="Add a Comment..." onClick={() => setCommentActive(true)} />
                                        {/* <p style={{ marginBottom: 20 }} ><span dangerouslySetInnerHTML={{ __html: formValues.comment }} /></p> */}
                                    </div>
                                }
                                {commentActive === true &&
                                    <div className="bottom-commrnt-col-right">
                                        <CKEditor
                                            name="comment"
                                            initData={comment}
                                            config={LocalStorageDataService.ckEditor_config_toolbar()}
                                            onChange={(e) => inputCommentHandler(e)}
                                        />
                                        <button onClick={() => editCommentChanges('comment')} className="save-btn">Save</button>
                                        <button onClick={() => { setCommentActive(false); setComment("") }} className="cancel-btn">Cancel</button>
                                    </div>
                                }
                            </div>
                            {commentById && commentById.data && commentById.data.rows && commentById.data.rows.length > 0 && commentById.data.rows.map((item, index) => {
                                return (
                                    <div className="bottom-comments-row">
                                        <div className="bottom-commrnt-col-left">
                                            {item.created_resource && item.created_resource.display_name &&
                                                <span style={{ textTransform: 'capitalize' }}><Avatar {...stringAvatar(item.created_resource.display_name)} /></span>
                                            }
                                        </div>
                                        <div className="bottom-commrnt-col-right">
                                            {item.created_resource && item.created_resource.display_name &&
                                                <h6 style={{ textTransform: 'capitalize' }}>{item.created_resource.display_name}
                                                    {/* {item.rtms_issue && item.rtms_issue && item.rtms_issue.assignee && item.rtms_issue.assignee &&
                                                                    <span>{item.rtms_issue.assignee && calculateDays(item.rtms_issue.assignee.created_on)}</span>
                                                                } */}
                                                </h6>
                                            }
                                            {/* <a href={item.comment_url}>Commit Link : {item.comment_url}</a> */}
                                            <p><span dangerouslySetInnerHTML={{ __html: item.body && item.body }} /></p>
                                            <p>Comment Date : {item && item.created_on && moment(item.created_on).format('YYYY-MM-DD')}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={`edit-details-col-right ${show ? 'show-hide-right-side-colum1' : ''}`}>
                        <select name="status" value={formValues.t_status} onChange={(evt) => changeStatus(evt)} className="select-status-ticket">
                            <option>SELECT</option>
                            {statusData && statusData.length > 0 ? statusData.map((item, index) => {
                                return (
                                    <option key={index} value={item.status_id}>{item.status_name}</option>
                                )
                            })
                                :
                                taskTypeList && taskTypeList.data && taskTypeList.data.rows.length > 0 && taskTypeList.data.rows.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.issue_name}</option>
                                    )
                                })}
                        </select>
                        <div className="right-side-details">
                            {/* <h4>Create page for edit task</h4> */}
                            <div className="right-and-left-name-lable-first">
                                <h6 style={{ fontSize: "16px", color: "#42526e" }}>Assignee</h6>

                                {!formValues.assignee &&
                                    <div>
                                        <Autocomplete
                                            disabled={checkAssignedfield()}
                                            className="custom-select-name"
                                            sx={{ width: 300 }}
                                            autoHighlight
                                            defaultValue={formValues.assignee_name_default}
                                            renderOption={(props, option) => (
                                                <Box key={props.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <span className="name-and-short">
                                                        <Avatar style={{ marginRight: "10px", textTransform: "uppercase", height: "30px", width: "30px", fontSize: "16px" }} {...stringAvatar(option.label)} />{"   "} <span className="short-cut-full-name" style={{ fontSize: "16px", textTransform: "capitalize", }}>{option.label}</span>
                                                    </span>
                                                </Box>
                                            )}
                                            onChange={(event, newValue) => {
                                                changeStatus(newValue);
                                            }}
                                            options={
                                                resource_data_list && resource_data_list.length > 0 && resource_data_list.map((option) => {
                                                    return { key: option.id, value: option.id, label: option.name + "(" + option.email + ")", name: "assignee" }
                                                })
                                            }
                                            renderInput={(params) => <TextField {...params} label={(formValues.issue_name === 'Story' || formValues.issue_name === 'story') ? "Unassigned Assignee" : "Select Assignee"} />}
                                        />
                                        {(formValues.issue_name === 'Story' || formValues.issue_name === 'story') && checkAssignedfield() === false && <button onClick={(e) => assignToMe(e)} className="btn btn-submit">assign to me</button>}
                                    </div>
                                }
                                {
                                    formValues.assignee_name_default && formValues.assignee_name_default.value &&
                                    <div>
                                        <Autocomplete
                                            disabled={checkAssignedfield()}
                                            className="custom-select-name"
                                            sx={{ width: 300 }}
                                            autoHighlight
                                            value={formValues.assignee_name_default}
                                            renderOption={(props, option) => (
                                                <Box key={props.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <span className="name-and-short">
                                                        <Avatar style={{ marginRight: "10px", textTransform: "uppercase", height: "30px", width: "30px", fontSize: "16px" }} {...stringAvatar(option.label)} />{"   "} <span className="short-cut-full-name" style={{ fontSize: "16px", textTransform: "capitalize", }}>{option.label}</span>
                                                    </span>
                                                </Box>
                                            )}
                                            onChange={(event, newValue) => {
                                                changeStatus(newValue);
                                            }}
                                            options={
                                                resource_data_list && resource_data_list.length > 0 && resource_data_list.map((option) => {
                                                    return { key: option.id, value: option.id, label: option.name + "(" + option.email + ")", name: "assignee" }
                                                })
                                            }
                                            renderInput={(params) => <TextField {...params} label={(formValues.issue_name === 'Story' || formValues.issue_name === 'story') ? "Unassigned Assignee" : "Select Assignee"} />}
                                        />
                                        {(formValues.issue_name === 'Story' || formValues.issue_name === 'story') && checkAssignedfield() === false && <button onClick={(e) => assignToMe(e)} className="btn btn-submit">assign to me</button>}
                                    </div>
                                }

                            </div>
                            <div className="right-and-left-name-lable">
                                <h6>Reported By</h6>
                                <input type="text" value={formValues.reporter} disabled={true} />
                            </div>

                            <div className="right-and-left-name-lable">
                                <h6>Label</h6>
                                {/* <Multiselect
                                    isObject={true}
                                    name="lables"
                                    onRemove={(evt) => { changeStatus(evt) }}
                                    onSelect={(evt) => { changeStatus(evt) }}
                                    displayValue={"value"}
                                    selectedValues={formValues.labels}
                                    options={LocalStorageDataService.labels_list()}
                                /> */}
                                {/* <select name="labels" multiple value={formValues.labels}  onChange={changeStatus} >
                                {LocalStorageDataService.labels_list().map(e=>{
                                    return(<option value={e}>{e}</option>)
                                })}
                                </select> */}
                                <Select
                                    id="demo-multiple-name"
                                    value={selected}
                                    onChange={changeStatus}
                                    multiple
                                    name="lables"
                                    renderValue={(selected) => selected.join(", ")}
                                    MenuProps={MenuProps}
                                    className="custom-select-name"
                                // disabled={true}
                                >
                                    {LocalStorageDataService.labels_list().map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>

                            <div className="right-and-left-name-lable">
                                <h6>Story Point</h6>
                                <input type="nubmer" value={formValues.story_point} disabled={true} />
                            </div>
                            {myTaskList && myTaskList.data && myTaskList.data.rows && myTaskList.data.rows.length > 0 &&
                                <div className="right-and-left-name-lable">
                                    <h6 >Epic Story</h6>
                                    <Button onClick={() => setActiveStoryModal(true)}>View related stories</Button>
                                    {/* <input type="nubmer" value={'View related stories'} disabled={true} /> */}
                                </div>
                            }

                            <div className="right-and-left-name-lable">
                                <h6>Sprint</h6>
                                <input type="text" value={formValues.sprint} disabled={true} />
                            </div>

                            <div className="right-and-left-name-lable">
                                <h6>Priority</h6>
                                <Select
                                    id="demo-multiple-name"
                                    value={formValues.priority}
                                    onChange={changeStatus}
                                    name="priority"
                                    MenuProps={MenuProps}
                                    className="custom-select-name"
                                    disabled={true}
                                >
                                    {LocalStorageDataService && LocalStorageDataService.priority_list().map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item}
                                            style={getStyles(resource_data_list, item, theme)}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </div>
                            {/* {formValues.epic_id &&
                                <div className="right-and-left-name-lable">
                                    <h6>Team members</h6>
                                    <InputMultiSelectField
                                        //label={'Team Members'}
                                        isObject={true}
                                        onRemove={changeStatus}
                                        onSelect={changeStatus}
                                        optionLevel={["display_name"]}
                                        defaultValue={selectedMember}
                                        optionList={resourceProject_list}
                                    />
                                </div>
                            } */}

                        </div>
                    </div>
                </div>
                <TaskModal
                    openModal={activeStoryModal}
                    setOpenModal={setActiveStoryModal}
                    title={"Related Stories"}
                    formtype="data-grid"
                    list={myTaskList && myTaskList.data && myTaskList.data.rows ? myTaskList.data.rows : []}
                    columns={[
                        {
                            field: "issue_type", headerName: "", width: 50,
                            renderCell: (params) => {
                                return (
                                    <div onClick={() => activeLink(params)} className="cellWithStatus">
                                        {params.row.issue_type && params.row.issue_type.icon_url ?
                                            <img src={params.row.issue_type.icon_url} alt="" />

                                            :
                                            params.row.issue_type_icon ?
                                                <img src={params.row.issue_type_icon} alt="" />

                                                :
                                                <div></div>
                                        }
                                    </div>
                                );
                            }
                        },

                        {
                            field: "ticket_key", headerName: "RTMS Key", width: 200,
                            renderCell: (params) => {
                                return (
                                    <div onClick={() => activeLink(params)} className="cellWithStatus">
                                        {params.row.ticket_key}
                                    </div>
                                )
                            }
                        },
                        {
                            field: "summary", headerName: "summary", width: 300,
                            renderCell: (params) => {
                                return (
                                    <div onClick={() => activeLink(params)} className="cellWithStatus">
                                        {params.row.summary}
                                    </div>
                                )
                            }
                        }]}
                />
                <TaskModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    list={taskStoryList}
                    title={"Story List"}
                    formtype="list"
                />
            </div>
        </div >
    )
}
