import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./jirastatus.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination } from "@mui/material";
import { useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { jiraStatusTypeColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jiraStatusCreate, jiraStatusList, jiraStatusUpdate, statusSyncByJira } from "../../redux/features/jiraStatusSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DataService from "../../redux/services/data.service";
import LoaderForm from "../../components/common-function/LoaderForm";
import { Sync, Close } from '@mui/icons-material'
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import AddIcon from '@mui/icons-material/Add';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";

const initialFormData = Object.freeze({
    status_name: "",
    description: "",
    category: "",
    status: "",
    jira_id: null,
    icon_url: null
});

const formErrData = Object.freeze({
    status_nameErr: "Status name is required",
    descriptionErr: "Description is required",
    categoryErr: "Category is required",
    statusErr: "Status is required"
});


const JiraStatus = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const location = useLocation();
    const modalOpen = location.pathname && location.pathname === "/all-jira/add" ? true : false;
    const [open, setOpen] = useState(modalOpen);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { jira_status_list, status, error, jira_status_create, jira_status_update, jira_status_archive, syncJiraStatus } = useSelector(state => state.jira_status)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [syncLoading, setSyncLoading] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"skip":0, "limit":25, "order_by": { "column": "-id" }}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    //columns visibility
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');

    useEffect(() => {
        dispatch(jiraStatusList(filterQuery))
        // eslint-disable-next-line
    }, [])

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            toast.error(error)
            // errorHandling(error, navigate)
        }
        // eslint-disable-next-line
    }, [error])


    if (reload === true && status === `${STATUSES.SUCCESS}_jira_status_update` && jira_status_update && jira_status_update.message) {
        setReload(false)
        if (jira_status_update.status === 200) {
            toast.success(jira_status_update.message)
            setTimeout(() => {
                dispatch(jiraStatusList(filterQuery))
            }, 500)
        } else {
            toast.error(jira_status_update.message)
        }
    }


    if (syncLoading === true && status === `${STATUSES.SUCCESS}_syncJiraStatus` && syncJiraStatus && syncJiraStatus.message) {
        toast(syncJiraStatus.message)
        setSyncLoading(false)
        setTimeout(() => {
            dispatch(jiraStatusList(filterQuery))
        }, 500);
    }


    const handleOpen = () => setOpen(true);
    const handleClose = () => { if (ID) { setID(0); setFormValues(initialFormData); } setOpen(false); setFormValues(initialFormData); setErrStatus(false); }

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.status_name === "" || formValues.description === "" || formValues.category === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.status_name !== "" && formValues.description !== "" && formValues.category !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id
            let addvalues = { ...formValues, name: formValues.status_name, created_by: userId }
            delete addvalues.status_name;
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(jiraStatusUpdate({ ID, addvalues }))
            } else {
                dispatch(jiraStatusCreate(addvalues))
            }


        }
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_jira_status_create` && jira_status_create && jira_status_create.message) {
        setReload(false)
        if (jira_status_create.status === 200) {
            toast.success(jira_status_create.message)
            setTimeout(() => {
                dispatch(jiraStatusList(filterQuery))
            }, 500)
        } else {
            toast.error(jira_status_create.message)
        }
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_jira_status_archive` && jira_status_archive && jira_status_archive.message) {
        toast.success(jira_status_archive.message)
        setReload(false)
        setArchiveOpen(false)
        setTimeout(() => {
            dispatch(jiraStatusList(filterQuery))
        }, 500)
    }


    const handleClickEdit = async (id) => {
        const res = await DataService.jira_status_edit(id)
        if (res && res.data && res.data.data) {
            let editData = res.data.data;
            setFormValues({
                status_name: editData.name,
                description: editData.description,
                category: editData.category,
                status: editData.status,
                jira_id: null,
                icon_url: null
            })
            setID(editData.id)
            setOpen(true)

        }

    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }


    const handleClickArchive = (data) => {
        setArchiveOpen(false)
        setReload(true)
        let addvalues = {
            category: data.category,
            name: data.name,
            description: data.description,
            jira_id: data.jira_id,
            icon_url: data.icon_url,
            status: !data.status,
        }
        let ID = data.id;
        setReload(true)
        dispatch(jiraStatusUpdate({ ID, addvalues }))
    }

    // const archiveModalClose = () => {
    //     setArchiveOpen(false)
    // }

    // console.log(jira_status_list && jira_status_list)
    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        dispatch(jiraStatusList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(jiraStatusList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(jira_status_list && jira_status_list.data && jira_status_list.data.rows && jira_status_list.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    const syncStatus = () => {
        setSyncLoading(true)
        dispatch(statusSyncByJira())

    }
    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(jiraStatusList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
        }
    }


    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent deleteIconName="ArchiveIcon"
                            moduleName="jira-status"
                            rowid={params.row.id}
                            addMoreData={{ jira_id: params.row.jira_id }}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(jiraStatusList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            // setPageSize(10)
            setFilterQuery(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`)
            dispatch(jiraStatusList(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}}`))
        }
    }
    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Jira Status List</h3>
                        <div>
                            <Tooltip title="Sync ">
                                <button className="btn-add-new" onClick={() => syncStatus()} >
                                    <Sync />
                                </button>
                            </Tooltip>

                            <Tooltip title="Add New Jira Status">
                                <button className="btn-add-new" onClick={handleOpen}>
                                    <AddIcon />
                                </button>
                            </Tooltip>
                            <RefreshButton api={jiraStatusList} filter={filterQuery} style={{ marginTop: 0 }} />

                        </div>
                    </div>
                    {(syncLoading === true || status === STATUSES.LOADING || reload === true) && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={(jira_status_list && jira_status_list.data && jira_status_list.data.rows && jira_status_list.data.rows) || []}
                        columns={jiraStatusTypeColumns.concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    fullWidth={true} maxWidth={"lg"} open={open} onClose={handleClose}>
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Jira Status</DialogTitle>
                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label >
                                        Jira Status Name
                                    </label>
                                    <input
                                        name="status_name"
                                        defaultValue={formValues.status_name}
                                        type="text"
                                        placeholder="Status Name"
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formValues && formValues.status_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.status_nameErr}</p>}
                                </div>

                                <div className="formInput">
                                    <label >
                                        Category
                                    </label>
                                    <input
                                        name="category"
                                        defaultValue={formValues.category}
                                        type="text"
                                        placeholder="Category Name"
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formValues && formValues.category === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.categoryErr}</p>}
                                </div>


                                <div className="formInput">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formValues.status}
                                            onChange={(e) => { handleChange(e) }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                        {errStatus === true && formValues && formValues.status === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.statusErr}</p>}
                                    </FormControl>
                                </div>

                                <div className="formInputDescription">
                                    <label >
                                        Description
                                    </label>

                                    <textarea defaultValue={formValues.description} name="description" onChange={handleChange} rows="3" placeholder="Description"></textarea>

                                    {errStatus === true && formValues && formValues.description === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.descriptionErr}</p>}
                                </div>

                            </div>
                        </form>
                    </DialogContent>
                    <DialogActions style={{ marginBottom: "15px" }}>
                        <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>

                        <button className="close-modal" onClick={handleClose}>{CONSTANTS.COMMON_BUTTONS.CLOSE}</button>
                    </DialogActions>
                </Dialog>


                {/* for delete popup */}
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are You Sure To ${changeStatus} Jira Status`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default JiraStatus