
import './Workflows.scss'
import BasicLayout from '../../components/layout/BasicLayout'
import React, { useEffect, useState } from "react"
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
import LoaderForm from '../../components/common-function/LoaderForm';

import dataService from "../../redux/services/data.service"
import {
    workFlowList,
    clearState,
    statusList, workflowMappingForWorkflowId
} from "../../redux/features/workflowSlice"
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';
import CustomButton from '../../components/button/CustomButton';
import moment from 'moment';

const { REACT_APP_ENV } = process.env;
const EditWorkflowMapping = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    const userId = rtm_user.user.data.id;
    const workFlowDetail = useLocation().state;
    const workFlowId = useLocation().state.id;

    const statusParam = { filter: { is_jira_status: { value: "False", operation: "eq" } }, "no_limit": "True" }

    const [formData, setFormData] = useState([])

    const [relatedStatusOption, setRelatedStatusOption] = useState([])
    const [isInitialStatus, setIsInitialStatus] = useState("")
    const [isInitialRendering, setIsInitialRendering] = useState(true)
    const [relatedDefaultStatus, setRelatedDefaultStatus] = useState({})
    const [refresh, setRefresh] = useState(false);
    const [refreshMapping, setRefreshMapping] = useState(false)

    const {
        workflows, status_List, workflow_Status, sts_status, workflow_StatusMapping, workflow_StatusMapSingleObj,
    } = useSelector(state => state.workflow)

    const handleSubmit = async (e) => {
        e.preventDefault();

        let statusIs = formData.status && formData.status.value
        statusIs = statusIs === undefined ? workFlowDetail.status : statusIs
        const postData = {
            "status": statusIs,
            "workflow": workFlowId,
            workflow_status: relatedStatusOption.map(e => { return e.id }),
            status_mapping: relatedStatusOption.map(e => ({
                "workflow": workFlowId,
                "workflow_status": e.id,
                "is_initial": isInitialStatus?.value === e.id,
                "related_status": relatedDefaultStatus[e.id].map(e => e.id),
                "created_by": userId
            })),
            "updated_by": userId
        };
        const res = await dataService.assign_project_workflow(postData);
        if (res.data && res.data.status === 200) {
            toast.success(res.data.message)
            clearStateValues();
            setTimeout(() => {
                navigate(-1)
            }, 3000);
        }
        else {
            toast.error(res.data.message)
        }
        setFormData({})
    }

    const clearStateValues = () => {
        dispatch(clearState())
        setIsInitialStatus({});
    }

    const handleChange = (evt) => {
        let eventValue = {}
        if (evt.type && evt.type === "date") {
            eventValue = { name: evt.name, type: evt.type, value: evt.format ? moment(evt.value).format(evt.format) : evt.value }
        }
        // const { name, value, type, selected, checked } = (evt && evt.target) ? evt.target : eventValue;
        const { name, value, type, checked } = (evt && evt.target) ? evt.target : eventValue;
        const finalValue = type === "checkbox" ? checked : type === "select" ? value.value : value
        if (name === "workflow_status") {
            setRelatedStatusOption(finalValue);
        }
        if (name === "default_status") {
            setIsInitialStatus(finalValue)
        }
        setFormData({ ...formData, [name]: finalValue })
    }

    const handleStatusListChange = (evt, isRemoving = false) => {
        setIsInitialRendering(false)
        isRemoving && setRefreshMapping(true)
        // const { name, value } = evt.target;
        const { value } = evt.target;
        const workflowStsArr = value.map(e => e.id)
        const newInitialStatus = workflowStsArr.includes(isInitialStatus.id) ? isInitialStatus : ""
        setIsInitialStatus(newInitialStatus);
        setRelatedStatusOption(value);
        let newDefSts = {}
        // value.map(e => {
        value.forEach(e => {
            newDefSts = {
                // ...newDefSts, [e.id]: relatedDefaultStatus[e.id] ? relatedDefaultStatus[e.id].filter(e => {
                //     if (workflowStsArr.includes(e.id)) {
                //         return e;
                //     }
                ...newDefSts, [e.id]: relatedDefaultStatus[e.id] ? relatedDefaultStatus[e.id].filter((e) =>
                    (workflowStsArr.includes(e.id))
                ) : []
            }

        })
        setRelatedDefaultStatus(newDefSts);
        setTimeout(() => {
            setRefreshMapping(false)
        }, 100);
    }

    const handleStatusMappingChange = (evt, name) => {
        setRelatedDefaultStatus({ ...relatedDefaultStatus, [name]: evt });
    }

    useEffect(() => {
        dispatch(statusList(statusParam))
        dispatch(workFlowList())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // workflow_StatusMapping.filter(e => {
    //     if (e.is_initial) {
    //         if (workFlowDetail.id && !isInitialStatus.id && isInitialRendering) {
    //             setIsInitialStatus({ id: e.workflow_status.id, label: e.workflow_status.name, value: e.workflow_status.id, name: "default_status" })
    //         }
    //     }
    // })
    workflow_StatusMapping.filter(e => {
        let arr =[]
        if (e.is_initial) {
            if (workFlowDetail.id && !isInitialStatus.id && isInitialRendering) {
                setIsInitialStatus({ id: e.workflow_status.id, label: e.workflow_status.name, value: e.workflow_status.id, name: "default_status" })
            }
        }
        return arr
    })

    useEffect(() => {
        workFlowDetail?.id && dispatch(workflowMappingForWorkflowId(workFlowDetail.id))
        setRefresh(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (workFlowDetail?.id && workflow_Status === STATUSES.SUCCESS && refresh) {

        const defaultArr = workflow_StatusMapSingleObj.workflow_mapping.map((option, index) => {
            const keyName = option.workflow_status.toString()
            return ({
                // [keyName]: status_List.filter((e, index) => {
                //     if (option.related_status.includes(e.id)) {
                //         return e;
                //     }
                [keyName]: status_List.filter((e, index) =>
                    (option.related_status.includes(e.id))
                ),
            })
        })
        let newObj = {}
        // defaultArr.map(e => {
        defaultArr.forEach(e => {
            newObj = { ...newObj, ...e }
        })
        setRelatedDefaultStatus(newObj)

        //     setRelatedStatusOption(status_List.filter(e => {
        //         // const rltdStsArr = workflow_StatusMapSingleObj.workflow_status_id
        //         if (workflow_StatusMapSingleObj?.workflow_status_id?.includes(e.id)) {
        //             return e
        //         }
        //     }));
        //     setRefresh(false)
        // }
        setRelatedStatusOption(status_List.filter((e) =>
            (workflow_StatusMapSingleObj?.workflow_status_id?.includes(e.id))
        ));
        setRefresh(false)
    }

    return (
        <BasicLayout>
            <div className="top-headings">
                <h3 >  Edit Workflow Mapping </h3>
                <div>
                    {
                        <CustomButton title={" "}
                            tooltip="Back"
                            preBackIcon
                            className="btn-add-new "
                            onClick={() => { clearStateValues(); navigate(-1) }}
                        />
                    }
                </div>
            </div>

            <ToastContainer />
            {(sts_status === STATUSES.LOADING || workflow_Status === STATUSES.LOADING) ? <LoaderForm />
                :
                <>
                    {
                        <form className="workflow-form" style={{ marginTop: "0", paddingBottom: "50px" }} onSubmit={handleSubmit}>
                            <div className="formInput" >
                                <label className='label-heading'>
                                    {"Workflow*"}
                                </label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder={"Select"}
                                    required
                                    isSearchable={true}
                                    isDisabled={true}
                                    name={"workflow"}
                                    defaultValue={workFlowDetail.id ? { id: workFlowDetail.id, label: workFlowDetail.name, value: workFlowDetail.id } : ""}
                                    options={workflows.length && workflows.map(e => ({ id: e.id, label: e.name, value: e.id, name: "workflow" }))}
                                    onChange={(evt) => { handleChange({ target: { name: "workflow", value: evt } }) }}
                                />

                            </div>

                            <div className="formInput" >
                                <label className='label-heading'>
                                    {"Workflow Status"}
                                </label>

                                <Multiselect
                                    isObject={true}
                                    onRemove={(evt) => { handleStatusListChange({ target: { name: "workflow_status", value: evt } }, true) }}
                                    onSelect={(evt) => { handleStatusListChange({ target: { name: "workflow_status", value: evt } }) }}
                                    displayValue={"label"}
                                    // required
                                    placeholder={"Select Workflow-Status"}
                                    // selectedValues={workFlowDetail.is_editing ? workflow_StatusMapping.map(e => ({ id: e.workflow_status.id, label: e.workflow_status.name, value: e.workflow_status.id, name: "workflow_status" })) : ""}
                                    selectedValues={workFlowDetail.is_editing ? workflow_StatusMapping.forEach(e => ({ id: e.workflow_status.id, label: e.workflow_status.name, value: e.workflow_status.id, name: "workflow_status" })) : ""}
                                    // options={status_List && status_List.map(e => {
                                        options={status_List && status_List.forEach(e => {
                                        if(!e.is_jira_project) {
                                            return { id: e.id, label: e.name, value: e.id, name: "workflow_status" }
                                        }
                                    })}

                                />

                            </div>

                            <>
                                {refreshMapping ? <LoaderForm /> :
                                    <>
                                        <div className="formInput" >
                                            <label className='label-heading'>
                                                {"Default Workflow Status*"}
                                            </label>
                                            <Select
                                                className="basic-single"
                                                placeholder="Select"
                                                isSearchable={true}
                                                required
                                                name={"default_status"}
                                                defaultValue={isInitialStatus}
                                                options={relatedStatusOption}
                                                onChange={(evt) => { handleChange({ target: { name: "default_status", value: evt } }) }}
                                            />

                                        </div>

                                        <div className="formInput" >
                                            <label className='label-heading'>
                                                {"Status*"}
                                            </label>
                                            <Select
                                                className="basic-single"
                                                placeholder="Select"
                                                isSearchable={true}
                                                required
                                                name={"status"}
                                                defaultValue={
                                                    (workFlowDetail.is_editing && (workFlowDetail?.status === true || workFlowDetail?.status === false)) ?
                                                        { label: workFlowDetail?.status ? "Active" : "Inactive", value: workFlowDetail?.status } : ""}
                                                options={
                                                    [
                                                        { id: 1, name: "Active", label: "Active", value: true },
                                                        { id: 2, name: "Inactive", label: "Inactive", value: false }
                                                    ]
                                                }
                                                onChange={(evt) => { handleChange({ target: { name: "status", value: evt } }) }}
                                            />

                                        </div>
                                        {
                                            relatedStatusOption && relatedStatusOption.length ?

                                                <>
                                                    <div className="formInput" >
                                                        <label className='label-heading'>
                                                            {"From Status"}
                                                        </label>
                                                    </div>
                                                    <div className="formInput" >
                                                        <label className='label-heading'>
                                                            {"Related Status"}
                                                        </label>
                                                    </div>
                                                    {
                                                        relatedStatusOption.map((element, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div className="formInput" style={{ marginBottom: "20px" }}>
                                                                        <Select
                                                                            className="basic-single"
                                                                            placeholder="Select"
                                                                            isDisabled
                                                                            required
                                                                            name={"status"}
                                                                            defaultValue={element}
                                                                            options={relatedStatusOption}
                                                                        />
                                                                    </div>
                                                                    <div className="formInput" style={{ marginBottom: "20px" }}>

                                                                        <Multiselect
                                                                            isObject={true}
                                                                            onRemove={(evt) => { handleStatusMappingChange(evt, element.id) }}
                                                                            onSelect={(evt) => { handleStatusMappingChange(evt, element.id) }}

                                                                            displayValue={"label"}
                                                                            placeholder={"Select Related-Status"}
                                                                            selectedValues={(relatedDefaultStatus && relatedDefaultStatus[element.id]) ? relatedDefaultStatus[element.id] : ""}
                                                                            // options={relatedStatusOption}
                                                                            options={relatedStatusOption.filter(e => e.id !== element.id && e)}
                                                                        />

                                                                    </div>
                                                                </React.Fragment>

                                                            )
                                                        })
                                                    }
                                                </>
                                                : ""
                                        }
                                    </>
                                }
                            </>


                            <CustomButton
                                loading={false}
                                type={"submit"}
                            />
                        </form>
                    }
                </>
            }
        </BasicLayout>
    )
}

export default EditWorkflowMapping

