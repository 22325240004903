import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationCreate, locationEdit, locationUpdate, getLocationList, getCountryList, getStateList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Select from 'react-select';

const initialFormData = Object.freeze({
  city_name: "",
  code: "",
  country: "",
  state: "",
  status: "",

});

const formErrData = Object.freeze({
  city_nameErr: "City is required",
  code_Err: "Code is required",
  country_Err: "Country name is required",
  state_Err: "state is required",
  statusErr: "Status is required"
});


const PreferredLocation = () => {
  const { REACT_APP_ENV } = process.env;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(initialFormData)
  const [formErr] = useState(formErrData)
  const [errStatus, setErrStatus] = useState(false);
  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const { status, error, location_List, location_Create, location_Edit, location_Update, country_List, state_List } = useSelector(state => state.master)
  const [currentPage, setCurrentPage] = useState(0);
  const [ID, setID] = useState("");
  const [reload, setReload] = useState(false)
  const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.LOCATION_LIST_COLUMNS}]}`)
  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [changeStatus, setChangeStatus] = useState('');

  useEffect(() => {
    dispatch(getLocationList(filterQuery))
    dispatch(getCountryList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))
    dispatch(getStateList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))
    if (status === STATUSES.FAILURE && error) {
      setReload(false)
      errorHandling(error, navigate)
    }
    // eslint-disable-next-line
  }, [])

  if (reload === true && status === `${STATUSES.SUCCESS}_location_Update` && location_Update && location_Update.message) {
    if (location_Update.status === 200) {
      toast.success(location_Update.message)
      dispatch(getLocationList(filterQuery))
    } else {
      toast.error(location_Update.message)
    }
    setReload(false)
  }

  if (reload === true && status === `${STATUSES.SUCCESS}_location_Create` && location_Create && location_Create.message) {
    if (location_Create.status === 200) {
      toast.success(location_Create.message)
      dispatch(getLocationList(filterQuery))
    } else {
      toast.error(location_Create.message)
    }
    setReload(false)
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
    setErrStatus(false);
  }

  const handleChange = (evt) => {
    const { name, value } = evt.target || evt;
    setFormValues({ ...formValues, [name]: value })

  }


  const submitData = (e) => {
    e.preventDefault()

    if (formValues.code === "" || formValues.city_name === "" || formValues.country === "" | formValues.state === "" || formValues.status === "") {
      setErrStatus(true)
    }

    if (formValues.code !== "" && formValues.city_name !== "" && formValues.status !== "") {
      const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
      let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id
      let addvalues = { ...formValues, created_by: userId, country: formValues.country.value ? formValues.country.value : formValues.country, state: formValues.state.value ? formValues.state.value : formValues.state }
      setErrStatus(false)
      setReload(true)
      handleClose()
      if (ID) {
        dispatch(locationUpdate({ ID, addvalues }))
      } else {
        dispatch(locationCreate(addvalues))
      }
    }
  }

  const selectValueInLable = (id) => {
    let data = country_List.data && country_List.data.rows;
    let newObject = null
    data && data.length > 0 && data.map(x => {
      return (x.id === id) && (newObject = { value: x.id, label: x.name })

    })
    return newObject
  }

  const selectValueInState = (id) => {
    let data = state_List.data && state_List.data.rows;
    let newObject = null
    data && data.length > 0 && data.map(x => {
      return (x.id === id) && (newObject = { value: x.id, label: x.name })

    })
    return newObject
  }

  if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && location_Edit && location_Edit.data) {
    let editData = location_Edit.data
    setFormValues({
      city_name: editData.city_name,
      code: editData.code,
      country: selectValueInLable(editData.country_id),
      state: selectValueInState(editData.state_id),
      status: editData.status
    })
    setID(editData.id)
    setOpen(true)
    setReload(false)
  }

  const handleClickEdit = (id) => {
    dispatch(locationEdit(id))
    setReload('edit_data')
  }

  const handleArchive = (id) => {
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
      setChangeStatus("InActive")
    } else {
      setChangeStatus("Active")
    }
  }

  const handleClickArchive = (data) => {
    setReload(true)
    setArchiveOpen(false)
    let addvalues = {
      city_name: data.city_name,
      country: data.country,
      state: data.state,
      code: data.code,
      status: !data.status,
      updated_by: GetLocalstorage.userDetail().id
    }
    let ID = data.id;
    setReload(true)
    dispatch(locationUpdate({ ID, addvalues }))
  }

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    setLimit(limit)
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getLocationList(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getLocationList(JSON.stringify(newFilterData)))
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(location_List && location_List.data && location_List.data.rows && location_List.data.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // sorting
  const handleSort = (sort) => {
    if (sort.length) {
      let newFilterData = JSON.parse(filterQuery);
      newFilterData['skip'] = 0;
      newFilterData['limit'] = limit;
      newFilterData['order_by'] = {
        "column": sort[0].sort === "desc" ?
          '-' + sort[0].field : sort[0].field
      }
      setFilterQuery(JSON.stringify(newFilterData))
      dispatch(getLocationList(JSON.stringify(newFilterData)))
    } else {
      setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.LOCATION_LIST_COLUMNS}] }`)
    }
  }


  const actionColumn = [
    {
      field: "city_name",
      headerName: "Location",
      width: 280,
      renderCell: (params) => {
        return (
          <div>
            {params.row.city_name}
          </div>
        );
      },
    },
    {
      field: "code",
      headerName: "Location Code",
      width: 280,
      renderCell: (params) => {
        return (
          <div>
            {params.row.code}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status === true ? 'Active' : 'Inactive'}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: "120",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              deleteIconName="ArchiveIcon"
              moduleName="location"
              rowid={params.row.id}
              addMoreData=""
              editLinkUrl=""
              viewLinkUrl=""
              isDelete={true}
              editButton={handleClickEdit}
              viewButton={false}
              deleteButton={() => handleArchive(params.row)} />
          </div>
        );
      },
    },
  ];

  const onFilterChange = (filterModel) => {
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
          filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
      const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.LOCATION_LIST_COLUMNS}]}`
      setFilterQuery(filterData)
      setCurrentPage(0)
      dispatch(getLocationList(filterData))
    }
    if (filterModel.items && filterModel.items.length === 0) {
      setCurrentPage(0)
      //setPageSize(10)
      setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.LOCATION_LIST_COLUMNS}]}`)
      dispatch(getLocationList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.LOCATION_LIST_COLUMNS}]}`))
    }
  }
  return (
    <div className="jiraStatus">
      <Sidebar />
      <div className="jiraStatusContainer">
        <Navbar />

        <ToastContainer />
        <div className="datatable">
          <div className="top-headings">
            <h3>Preferred Location List</h3>
            <div>
              <Tooltip title="Add Preferred Location">
                <button onClick={handleOpen} className="btn-add-new ">
                  <Add />
                </button>
              </Tooltip>
              <RefreshButton api={getLocationList} filter={filterQuery} style={{ marginTop: 0 }} />

            </div>
          </div>
          {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
          <DataGrid
            className="datagrid"
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            rows={location_List && location_List.data && location_List.data.rows ? location_List.data.rows : []}
            columns={actionColumn}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            onFilterModelChange={onFilterChange}
            disableSelectionOnClick
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowsOverlay
            }}
            onSortModelChange={(sort) => handleSort(sort)}
          />
        </div>

        <Dialog
          hideBackdrop
          fullWidth
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
        >
          <Button
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ position: "absolute", top: "10px", right: "10px" }}>
            <Close />
          </Button>
          <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Preferred Location</DialogTitle>

          <DialogContent>
            <form>
              <div className="add-new-user-form">
                <InputFields
                  label={"Preferred Location"}
                  name="city_name"
                  defaultValue={formValues.city_name}
                  type="text"
                  placeholder="Preferred Location"
                  onChange={handleChange}
                  errStatus={errStatus}
                  formErr={formErr.city_nameErr}
                />
                <InputFields
                  label={"Location code"}
                  name="code"
                  defaultValue={formValues.code}
                  type="text"
                  placeholder="Location-code"
                  onChange={handleChange}
                  errStatus={errStatus}
                  formErr={formErr.code_Err}
                />
                <div className="formInput">
                  <label >
                    Select Country
                  </label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    divstyle={{ flexBasis: '100%' }}
                    placeholder={'Select Country'}
                    onChange={handleChange}
                    defaultValue={formValues.country}
                    isSearchable={true}
                    name={'name'}
                    options={
                      country_List && country_List.data && country_List.data.rows.map((option) => {
                        return {
                          label: option.name,
                          value: option.id,
                          name: 'country'
                        }
                      })
                    }
                  />
                  {errStatus === true && formValues && formValues.country === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.country_Err}</p>}
                </div>

                <div className="formInput">
                  <label >
                    Select State
                  </label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    divstyle={{ flexBasis: '100%' }}
                    placeholder={'Select State'}
                    onChange={handleChange}
                    defaultValue={formValues.state}
                    isSearchable={true}
                    name={'name'}
                    options={
                      state_List && state_List.data && state_List.data.rows.map((option) => {
                        return {
                          label: option.name,
                          value: option.id,
                          name: 'state'
                        }
                      })
                    }
                  />
                  {errStatus === true && formValues && formValues.state === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.state_Err}</p>}
                </div>

                <InputRadioGroupFields
                  label={"Status"}
                  name="status"
                  defaultValue={formValues.status}
                  type="text"
                  placeholder="Status"
                  onChange={handleChange}
                  errStatus={errStatus}
                  formErr={formErr.statusErr}
                />

              </div>
              <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
            </form>
          </DialogContent>
        </Dialog>
        <DeleteDialogModal
          open={archiveOpen}
          onClose={setArchiveOpen}
          heading={changeStatus}
          paragraph={`Are you sure to ${changeStatus} Location`}
          handleArchive={handleClickArchive}
          id={archiveId}
        />
      </div>
    </div >
  )
}

export default PreferredLocation;