import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./hiring.scss";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { toast, ToastContainer } from "react-toastify";
import parse from 'html-react-parser';
import { jobPostEdit } from "../../redux/features/jobPostSlice";
import GetLocalstorage from "../common-function/GetLocalstorage";

const user = GetLocalstorage.userDetail();
const tmsRole = user && user.tms_role

const ViewJobList = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { view_list, status, error } = useSelector(state => state.jobpost)
    let view_data = view_list && view_list.data && view_list.data.hiring_request


    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
        }
        // eslint-disable-next-line
    }, [error])

    useEffect(() => {
        if (id) {
            dispatch(jobPostEdit(id))
        }
        // eslint-disable-next-line
    }, [dispatch])
    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                <ToastContainer />
                <div className="top-headings">
                    <h3>View Job Post List </h3>
                    <div>
                        <Tooltip title="Back">
                            <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                        </Tooltip>
                        <RefreshButton api={jobPostEdit} filter={id} style={{ marginTop: 5 }} />
                    </div>
                </div>
                {(status === STATUSES.LOADING) && <LoaderForm />}

                <div className="view-details-row">
                    <div className="detailItem">
                        <span className="itemKey">Project :</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.project && view_list.data.project.project_name ? view_list.data.project.project_name : "N/A"}</span>
                    </div>
                    <div className="detailItem">
                        <span className="itemKey">Job Title :</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.job_title ? view_list.data.job_title : "N/A"}</span>
                    </div>
                    <div className="detailItem">
                        <span className="itemKey">Industry :</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.industry && view_list.data.industry.industry_name ? view_list.data.industry.industry_name : "N/A"}</span>
                    </div>
                    <div className="detailItem">
                        <span className="itemKey">Department :</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.department && view_list.data.department.name ? view_list.data.department.name : "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Job Responsibilities :</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.hiring_request &&
                            view_list.data.hiring_request.job_responsibilities ? view_list.data.hiring_request.job_responsibilities
                            : "N/A"}</span>
                    </div>
                    <div className="detailItem">
                        <span className="itemKey"> Client :</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.hiring_request &&
                            view_list.data.hiring_request.client?.name ? view_list.data.hiring_request.client?.name
                            : "N/A"}</span>
                    </div>
                    <div className="detailItem">
                        <span className="itemKey"> Contract Type :</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.hiring_request &&
                            view_list.data.hiring_request.contract_type?.name ? view_list.data.hiring_request.contract_type?.name
                            : "N/A"}</span>
                    </div>
                    <div className="detailItem">
                        <span className="itemKey"> Number Of Vacancies:</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.hiring_request &&
                            view_list.data.hiring_request.number_of_vacancies ? view_list.data.hiring_request.number_of_vacancies
                            : "N/A"}</span>
                    </div>



                    <div className="detailItem">
                        <span className="itemKey"> Type of Vacancy  :</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.hiring_request &&
                            view_list.data.hiring_request.type_of_vacancy?.vacancy_type ? view_list.data.hiring_request.type_of_vacancy?.vacancy_type
                            : "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Working Mode :</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.work_mode && view_list.data.work_mode ? view_list.data.work_mode : "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey"> Urgency  :</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.hiring_request &&
                            view_list.data.hiring_request.urgency?.urgency_type ? view_list.data.hiring_request.urgency?.urgency_type
                            : "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Expected ETA In Years :</span>
                        <span className="itemKey2">{view_list && view_list.data && view_list.data.expected_ETA_in_years ? view_list.data.expected_ETA_in_years : "0"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Relevant Experience In Months :</span>
                        <span className="itemKey2">{view_data && view_data.relevant_experience_expected_in_months ? view_data.relevant_experience_expected_in_months : "0"}</span>
                    </div>



                    <div className="detailItem">
                        <span className="itemKey">Relevant Experience In Years  :</span>
                        <span className="itemKey2">{view_data && view_data.relevant_experience_expected_in_years ? view_data.relevant_experience_expected_in_years : "N/A"}</span>
                    </div>

                    {
                        view_list && view_list.data &&
                        <div className="detailItem" style={{ float: "left" }}>
                            <span className="itemKey" style={{ float: "left" }}>Add More Specifications :</span>
                            <span className="itemKey2">
                                {view_list.data && view_list.data.add_more_specifications_data && view_list.data.add_more_specifications_data.length > 0 ?
                                    view_list.data.add_more_specifications_data.map((e, index) => `${index ? ', ' : ''}${(e.specification_name).trim()}`) : "N/A"}
                            </span>
                        </div>
                    }

                    {
                        view_data && view_data.job_location &&
                        <div className="detailItem" style={{ float: "left" }}>
                            <span className="itemKey" style={{ float: "left" }}>Job Location :</span>
                            <span className="itemKey2">
                                {view_data && view_data.job_location && view_data.job_location.job_location ? view_data.job_location.job_location : "N/A"}
                            </span>
                        </div>
                    }

                    {
                        view_data && view_data.technology_data &&

                        <div className="detailItem" style={{ float: "left" }}>
                            <span className="itemKey" style={{ float: "left" }}> Technology :</span>
                            <span className="itemKey2">
                                {view_data && view_data.technology_data && view_data.technology_data.length > 0 ? view_data.technology_data.map((e, index) => `${index ? ', ' : ''}${(e.specialization && e.specialization.name).trim()}`) : "N/A"}
                            </span>
                        </div>
                    }
                    {tmsRole && (tmsRole.role_key === "admin" || tmsRole.role_key === "hr") &&
                    <React.Fragment>
                   <div className="detailItem">
                        <span className="itemKey">Client Budget From :</span>
                        <span className="itemKey2">{view_data  && view_data.client_budget_from ? view_data.client_budget_from : "0"}</span>
                    </div>
                    <div className="detailItem">
                        <span className="itemKey">Client Budget To :</span>
                        <span className="itemKey2">{view_data  && view_data.client_budget_to ? view_data.client_budget_to : "0"}</span>
                    </div>
                    </React.Fragment>
                    }


                    {/* {
                        view_list && view_list.data &&
                        <div className="detailItem" style={{ float: "left" }}>
                            <span className="itemKey" style={{ float: "left" }}>Required Skill Set :</span>
                            <span className="itemKey2">
                                {view_list.data && view_list.data.required_skill_set_data && view_list.data.required_skill_set_data.length > 0 ? view_list.data.required_skill_set_data.map((e, index) => `${index ? ', ' : ''}${(e.skill_name).trim()}`) : "N/A"}
                                 { view_data.technology_data && view_data.technology_data.[]] && view_data.technology_data.specialization && view_data.technology_data[0].specialization.length >0 ? view_data.technology_data.filter((e)=>e.index).specialization.map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "N/A"}
                            </span>
                        </div>
                    } */}

                    <div className={"detailItem"}>
                        <span className="itemKey" style={{ float: 'left' }}>Job Description :</span>
                        <span className="itemKey2 item-value" style={{ float: 'left' }}>
                            <span className={view_list && view_list.data && parse(view_list.data.job_description).length > 70 ? "para-text" : "text"}>
                                {view_list && view_list.data && parse(view_list.data.job_description)}</span>
                        </span>
                    </div>


                </div>

            </div>
        </div >
    )

}

export default ViewJobList