import * as React from 'react';
import { Dialog, DialogContent, DialogTitle, Button } from "@mui/material";
import { useState } from "react";
import { Close } from '@mui/icons-material';
import { InputFields, InputTextareaField } from '../../components/common-function/InputFields';
import LoaderForm from '../../components/common-function/LoaderForm';
import { memo } from 'react';

const initialFormData = Object.freeze({
    email: "",
    password: "",
    remark: "",
});

const formErrData = Object.freeze({
    nameErr: "Email is required",
    passwordErr: "Password is required",
    commentErr: "Comment is required"
});

const Skip = ({ open, setOpen, skipRowData, skipformValues, setSkipFormValues, submitData, setLoading, loading }) => {

    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [validateErr, setValidateErr] = useState("")

    const handleClose = () => {
        setOpen(false);
        setSkipFormValues(initialFormData);
        setErrStatus(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setSkipFormValues({ ...skipformValues, [name]: value })
    }

    const validate = (email, empCode) => {
        const errors = {}

        if (email !== null && empCode === null) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                errors.email = 'Please enter valid email address'
            } else {
                errors.email = true
            }
        }
        var pattern = /^[a-zA-Z0-9-]*$/;
        if (empCode !== null && email === null) {
            if (!pattern.test(empCode)) {
                errors.empCode = "Please enter valid employee Id";
            } else {
                errors.empCode = true
            }
        }

        return errors
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (!skipformValues.email || !skipformValues.password || !skipformValues.remark) {
            setErrStatus(true)
        }

        if (skipformValues.email !== "" && skipformValues.password !== "" && skipformValues.remark !== "") {
            const checkEmail = skipformValues.email.includes('@')
            let validateCheck
            if (checkEmail === true) {
                validateCheck = validate(skipformValues.email, null)
            } else {
                validateCheck = validate(null, skipformValues.email)
            }
            if (validateCheck.email === true || validateCheck.empCode === true) {
                let addvalues = {
                    ...skipformValues,
                    hiring_story_screenflow_status: 3
                }
                setErrStatus(false)
                setLoading(true)
                submitData(addvalues)
                setValidateErr("")
                setErrStatus(false)
            } else {
                setErrStatus(true)
                setValidateErr(validateCheck)
            }
        }
    }

    return (
        <div className="jiraStatus">
            <div className="jiraStatusContainer">
                {/* form section */}
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"sm"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description">
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>User Authorization</DialogTitle>
                    <DialogContent>
                        {loading === true && <LoaderForm />}
                        <form>
                            <div className="add-new-user-form">
                                <div className='formInput' style={{ flexBasis: "100%" }}>
                                    <InputFields
                                        label={"Email"}
                                        name="email"
                                        defaultValue={skipformValues.email}
                                        type="text"
                                        placeholder="Email"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.nameErr}
                                    />
                                    {errStatus === true && validateErr && validateErr.email !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.email}</p>}
                                    {errStatus === true && validateErr && validateErr.empCode !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.empCode}</p>}

                                </div>
                                <div className='formInput' style={{ flexBasis: "100%" }}>
                                    <InputFields
                                        label={"Password"}
                                        name="password"
                                        defaultValue={skipformValues.password}
                                        type="password"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.passwordErr}
                                    />
                                </div>
                                <InputTextareaField
                                    label={"Comment"}
                                    name="remark"
                                    defaultValue={skipformValues.remark}
                                    type="text"
                                    placeholder="Write a comment here......."
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.commentErr}
                                />
                            </div>
                            <button className="submit-modal" onClick={onSubmit}>
                                Submit
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}
export default memo(Skip)