import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';
import { ArrowBack } from "@mui/icons-material";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from "../../redux/common-status/constant";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import "./manageAvailability.scss"
import { availabiltyDetails, getAvailabiltyList } from "../../redux/features/interviewSlice";
import ViewAvailability from "./ViewAvailability";
import { TablePagination, Tooltip } from "@mui/material";
import CreateAvailability from "./CreateAvailability";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import moment from "moment";
import { interviewerListOption } from "../../redux/features/optionsSlice";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import Filter from "../../components/Filter";

const AllAvailability = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { status, error, manageAvailability_List, manageAvailability_Details_View } = useSelector(state => state.interview)
    const [currentPage, setCurrentPage] = useState(0);
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25}`)
    const { interviewers } = useSelector(state => state.dropdownOptions)

    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    //view availability
    const [activeViewMode, setActiveViewMode] = useState(false)
    const [viewData, setViewData] = useState({})
    const [slotDetails, setSlotDetails] = useState([])
    // custom filter
    const [fieldName, setFieldName] = useState("")
    const [defaultValue, setDefaultValue] = useState({})

    useEffect(() => {
        dispatch(getAvailabiltyList(filterQuery))
        dispatch(interviewerListOption({ "filter": { "is_interviewer": { "value": "True", "operation": "eq" } }, "order_by": { "column": "name" }, "no_limit": "True", "columns": DefaultFilterColumns.RESOURCE_ROLES_DROUPDOWN_COLUMNS }))
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getAvailabiltyList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getAvailabiltyList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(manageAvailability_List && manageAvailability_List.data && manageAvailability_List.data.rows && manageAvailability_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getAvailabiltyList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
        }
    }

    // action columns
    const actionColumn = [
        {
            field: "resource_id", headerName: "Interviewer Name", width: 240, type: 'singleSelect',
            valueOptions: interviewers,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.interviewer_details && params.row.interviewer_details.name ? params.row.interviewer_details.name : "N/A"}
                    </div>
                );
            },
        },
        {
            field: "from_date", headerName: "From Date", type: 'date', width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.from_date ? moment(params.row.from_date).format('DD-MM-YYYY') : "N/A"}
                    </div>
                );
            },
        },
        {
            field: "expiry_date", headerName: "To Date", type: 'date', width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.expiry_date ? moment(params.row.expiry_date).format('DD-MM-YYYY') : "N/A"}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="source-type"
                            rowid={params.row.id}
                            addMoreData={params.row.id}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={false}
                            editButton={false}
                            viewButton={handleClickView}
                            deleteButton={false} />
                    </div>
                );
            },
        },
    ];

    //filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                        filterModel.items[0].operatorValue === "is" ? 'eq' :
                            filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getAvailabiltyList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setDefaultValue("")
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getAvailabiltyList(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`))
        }
    }

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                // console.log(value, "value")
                applyValue({ ...item, value: value });
            }
        };
        return (
            fieldName === "resource_id" ?
                <Filter options={interviewers} onChange={handleFilterChange} defaultValue={defaultValue} />
                : ""
        );
    }

    const columns = React.useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "resource_id") {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                ? CustomFilterInputValue
                                : undefined
                        }))
                };
            }
            return col;
        }),
        // eslint-disable-next-line
        [actionColumn]
    );

    // const handleOpen = () => {
    //     setOpen(true)
    // };

    const handleClose = () => {
        // if (ID) { setID(0); setFormValues(initialFormData) }
        setOpen(false);
        // setSelectTechnology("");
        // clearStates();
    }

    const handleClickView = (id) => {
        dispatch(availabiltyDetails(`{"filter":{"interviewer_schedule_setting_id":{"value":${id},"operation": "eq"}},"order_by":{"column":"-id"},"no_limit":True}`))
        setReload('view_data')
        setActiveViewMode(true)
        const filterViewData = manageAvailability_List && manageAvailability_List.data && manageAvailability_List.data.rows.find((ele) => ele.id === id)
        let editData = filterViewData
        editData && setViewData(editData)

    }
    const closeViewMode = () => {
        setActiveViewMode(false)
    }

    if (reload === 'view_data' && status === `${STATUSES.SUCCESS}_manageAvailability_Details_View` && manageAvailability_Details_View && manageAvailability_Details_View.data) {
        let editData = manageAvailability_Details_View.data && manageAvailability_Details_View.data.rows
        editData && setSlotDetails(editData)
        setActiveViewMode(true)
        setReload(false)
    }

    return (
        <div className="manage-availability">
            <Sidebar />
            <div className="manage-availability-container">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    {/* heading */}
                    <div className="top-headings">
                        <h3>All Interviewer Availability</h3>
                        <div>
                            {/* <Tooltip title={"Create your availability"}>
                                <button onClick={handleOpen} className="btn-add-new" style={{ marginRight: "5px" }}>
                                    <Add />
                                </button>
                            </Tooltip> */}
                            <RefreshButton api={getAvailabiltyList} filter={filterQuery} style={{ marginTop: 0 }} />

                            <Tooltip title="Back">
                                <button onClick={() => navigate(-1)} className="btn-add-new" style={{ marginLeft: "4px" }}>
                                    <ArrowBack />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {/* data grid (table) */}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={manageAvailability_List && manageAvailability_List.data && manageAvailability_List.data.rows ? manageAvailability_List.data.rows : []}
                        columns={columns}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>
                {/* for view section */}
                <ViewAvailability
                    openModal={activeViewMode}
                    setOpenModal={closeViewMode}
                    viewData={viewData}
                    slotDetails={slotDetails}
                />

                {/* for add section */}
                <CreateAvailability
                    openModal={open}
                    closeModal={handleClose}
                    listApi={getAvailabiltyList}
                    filters={filterQuery}
                />
            </div >
        </div >
    )
}
export default AllAvailability