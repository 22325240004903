import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import Widget from "../../components/widget/Widget"

import "./dashboardMenu.scss"
import { useDispatch, useSelector } from "react-redux";
import { allProjects, allResources, allShift, allTask, checkIsInterviewerAval, myProjects } from "../../redux/features/dashboardSlice";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { Dialog, DialogContent } from "@mui/material";
const rtm_user = GetLocalstorage.userDetail()
const availability = localStorage.getItem('is_interviewr_availabilty')

const DashboardMenu = () => {

    if (window.location === window.location.origin + '/dashboard' || window.location === `${window.location.origin}/dashboard`) {
        window.location = '/dashboard?reload=done'
    }
    const dispatch = useDispatch();

    const { resourceList, shiftList, projectList, myProjectList, taskList, interviewerAval} = useSelector(state => state.dashboardCard)
    const tmsRole = rtm_user && rtm_user.tms_role
    const [ avl, setAvl] = useState((availability === 'true' ||interviewerAval === true) ? true :  false)
    //const [openInterviewerModal, setOpenInterviewerModal] = useState(rtm_user.is_interviewer === true ? avl === true ? false : true : avl === true ? false : true)
    const [openInterviewerModal, setOpenInterviewerModal] = useState(false)

    useEffect(() => {
        if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
            dispatch(allResources('{"skip":0,"limit":10}'))
            dispatch(allShift('{"skip":0,"limit":10}'))
            // dispatch(allReport())
            dispatch(allProjects())
            dispatch(allResources(`{"skip":0, "limit":10}`))
        } else {
            dispatch(allTask())
            dispatch(myProjects())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    const closeModal = () => {
        setAvl(true)
        dispatch(checkIsInterviewerAval())
        localStorage.setItem("is_interviewr_availabilty", true)
        setOpenInterviewerModal(false)
    }


    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />

                <div className="widgets">
                    {
                        tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ?
                            <>
                                <Widget type="resources" count={(resourceList && resourceList.count) ? resourceList.count : 0} />
                                <Widget type="projects" count={(projectList && projectList.count) ? projectList.count : 0} />
                                <Widget type="shifts" count={(shiftList && shiftList.count) ? shiftList.count : 0} />
                                <Widget type="reports" count={2} />
                            </>
                            : <>
                                <Widget type="tasks" count={(taskList && taskList.count) ? taskList.count : 0} />
                                <Widget type="myProjects" count={(myProjectList && myProjectList.count) ? myProjectList.count : 0} />
                            </>
                    }
                </div>
                <Dialog
                    open={openInterviewerModal}
                    onClose={() => closeModal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">

                    <DialogContent>
                        <div className="popup-confirming">
                            <h3 style={{ color: '#ff0000d1' }}>Assignment for Interviewer</h3>
                            <p>{"If you do not mark your custom availability, It will be considered you are available throughout your shift time for any interviews."}</p>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* <div className="charts">
                    <Featured />

                </div> */}
            </div>

        </div>
    )
}

export default DashboardMenu