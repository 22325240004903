import { memo } from "react"
import { InputEditorField, InputFields, InputSelectField } from "../../../components/common-function/InputFields";
import Select from 'react-select';
import { Tooltip } from "@mui/material";
import { AttachFile, Close, Download } from "@mui/icons-material";
import LoaderForm from "../../common-function/LoaderForm";
import SmallLoader from "../../common-function/SmallLoader";
import CreatableSelect from 'react-select/creatable';
import { useState } from "react";
// import GetLocalstorage from "../../common-function/GetLocalstorage";

var etaMonthArr = [];
var etaDayArr = [];
var experienceMonthArr = [];
var experienceYearArr = [];

for (let i = 0; i <= 24; i++) {
    etaMonthArr.push(i);
}

for (let i = 0; i <= 31; i++) {
    etaDayArr.push(i);
}

for (let i = 0; i <= 50; i++) {
    experienceYearArr.push(i);
}

for (let i = 0; i <= 11; i++) {
    experienceMonthArr.push(i);
}

const timeTotalToArray = (i) => {
    var timeArrayForTo = [];
    for (i; i <= 23; i++) {
        timeArrayForTo.push(i);
    }
    return timeArrayForTo
}
const timeRelevantToArray = (i) => {
    var timeArrayForTo = [];
    for (i; i <= 23; i++) {
        timeArrayForTo.push(i);
    }
    return timeArrayForTo
}

const StepFormThree = ({ vacancy_AllList, workingmode_AllList, urgencytype_AllLists, handleChangeSelect, handleChangeText, submitData, formValues, errStatus, formErr,
    job_location_AllList, specificationOptionList, handleDescriptionContent, handleChangeOnSpecification, showContractType, contracttype_AllList,
    showExpectedHour, description, id, reload,
    uploadedFiles, deleteFile, fileErr, onFileChange, uploadInputRef, removeAttachFile, fileDownload, removeFileStatus,
    handleChangeOnQualification, qualification_AllList, totalExperienceTo, relevanExperienceTo
}) => {

    const submitFormData = (e) => {
        submitData(e, null)
    }

    return <>
        {(reload === true || reload === 'submit') ? <LoaderForm /> :
            <div>
                <form onSubmit={(e) => submitFormData(e, null)}>
                    <h4>Step 3 JOB DETAILS </h4>
                    <br />
                    <div className="add-new-user-form">
                        <div className="formInput">
                            <InputFields
                                label={'Position/Role Name'}
                                name="role"
                                defaultValue={formValues.role}
                                type="text"
                                placeholder="Enter Position/Role Name"
                                onChange={handleChangeText}
                                errStatus={errStatus}
                                formValues={formValues}
                                formErr={formErr.roleErr}
                            />
                        </div>

                        <div className="formInput">
                            <InputFields
                                label={'Number Of Vacancies'}
                                name="number_of_vacancies"
                                defaultValue={formValues.number_of_vacancies}
                                type="number"
                                placeholder="Number of vacancies"
                                onChange={handleChangeText}
                                errStatus={errStatus}
                                formValues={formValues}
                                formErr={formErr.numOfVacancyErr}
                            />
                        </div>

                        <div className="formInput">
                            <label > Type Of Vacancy </label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                divstyle={{ flexBasis: '100%' }}
                                placeholder={'Select Type of vacancy'}
                                onChange={handleChangeSelect}
                                defaultValue={formValues.type_of_vacancy}
                                isSearchable={true}
                                name={'type_of_vacancy'}
                                options={vacancy_AllList && vacancy_AllList.data && vacancy_AllList.data.rows.map((option) => {
                                    return {
                                        label: option.vacancy_type,
                                        value: option.id,
                                        name: 'type_of_vacancy',
                                        key_name: option.vacancy_type_key
                                    }
                                })}
                            />
                            {errStatus === true && formValues && formValues.type_of_vacancy === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.vacancyTypeErr}</p>}
                        </div>

                        {
                            showContractType === true ?
                                <div className="formInput">
                                    <InputSelectField
                                        label={"Contract Type"}
                                        name={"contract_type"}
                                        placeholder={"Contract Type"}
                                        onChange={handleChangeSelect}
                                        defaultValue={formValues.contract_type}
                                        errStatus={errStatus}
                                        formErr={formErr.contractTypeErr}
                                        optionList={contracttype_AllList && contracttype_AllList.data && contracttype_AllList.data.rows.map((option) => {
                                            return {
                                                label: option.name,
                                                value: option.id,
                                                name: 'contract_type',
                                            }
                                        })}
                                        optionLevel={['label']}
                                        optionValue={'value'}
                                    />
                                </div> : ""
                        }

                        {
                            showExpectedHour === true ?
                                <>
                                    <div className="formInput">
                                        <label>Hours per day</label>
                                        <select
                                            name={"hours_per_day"}
                                            onChange={handleChangeText}
                                            value={formValues.hours_per_day}
                                        >
                                            {etaMonthArr.map((e, i) => {
                                                return (<option value={e} key={i}>{e}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className="formInput">
                                        <label>Number of months</label>
                                        <input
                                            name="expected_duration_in_months"
                                            min={0}
                                            type="number"
                                            onChange={handleChangeText}
                                            placeholder="Number of months"
                                            defaultValue={formValues.expected_duration_in_months}
                                        />
                                    </div>
                                </>

                                : ""
                        }


                        <div className="formInput">
                            <InputSelectField
                                label={"Working Mode"}
                                name={"working_mode"}
                                placeholder={"Working Mode"}
                                onChange={handleChangeSelect}
                                defaultValue={formValues.working_mode}
                                errStatus={errStatus}
                                formErr={formErr.workingModeErr}
                                optionList={workingmode_AllList && workingmode_AllList.data && workingmode_AllList.data.rows.map((option) => {
                                    return {
                                        label: option.working_mode,
                                        value: option.id,
                                        name: 'working_mode'
                                    }
                                })}
                                optionLevel={['label']}
                                optionValue={'value'}
                            />
                        </div>

                        <div className="formInput">
                            <InputSelectField
                                label={"Job Location"}
                                name={"job_location"}
                                placeholder={"Job Location"}
                                onChange={handleChangeSelect}
                                defaultValue={formValues.job_location}
                                errStatus={errStatus}
                                formErr={formErr.jobLocationErr}
                                optionList={job_location_AllList && job_location_AllList.data && job_location_AllList.data.rows.map((option) => {
                                    return {
                                        label: option.job_location,
                                        value: option.id,
                                        name: 'job_location'
                                    }
                                })
                                }
                                optionLevel={['label']}
                                optionValue={'value'}
                            />
                        </div>

                        {/* <div className="formInput">
                            <InputSelectField
                                label={"Urgency"}
                                name={"urgency"}
                                placeholder={"Urgency"}
                                onChange={handleChangeSelect}
                                defaultValue={formValues.urgency}
                                errStatus={errStatus}
                                formErr={formErr.urgencyErr}
                                optionList={urgencytype_AllLists && urgencytype_AllLists.map((option) => {
                                    return {
                                        label: option.urgency_type,
                                        value: option.id,
                                        name: 'urgency'
                                    }
                                })
                                }
                                optionLevel={['label']}
                                optionValue={'value'}
                            />
                        </div>
                        {
                            showTextBox === true &&
                            <div className="formInput">
                                <label>Other(Optional)</label>
                                <textarea
                                    defaultValue={formValues.other}
                                    name={"urgency_other"}
                                    onChange={handleChangeText}
                                    rows={1}
                                    placeholder={"Enter Other Urgency Option"}></textarea>

                            </div>
                        } */}

                        {/* <div className="formInput" style={{ marginBottom: "5px" }}>
                            <InputFields
                                label={'Job Responsibilities'}
                                name="job_responsibilities"
                                defaultValue={formValues.job_responsibilities}
                                type="text"
                                placeholder="Job Responsibilities"
                                onChange={handleChangeText}
                                errStatus={errStatus}
                                formValues={formValues.job_responsibilities}
                                formErr={formErr.jobResErr}
                            />
                        </div> */}

                        {/* add more specifications section start */}
                        <div className="formInput">
                            <label> Add More Specifications </label>
                            <CreatableSelect
                                isMulti
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Select Specifications"
                                defaultValue={formValues.add_more_specifications}
                                isSearchable={true}
                                name={"add_more_specifications"}
                                options={
                                    specificationOptionList && specificationOptionList.map((option) => {
                                        return {
                                            label: option.label,
                                            value: option.value,
                                            name: "add_more_specifications"
                                        }
                                    })
                                }
                                onChange={handleChangeOnSpecification}
                            />
                            {errStatus === true && formValues && formValues.add_more_specifications === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.moreSpecErr}</p>}
                        </div>

                        {/* education qualification section start */}
                        <div className="formInput">
                            <label> Education Qualification </label>
                            <CreatableSelect
                                isMulti
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Select Education Qualification"
                                defaultValue={formValues.education_qualification}
                                isSearchable={true}
                                name={"education_qualification"}
                                options={
                                    qualification_AllList && qualification_AllList.data && qualification_AllList.data.rows.map((option) => {
                                        return {
                                            label: option.education_qualification_name,
                                            value: option.id,
                                            name: "education_qualification"
                                        }
                                    })
                                }
                                onChange={handleChangeOnQualification}
                            />
                            {errStatus === true && formValues && formValues.education_qualification === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.qualicationErr}</p>}
                        </div>
                        <div className="formInput"></div>
                    </div>

                    {/* Total Years of Experience section start */}
                    <div className="add-new-user-form"  >
                        <div className="formInput">
                            <label> Total Years of Experience From</label>
                            <select
                                name={"total_experience_from"}
                                onChange={handleChangeText}
                                value={formValues.total_experience_from}
                            >
                                {experienceYearArr.map((e, i) => {
                                    return (<option value={e} key={i}>{e}</option>)
                                })}
                            </select>
                        </div>

                        <div className="formInput">
                            <label> Total Years of Experience To</label>
                            <select
                                name={"total_experience_to"}
                                onChange={handleChangeText}
                                value={formValues.total_experience_to}
                            >
                                {totalExperienceTo.map((e, i) => {
                                    return (<option value={e} key={i}>{e}</option>)
                                })}
                            </select>
                        </div>
                        <div className="formInput"></div>
                    </div>
                    {/* Total Years of Experience section end */}

                    {/* Total Years of Relevant Experience section start */}
                    <div className="add-new-user-form"  >
                        <div className="formInput">
                            <label>Total Years of Relevant Experience From</label>
                            <select
                                name={"relevant_experience_expected_from"}
                                onChange={handleChangeText}
                                value={formValues.relevant_experience_expected_from}
                            >
                                {experienceYearArr.map((e, i) => {
                                    return (<option value={e} key={i}>{e}</option>)
                                })}
                            </select>
                        </div>

                        <div className="formInput">
                            <label>Total Years of Relevant Experience To</label>
                            <select
                                name={"relevant_experience_expected_to"}
                                onChange={handleChangeText}
                                value={formValues.relevant_experience_expected_to}
                            >
                                {relevanExperienceTo.map((e, i) => {
                                    return (<option value={e} key={i}>{e}</option>)
                                })}
                            </select>
                        </div>
                        <div className="formInput"></div>
                    </div>
                    {/* Total Years of Relevant Experience section end */}

                    {/* <div className="formInput">
                            <label>Expected ETA In Months</label>
                            <select
                                name={"expected_ETA_in_months"}
                                onChange={handleChangeText}
                                value={formValues.expected_ETA_in_months}
                            >
                                {etaMonthArr.map((e, i) => {
                                    return (<option value={e} key={i}>{e}</option>)
                                })}
                            </select>
                        </div> */}

                    {/* <div className="formInput">
                            <label>Expected ETA In Days</label>
                            <select
                                name={"expected_ETA_in_days"}
                                onChange={handleChangeText}
                                value={formValues.expected_ETA_in_days}
                            >
                                {etaDayArr.map((e, i) => {
                                    return (<option value={e} key={i}>{e}</option>)
                                })}
                            </select>
                        </div> */}

                    <div className="formInput"></div>

                    {/* Expected ETA section satrt */}
                    <div className="add-new-user-form"  >
                        <div className="formInput" style={{ flexBasis: "100%" }}>
                            <label>Expected ETA</label>
                        </div>
                        <div className="formInput" style={{ flexBasis: "15%" }}>
                            <select
                                name={"expected_ETA"}
                                onChange={handleChangeText}
                                value={formValues.expected_ETA}
                            >
                                {etaMonthArr.map((e, i) => {
                                    return (<option value={e} key={i}>{e}</option>)
                                })}
                            </select>
                        </div>
                        <div className="formInput" style={{ flexBasis: "15%" }}>
                            <select
                                name={"urgency_unit"}
                                onChange={handleChangeText}
                                value={formValues.urgency_unit}
                            >
                                {urgencytype_AllLists && urgencytype_AllLists.map((e, i) => {
                                    return (<option value={e?.id} key={i}>{e?.urgency_type}</option>)
                                })}
                            </select>
                        </div>
                    {/* expected ETA section end */}


                    {/*job description and responsibility section start */}
                    <div className="formInput" style={{ flexBasis: "66%" }}></div>
                    <InputEditorField
                        label={"Job Description and Responsibility"}
                        defaultValue={description}
                        name="job_description"
                        onChange={(evt) => handleDescriptionContent(evt, "job_description")}
                        placeholder="Job Description"
                        errStatus={errStatus}
                        formErr={formErr.jobDesErr}
                    />

                    </div>
                    {/* <button className="btn btn-submit">Save as Draft</button> */}
                </form>

                {/* attachment section start */}

                {/* edit */}
                {id > 0 &&
                    <div className="add-new-user-form" style={{ marginBottom: '10px' }}>
                        {formValues && formValues.attachment && formValues.attachment.length > 0 ? formValues.attachment.map((item, i) => {
                            return <>
                                <div className="uploaded-files-list" style={{ flexBasis: "40%" }}>
                                    {removeFileStatus === 'remove' ? <SmallLoader />
                                        : <div key={i}>
                                            <ul>
                                                <li>
                                                    <span>{item && item.substring(0, 35) + "..."}</span>
                                                    <Tooltip title="Delete">
                                                        <button onClick={() => removeAttachFile(item)}>
                                                            <Close />
                                                        </button>
                                                    </Tooltip>

                                                    <Tooltip title="Download">
                                                        <button onClick={() => fileDownload(item)}>
                                                            <Download style={{ backgroundColor: "gray" }} />
                                                        </button>
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                                <div className="uploaded-files-list" style={{ flexBasis: "40%" }}></div>
                            </>

                        })
                            :
                            <p>No Attachment</p>
                        }
                    </div>
                }

                {/* add */}
                <>
                    <div className='add-new-user-form' style={{ display: "flex" }}>
                        <div className="uploaded-files-list" style={{ flexBasis: "40%" }}>
                            {uploadedFiles.length > 0 && uploadedFiles.map((file, i) => {
                                return <div key={i}>
                                    <ul>
                                        <li>
                                            <span>{file && file.name && file.name.substring(0, 35) + "..."}</span>
                                            <Tooltip title="Delete">
                                                <button onClick={() => deleteFile(i)}>
                                                    <Close />
                                                </button>
                                            </Tooltip>
                                        </li>
                                    </ul>
                                </div>
                            })
                            }
                        </div>
                        <div className="uploaded-files-list" style={{ flexBasis: "40%" }}>
                        </div>
                    </div>


                    <div className='add-new-user-form' style={{ display: "contents" }}>
                        <div className="file-attchment-icon-btn">
                            <input
                                id='fileUpload'
                                ref={uploadInputRef}
                                type='file'
                                multiple
                                accept='application/pdf'
                                style={{ display: "none" }}
                                onChange={onFileChange}
                                name="file"
                            />

                            <Tooltip title="Attach File">
                                <span>
                                    <AttachFile
                                        className={`attach_con`}
                                        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                        {errStatus === true && fileErr && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}
                    </div>
                </>

            </div>
        }
    </>
}
export default memo(StepFormThree)