import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

export const postClientCreate = createAsyncThunk("client/create", async (data) => {
    const res = await DataService.create_client(data);
    return res.data;
});

export const getClientList = createAsyncThunk("client/list", async (filter) => {
    const res = await DataService.get_client_list(filter);
    return res.data;
});
export const getClientById = createAsyncThunk("client/Id", async (data) => {
    const res = await DataService.get_client_by_id(data);
    return res.data;
});

export const updateClientById = createAsyncThunk("client-update/Id", async (data) => {
    const res = await DataService.update_client_by_id(data);
    return res.data;
});

export const deleteClientById = createAsyncThunk("client-delete/Id", async (data) => {
    const res = await DataService.archive_client_by_id(data);
    return res.data;
});

export const getResourceRoleWise = createAsyncThunk("rolewise-resource/list", async (filter) => {
    const res = await DataService.resources_dropDown(filter);
    return res.data;
});

export const getSourceTypeList = createAsyncThunk("master-sorece-type/list", async (filter) => {
    const res = await DataService.get_master_source_type_list(JSON.stringify(filter));
    return res.data;
});



const clientSlice = createSlice({
    name: "clientSlice",
    initialState: {
        clientCreate: [],
        clientList: [],
        clientUpdate: [],
        clientArchive: [],
        clientById: [],
        status: STATUSES.IDLE,
        error: '',
        resourceMarketingList: [],
        masterSourceTypeList: []
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //create client
            .addCase(postClientCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postClientCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_clientCreate`;
                state.clientCreate = action.payload;
            })
            .addCase(postClientCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //update client
            .addCase(updateClientById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateClientById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_clientUpdate`;
                state.clientUpdate = action.payload;
            })
            .addCase(updateClientById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get by id client
            .addCase(getClientById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getClientById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_clientById`;
                state.clientById = action.payload;
            })
            .addCase(getClientById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //delete client
            .addCase(deleteClientById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(deleteClientById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_clientArchive`;
                state.clientArchive = action.payload;
            })
            .addCase(deleteClientById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get  client list
            .addCase(getClientList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getClientList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_clientList`;
                state.clientList = action.payload;
            })
            .addCase(getClientList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get  resource list
            .addCase(getResourceRoleWise.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getResourceRoleWise.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resourceMarketingList`;
                let result = action.payload;
                let array = []
                if (result.status === 200) {
                    // eslint-disable-next-line
                    result.data && result.data.rows && result.data.rows.map(item => {
                        if (item.tms_role && (item.tms_role.role_key === 'marketing' || item.tms_role__role_key === 'marketing')) {
                            array.push(item)
                        }
                    })
                }
                state.resourceMarketingList = array;
            })
            .addCase(getResourceRoleWise.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get  master source list
            .addCase(getSourceTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSourceTypeList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_masterSourceTypeList`;
                let result = action.payload;
                let array = []
                if (result.status === 200) {
                    result.data && result.data.rows && result.data.rows.map(item => {
                        return array.push(item)
                    })
                }
                state.masterSourceTypeList = array;
            })
            .addCase(getSourceTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


    },
});

export default clientSlice.reducer;