import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./hiring.scss";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { hiringRequestEdit } from "../../redux/features/masterSlice";
import { toast, ToastContainer } from "react-toastify";
import parse from 'html-react-parser';

const ViewHiringRequest = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { view_data, status, error } = useSelector(state => state.master)

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
        }
        // eslint-disable-next-line
    }, [error])

    useEffect(() => {
        if (id) {
            dispatch(hiringRequestEdit(id))
        }
        // eslint-disable-next-line
    }, [dispatch])
    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                <ToastContainer />
                <div className="top-headings">
                    <h3>View Hiring Request </h3>
                    <div>
                        <Tooltip title="Back">
                            <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
                        </Tooltip>
                        <RefreshButton api={hiringRequestEdit} filter={id} style={{ marginTop: 5 }} />
                    </div>
                </div>
                {(status === STATUSES.LOADING) && <LoaderForm />}

                <div className="view-details-row">
                    <div className="detailItem">
                        <span className="itemKey">Role :</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.role ? view_data.data.role : "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Job Responsibilities :</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.job_responsibilities
                            ? view_data.data.job_responsibilities
                            : "N/A"}</span>
                    </div>


                    <div className="detailItem">
                        <span className="itemKey"> Number Of Vacancies:</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.number_of_vacancies ? view_data.data.number_of_vacancies : "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Type Of Vacancy :</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.type_of_vacancy && view_data.data.type_of_vacancy.vacancy_type ? view_data.data.type_of_vacancy.vacancy_type : "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Working Mode :</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.working_mode && view_data.data.working_mode.working_mode ? view_data.data.working_mode.working_mode : "N/A"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Urgency</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.urgency && view_data.data.urgency.urgency_type ? view_data.data.urgency.urgency_type : "0"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Expected ETA In Months :</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.expected_ETA_in_months ? view_data.data.expected_ETA_in_months : "0"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Expected ETA In Years :</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.expected_ETA_in_years ? view_data.data.expected_ETA_in_years : "0"}</span>
                    </div>

                    <div className="detailItem">
                        <span className="itemKey">Relevant Experience In Months :</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.relevant_experience_expected_in_months ? view_data.data.relevant_experience_expected_in_months : "0"}</span>
                    </div>



                    <div className="detailItem">
                        <span className="itemKey">Relevant Experience In Years  :</span>
                        <span className="itemKey2">{view_data && view_data.data && view_data.data.relevant_experience_expected_in_years ? view_data.data.relevant_experience_expected_in_years : "N/A"}</span>
                    </div>

                    {
                        view_data && view_data.data &&
                        <div className="detailItem" style={{ float: "left" }}>
                            <span className="itemKey" style={{ float: "left" }}>Specifications :</span>
                            <span className="itemKey2">
                                {view_data.data && view_data.data.add_more_specifications_data && view_data.data.add_more_specifications_data.length > 0 ? view_data.data.add_more_specifications_data.map((e, index) => `${index ? ', ' : ''}${(e.specification_name).trim()}`) : "N/A"}
                            </span>
                        </div>
                    }

                    {
                        view_data && view_data.data &&
                        <div className="detailItem" style={{ float: "left" }}>
                            <span className="itemKey" style={{ float: "left" }}>Preferred Location :</span>
                            <span className="itemKey2">
                                {view_data.data && view_data.data.preferred_location_data && view_data.data.preferred_location_data.length > 0 ? view_data.data.preferred_location_data.map((e, index) => `${index ? ', ' : ''}${(e.city_name).trim()}`) : "N/A"}
                            </span>
                        </div>
                    }

                    {
                        view_data && view_data.data &&
                        <div className="detailItem" style={{ float: "left" }}>
                            <span className="itemKey" style={{ float: "left" }}>Technology :</span>
                            <span className="itemKey2">
                                {view_data.data && view_data.data.technology && view_data.data.technology.length > 0 ? view_data.data.technology.map((e, index) => `${index ? ', ' : ''}${(e.skill_name).trim()}`) : "N/A"}
                            </span>
                        </div>
                    }

                    {
                        view_data && view_data.data &&
                        <div className="detailItem" style={{ float: "left" }}>
                            <span className="itemKey" style={{ float: "left" }}>Required Skill Set :</span>
                            <span className="itemKey2">
                                {view_data.data && view_data.data.required_skill_set_data && view_data.data.required_skill_set_data.length > 0 ? view_data.data.required_skill_set_data.map((e, index) => `${index ? ', ' : ''}${(e.skill_name).trim()}`) : "N/A"}
                            </span>
                        </div>
                    }

                    {/* <div className={"detailItem"}>
                        <span className="itemKey" style={{ float: 'left' }}>Job Description :</span>
                        <span className="itemKey2 item-value" style={{ float: 'left' }}>
                            <span className={view_data && view_data.data && parse(view_data.data.job_description).length > 70 ? "para-text" : "text"}>
                                {view_data && view_data.data && parse(view_data.data.job_description)}</span>
                        </span>
                    </div> */}


                </div>

            </div>
        </div >
    )

}

export default ViewHiringRequest