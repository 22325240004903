import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./attendacecalender.scss"
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { teamAttandanceListByManager, updateAttandanceRegulization } from "../../redux/features/calendarSlice";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from "@mui/icons-material";
import { IconButton, TablePagination } from '@mui/material';
import DatePicker from 'react-datepicker';
import moment from "moment";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { allResourcesByManager } from "../../redux/features/resourceSlice";
import { toast, ToastContainer } from 'react-toastify';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import Filter from "../../components/Filter";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
const user = LocalStorageDataService.userDetail();

const TeamRegularization = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"resource_manager_id":{"value":${user.id},"operation": "eq"}}, "skip":0, "limit":25, "columns":[${DefaultFilterColumns.REGULARIZATION_LIST_COLUMNS}]}`)
    const [managerRemark, setManagerRemark] = useState("")
    const [punchInTime, setPunchInTime] = useState('');
    const [punchOutTime, setPunchOutTime] = useState('');
    const [regulizeData, setRegulizeData] = useState("")
    const [remark, setRemark] = useState('');
    const [regularStatus, setRegularStatus] = useState("")
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [regulizModalView, setRegulizModalView] = useState(false);
    const [teamList, setTeamList] = useState([])
    const [filterActiveResourcesQuery, setFilterActiveResourcesQuery] = useState([])
    const [filterActiveEmailResourcesQuery, setFilterActiveEmailResourcesQuery] = useState([])
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [corrected_punch_in_datetime, setcorrected_punch_in_datetime] = useState('')
    const [corrected_punch_out_datetime, setcorrected_punch_out_datetime] = useState('')
    const [actual_punch_in_datetime, setactual_punch_in_datetime] = useState('')
    const [actual_punch_out_datetime, setactual_punch_out_datetime] = useState('')
    const { status, teamRegularList, updateRegulization } = useSelector(state => state.calender)
    const { allResourcesByManagerList } = useSelector(state => state.resource)
    const [count, setCount] = useState(0)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    //columns visibility
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    // custom filter
    const [fieldName, setFieldName] = useState("")
    const [options, setOptions] = useState({})
    const [defaultValue, setDefaultValue] = useState({})

    useEffect(() => {
        dispatch(allResourcesByManager())
        dispatch(teamAttandanceListByManager(`{"filter":{"resource_manager_id":{"value":${user.id},"operation": "eq"}}, "skip":0, "limit":${pageSize}, "columns":[${DefaultFilterColumns.REGULARIZATION_LIST_COLUMNS}]}`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadData = () => {
        let list = teamRegularList.data.rows;
        let counts = teamRegularList.data && teamRegularList.data.count ? teamRegularList.data.count : 0
        let newArrey = []
        if (list && list.length > 0) {
            list.map(e => {
                return newArrey.push({
                    "id": e.id,
                    "resource": e.resource ? e.resource.id : e.resource_id,
                    "resource_name": e.resource ? e.resource.display_name : e.resource__display_name,
                    "resource_attendance": e.resource_attendance ? e.resource_attendance.id : e.resource_attendance__id,
                    "punchin_date": e.resource_attendance ? e.resource_attendance.punchin_date : e.resource_attendance__punchin_date,
                    // "department_id": e.resource.department_id,
                    // "department_name": e.resource.department.name,
                    "email": e.resource ? e.resource.email : e.resource__email,
                    "resource_id": e.resource ? e.resource_id : e.resource_id,
                    "status": e.status,
                    "actual_punch_in_datetime": e.actual_punch_in_datetime,
                    "actual_punch_out_datetime": e.actual_punch_out_datetime,
                    "reporting_head_remark": e.reporting_head_remark,
                    "remark": e.remark,
                    "request_type": e.request_type,
                    "resource_manager_id": e.resource_manager_id,
                    "approved_rejected_by": e.approved_rejected_by,
                    "approved_rejected_by_id": e.approved_rejected_by_id,
                    "corrected_punch_in_datetime": e.corrected_punch_in_datetime,
                    "corrected_punch_out_datetime": e.corrected_punch_out_datetime,
                    "approved_rejected_on": e.approved_rejected_on,

                })
            })
            setTeamList(newArrey)
        } else {
            setTeamList([])
        }
        setCount(counts)
        setLoading(false)
    }

    if (loading === true && status === `${STATUSES.SUCCESS}_teamRegularList` && teamRegularList && teamRegularList.data && teamRegularList.data.rows) {
        loadData()
    }

    if (filterActiveResourcesQuery && filterActiveResourcesQuery.length === 0 && allResourcesByManagerList && allResourcesByManagerList.data && allResourcesByManagerList.data.rows) {
        let array = []
        let arrayEmail = []
        allResourcesByManagerList.data && allResourcesByManagerList.data.rows.map(e => { return array.push({ value: e.id, label: e.display_name }) })
        array.length > 0 && setFilterActiveResourcesQuery(array)
        allResourcesByManagerList.data && allResourcesByManagerList.data.rows.map(e => { return arrayEmail.push({ value: e.id, label: e.email }) })
        arrayEmail.length > 0 && setFilterActiveEmailResourcesQuery(arrayEmail)
        array.length > 0 && setOptions({ ...options, resource_id: arrayEmail, resource: array })
    }
    if (status === `${STATUSES.SUCCESS}_updateRegulization` && loading === `${true}_updateRegulization` && updateRegulization) {
        toast.success(updateRegulization.message)
        setRegulizModalView(false)
        setPunchInTime('')
        setPunchOutTime('')
        setRegulizeData("")
        setRemark('')
        setManagerRemark("")
        setRegularStatus("")
        setOpenCancelModal(false)
        //setTeamList([])
        dispatch(teamAttandanceListByManager(`{"filter":{"resource_manager_id":{"value":${user.id},"operation": "eq"}}, "skip":0, "limit":${pageSize}, "columns":[${DefaultFilterColumns.REGULARIZATION_LIST_COLUMNS}]}`))
        setLoading(true)

    }
    
    const actionColumns = [
        {
            field: 'id', headerName: 'ID', width: 90,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <p>{params.row.id}</p>
                    </div>
                )
            }
        },
        {
            field: 'resource',
            headerName: 'Display Name',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: filterActiveResourcesQuery,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <p>{params.row.resource_name}</p>
                    </div>
                )
            }
        },
        {
            field: 'resource_id',
            headerName: 'Email',
            width: 200,
            editable: true,
            type: 'singleSelect',
            valueOptions: filterActiveEmailResourcesQuery,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <p>{params.row.email}</p>
                    </div>
                )
            }
        },
        {
            field: 'actual_punch_in_datetime',
            headerName: 'Punch In Date and Time',
            width: 300,
            type: 'date',
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <p>{(params.row.actual_punch_in_datetime).replace(/[TZ]/g, ' ')}</p>
                    </div>
                )
            }
        },
        {
            field: 'actual_punch_out_datetime',
            headerName: 'Punch Out Date and Time',
            width: 300,
            type: 'date',
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <p>{params.row.actual_punch_out_datetime ? (params.row.actual_punch_out_datetime).replace(/[TZ]/g, ' ') : " - "}</p>
                    </div>
                )
            }
        },



        {
            field: 'corrected_punch_in_datetime',
            headerName: 'Corrected Punch In Date and Time',
            width: 300,
            type: 'date',
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <p>{(params.row.corrected_punch_in_datetime).replace(/[TZ]/g, ' ')}</p>
                    </div>
                )
            }
        },
        {
            field: 'corrected_punch_out_datetime',
            headerName: 'Corrected Punch Out Date and Time',
            width: 300,
            type: 'date',
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <p>{params.row.corrected_punch_out_datetime ? (params.row.corrected_punch_out_datetime).replace(/[TZ]/g, ' ') : " - "}</p>
                    </div>
                )
            }
        },








        {
            field: 'status',
            headerName: 'Regularization Status',
            width: 250,
            type: 'singleSelect',
            valueOptions: LocalStorageDataService.regularize_status_type(),
            renderCell: (params) => {
                return (
                    <div className="cellAction" style={params.row.status === 4 ? { color: '#cc9600' } :
                        params.row.status === 5 ? { color: 'green' } :
                            params.row.status === 6 ? { color: '#808080' } :
                                params.row.status === 7 ? { color: 'rgb(151, 15, 15)' } :
                                    { color: '#000' }
                    }>
                        <p>{params.row.status === 4 ? "PENDING" :
                            params.row.status === 5 ? "APPROVED" :
                                params.row.status === 6 ? "CANCELLED" :
                                    params.row.status === 7 ? "REJECTED" : "No Request"}</p>
                    </div>
                )
            }
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            moduleName="team-regular"
                            rowid={params.row.id}
                            addMoreData={params.row}
                            editLinkUrl={false}
                            viewLinkUrl={false}
                            isDelete={false}
                            editButton={params.row.status === 4 ? regulizationModal : false}
                            viewButton={params.row.status === 4 ? false : regulizationModal}
                            deleteButton={false} />
                    </div>
                );
            },
        },
    ];

    const submitDataByManager = (e) => {
        e.preventDefault()
        if (managerRemark) {
            setLoading(`${true}_updateRegulization`)
            let in_date = moment(punchInTime).format('YYYY-MM-DD')
            let in_time = moment(punchInTime).format('HH:mm:SS')
            let out_date = punchOutTime && moment(punchOutTime).format('YYYY-MM-DD')
            let out_time = punchOutTime && moment(punchOutTime).format('HH:mm:SS')
            let isdate = moment().format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:SS') + 'Z';
            let prepairData = {
                "id": regulizeData.id,
                "resource_attendance": regulizeData.resource_attendance,
                "resource": regulizeData.resource,
                "corrected_punch_in_datetime": in_date + 'T' + in_time + 'Z',
                "corrected_punch_out_datetime": punchOutTime && out_date + "T" + out_time + 'Z',
                "remark": remark,
                "status": 5,
                // "created_by" : 6,
                "approved_rejected_by": user.id,
                "approved_rejected_on": isdate,
                "reporting_head_remark": managerRemark

            }
            dispatch(updateAttandanceRegulization(prepairData))
        } else {
            toast.error("Remark is Required!")
        }

    }

    const handleChange = (evt) => {
        const { name, value } = evt.target
        if (name === 'remark') {
            setManagerRemark(value)
        } else {
            setRemark(value);
        }

    }
    const cancelModale = (e) => {
        e.preventDefault()
        if (managerRemark) {
            setOpenCancelModal(!openCancelModal)
        }
        else {
            toast.error("Remark is Required!")
        }

    }

    const prepairModalData = (data) => {
        setRegulizeData(data)
        let in_date = moment(data.corrected_punch_in_datetime).format('YYYY-MM-DD')
        let in_time = moment(data.corrected_punch_in_datetime).format('HH:mm:ss')
        let out_date = data.corrected_punch_out_datetime && moment(data.corrected_punch_out_datetime).format('YYYY-MM-DD')
        let out_time_is = data.corrected_punch_out_datetime && moment(data.corrected_punch_out_datetime).format('HH:mm:ss')
        const [year, month, day] = in_date.split('-');
        const [hours, minutes, seconds] = in_time.split(':');
        if (out_date) {
            const [year1, month1, day1] = out_date.split('-');
            const [hours1, minutes1, seconds1] = out_time_is.split(':');
            let new_hour1 = hours1 - 5
            let new_minute1 = minutes1 - 30;
            const actul_date_out = new Date(+year1, month1 - 1, +day1, +new_hour1, +new_minute1, +seconds1);
            setPunchOutTime(actul_date_out)

        } else {
            setPunchOutTime('')
        }

        let new_hour = hours - 5
        let new_minute = minutes - 30

        let corrected_punch_in_datetime = data.corrected_punch_in_datetime.replace('T', ' ')
        setcorrected_punch_in_datetime(corrected_punch_in_datetime.replace('Z', ''))
        let corrected_punch_out_datetime = data.corrected_punch_out_datetime && data.corrected_punch_out_datetime.replace('T', ' ')
        data.corrected_punch_out_datetime && setcorrected_punch_out_datetime(corrected_punch_out_datetime.replace('Z', ''))
        let actual_punch_in_datetime = data.actual_punch_in_datetime.replace('T', ' ')
        setactual_punch_in_datetime(actual_punch_in_datetime.replace('Z', ''))
        let actual_punch_out_date_time = data.actual_punch_out_date_time && data.actual_punch_out_date_time.replace('T', ' ')
        data.actual_punch_out_date_time && setactual_punch_out_datetime(actual_punch_out_date_time.replace('Z', ''))
        const actul_date_in = new Date(+year, month - 1, +day, +new_hour, +new_minute, +seconds);

        let time_in = new Date(data.corrected_punch_in_datetime)
        // let out_time = new Date(data.corrected_punch_out_datetime)
        let statusIs = data.status === 4 ? "PENDING" :
            data.status === 5 ? "APPROVED" :
                data.status === 6 ? "CANCELLED" :
                    data.status === 7 && "REJECTED";
        setPunchInTime(actul_date_in)
        setMinDate(time_in)
        setMaxDate(time_in)

        setRegularStatus(statusIs)
        setRemark(data.remark)
        setManagerRemark(data.reporting_head_remark)
        setRegulizModalView(!regulizModalView)
    }

    const cancelReq = (e) => {
        //e.preventDefault()

        setLoading(`${true}_updateRegulization`)
        let in_date = moment(punchInTime).format('YYYY-MM-DD')
        let in_time = moment(punchInTime).format('HH:mm:SS')
        let out_date = punchOutTime && moment(punchOutTime).format('YYYY-MM-DD')
        let out_time = punchOutTime && moment(punchOutTime).format('HH:mm:SS')
        let isdate = moment().format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:SS') + 'Z';
        const prepairData = {
            "id": regulizeData.id,
            "resource_attendance": regulizeData.resource_attendance,
            "resource": regulizeData.resource,
            "corrected_punch_in_datetime": in_date + 'T' + in_time + 'Z',
            "corrected_punch_out_datetime": punchOutTime && out_date + "T" + out_time + 'Z',
            "remark": remark,
            "status": 7,
            // "created_by" : 6,
            "approved_rejected_by": user.id,
            "approved_rejected_on": isdate,
            "reporting_head_remark": managerRemark

        }
        dispatch(updateAttandanceRegulization(prepairData))


    }
    const regulizationModal = (data) => {
        if (regulizModalView === false) {
            prepairModalData(data)
        } else {
            setRegulizModalView(!regulizModalView)
        }

    }

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"resource_manager_id":{"value":${user.id},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit}, "columns":[${DefaultFilterColumns.REGULARIZATION_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(teamAttandanceListByManager(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setDefaultValue("")
            setFilterQuery(`{"filter":{"resource_manager_id":{"value":${user.id},"operation": "eq"}}, "skip":0, "limit":${limit}, "columns":[${DefaultFilterColumns.REGULARIZATION_LIST_COLUMNS}]}`)
            dispatch(teamAttandanceListByManager(`{"filter":{"resource_manager_id":{"value":${user.id},"operation": "eq"}}, "skip":0, "limit":${limit}, "columns":[${DefaultFilterColumns.REGULARIZATION_LIST_COLUMNS}]}`))
        }
        setLoading(true)
    }

    const addExtraDate = (selectedDate) => {
        var actualDate = new Date(selectedDate);
        return new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate() + 1);
    }


    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }

    const columns = useMemo(() =>
        actionColumns.map((col) => {
            if (col.field === "resource" || col.field === "resource_id") {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                && CustomFilterInputValue

                        }))
                };
            }
            return col;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }), [actionColumns]
    );

    //custom pagination
    const handlePagination = (data) => {
        setLoading(true)
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit;
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(teamAttandanceListByManager(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setLoading(true)
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(teamAttandanceListByManager(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={count || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    return (
        <div className="listing">
            <Sidebar />
            <div className="listingContainer">
                <Navbar />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>Team Regularization</h3>
                        <div>
                            <RefreshButton api={teamAttandanceListByManager} filter={filterQuery} style={{ marginRight: 5 }} />
                            <Tooltip title="Back">
                                <button onClick={() => navigate(-1)} className="btn-add-new "><ArrowBackIcon /></button>
                            </Tooltip>
                        </div>

                    </div>
                    {(loading === `${true}_updateRegulization` || loading === true) && <LoaderForm />}
                    <ToastContainer />
                    <DataGrid
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        className="datagrid"
                        rows={(teamList && teamList.length > 0 && teamList) || []}
                        columns={columns}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                    />
                </div>
                <Dialog
                    //hideBackdrop
                    fullWidth={true}
                    maxWidth={"md"}

                    open={regulizModalView}
                    onClose={regulizationModal}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}> Regularization Request-{regulizeData && moment(regulizeData.punchin_date).format('ll')}{
                        regularStatus && ` - (${regularStatus})`}
                        <IconButton
                            edge="start"
                            //color="inherit"
                            onClick={regulizationModal}
                            aria-label="close"
                            style={{ position: "absolute", top: "10px", right: "10px" }}
                        ><Close /></IconButton>
                    </DialogTitle>
                    <DialogContent style={{ height: "500px" }}>
                        {loading === `${true}_updateRegulization` && <LoaderForm />}
                        <div>
                            <form className="regulazation-form">
                                {regulizeData && (regulizeData.status === 5 || regulizeData.status === 7 || regulizeData.status === 6) ?
                                    <div>
                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Request By</span></p></div>
                                            <div className="table-row-cell-regular"><p style={{ textTransform: 'capitalize' }}>{regulizeData.resource_name}</p></div>
                                        </div>
                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Actual Punch In Date and Time</span></p></div>
                                            <div className="table-row-cell-regular"><p>{actual_punch_in_datetime}</p></div>
                                        </div>

                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Corrected Punch In Date and Time</span></p></div>
                                            <div className="table-row-cell-regular"><p>{corrected_punch_in_datetime}</p></div>
                                        </div>

                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Actual Punch Out Date and Time</span></p></div>
                                            <div className="table-row-cell-regular"><p>{actual_punch_out_datetime ? actual_punch_out_datetime : "-"}</p></div>
                                        </div>

                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Corrected Punch Out Date and Time </span></p></div>
                                            <div className="table-row-cell-regular"><p>{corrected_punch_out_datetime}</p></div>
                                        </div>
                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Request Type</span></p></div>
                                            <div className="table-row-cell-regular"><p style={{ textTransform: 'capitalize' }}>{regulizeData.request_type === 0 ? 'Regularize Both' : regulizeData.request_type === 1 ? 'Regularize Punch In Time' : regulizeData.request_type === 2 && 'Regularize Punch Out Time'}</p></div>
                                        </div>

                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Clarification</span></p></div>
                                            <div className="table-row-cell-regular"><p>{remark}</p></div>
                                        </div>

                                        <div className="table-row-regular">
                                            <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Remark</span></p></div>
                                            <div className="table-row-cell-regular"><p>{managerRemark}</p></div>
                                        </div>

                                    </div>


                                    :

                                    <div className="add-new-user-form">

                                        <div className="formInput" style={{ flexBasis: "50%" }}>
                                            <label style={{ textTransform: "capitalize" }}>
                                                <span className="label-heading">Request by :</span> {regulizeData.resource_name}
                                            </label>

                                        </div>
                                        <div className="formInput" style={{ flexBasis: "50%" }}>
                                            <label >
                                                <span className="label-heading">Request-Type :</span> {regulizeData.request_type === 0 ? 'Regularize Both' : regulizeData.request_type === 1 ? 'Regularize Punch In Time' : regulizeData.request_type === 2 ? 'Regularize Punch Out Time' : ""}
                                            </label>

                                        </div>

                                        <div className="formInput" style={{ flexBasis: "50%" }}>
                                            <label >
                                                <span className="label-heading">Actual Punch In Date-Time :</span> {regulizeData.actual_punch_in_datetime ? (regulizeData.actual_punch_in_datetime).replace(/[TZ]/g, ' ') : ""}
                                            </label>

                                        </div>

                                        <div className="formInput" style={{ flexBasis: "50%" }}>
                                            <label >
                                                <span className="label-heading">Actual Punch Out Date-Time :</span> {regulizeData.actual_punch_out_datetime ? (regulizeData.actual_punch_out_datetime).replace(/[TZ]/g, ' ') : ""}
                                            </label>

                                        </div>

                                        <div className="formInput" style={{ flexBasis: "100%" }}>
                                            <label >
                                                <span className="label-heading">Clarification :</span> {remark}
                                            </label>

                                        </div>

                                        {regulizeData.request_type === 0 || regulizeData.request_type === 1 ?

                                            <div className="formInput">
                                                <label >
                                                    Punch In Date and Time
                                                </label>
                                                <DatePicker
                                                    minDate={minDate}
                                                    maxDate={maxDate}
                                                    name="punch_in_time"
                                                    selected={punchInTime}
                                                    onChange={(time) => setPunchInTime(time)}
                                                    dropdownMode="select"
                                                    timeInputLabel="Time:"
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    showTimeInput
                                                />
                                            </div>
                                            :
                                            ""}
                                        {regulizeData.request_type === 0 || regulizeData.request_type === 2 ?

                                            <div className="formInput">
                                                <label >
                                                    Punch Out Date and Time
                                                </label>
                                                <DatePicker
                                                    name="punch_out_time"
                                                    minDate={minDate}
                                                    maxDate={addExtraDate(maxDate)}
                                                    selected={punchOutTime}
                                                    onChange={(punchOutTime) => setPunchOutTime(punchOutTime)}
                                                    timeInputLabel="Time:"
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    showTimeInput
                                                />
                                            </div>
                                            : ""}

                                        <div className="formInput" style={{ flexBasis: "100%" }}>
                                            <label >
                                                Remark
                                            </label>

                                            <textarea defaultValue={managerRemark} name="remark" onChange={handleChange} rows="3" placeholder="Remark"></textarea>
                                        </div>

                                    </div>
                                }
                                {regulizeData && (regulizeData.status === 5 || regulizeData.status === 6 || regulizeData.status === 7)
                                    ?
                                    <div className="table-row-regular">
                                        <div className="table-row-cell-regular"><p><span style={{ fontWeight: "bold" }}>Status</span></p></div>
                                        <div className="table-row-cell-regular"><button style={{ marginLeft: "0" }} disabled className={`statuscard ${regularStatus}`}>{regularStatus}</button></div>
                                    </div>
                                    // <p>Status :
                                    //     <button disabled className={`statuscard ${regularStatus}`}>{regularStatus}</button>
                                    // </p>
                                    :

                                    loading === false && <button onClick={submitDataByManager} className="btn btn-approve">{"Approve"}</button>

                                }
                                {regulizeData.status === 4 && loading === false && <button onClick={cancelModale} className="btn btn-cancel">Reject</button>}
                            </form>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openCancelModal}
                    onClose={cancelModale}
                    hideBackdrop
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >

                    <DialogContent>
                        {/* {loading === `${true}_updateRegulization` && <LoaderForm />} */}
                        <div className="popup-confirming">
                            <h3>Rejected</h3>
                            <p>Are you sure to reject regularization request</p>
                            <button className="yes" onClick={() => cancelReq()}>Yes</button>
                            <button className="no" onClick={() => setOpenCancelModal(false)} autoFocus>
                                No
                            </button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}

export default TeamRegularization