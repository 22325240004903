import "./attendacecalender.scss"
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import CloseIcon from '@mui/icons-material/Close';
import AttendanceDetails from "./AttendanceDetails";
import { useDispatch, useSelector } from "react-redux";
import { allResources, getTeamAttendance } from '../../redux/features/teamAttendanceSlice';
import { useEffect, useMemo, useState } from "react";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { ToastContainer } from "react-toastify";
import { STATUSES } from "../../redux/common-status/constant";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle, IconButton, TablePagination } from "@mui/material";
import { attandanceDetailById } from "../../redux/features/teamAttendanceSlice";
import Filter from "../../components/Filter";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";

const TeamAttendanceList = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-punchin_date"},"skip":0,"limit":25,"columns":[${DefaultFilterColumns.TEAM_ATTENDANCE_LIST_COLUMNS}]}`)
  const [filterResourceList, setFilterResourceList] = useState([]);
  const [limit, setLimit] = useState(25);

  const [modalView, setModelView] = useState(false)
  const [attendaceDetails, setAttendaceDetails] = useState([])
  // custom filter
  const [fieldName, setFieldName] = useState("")
  const [options, setOptions] = useState({})
  const [defaultValue, setDefaultValue] = useState({})
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

  const { teamAttendanceList, status, resourceList, attendaceDetailsById } = useSelector(state => state.teamAttendance)
  const { data: list } = teamAttendanceList

  if (filterResourceList && filterResourceList.length === 0 && status === `${STATUSES.SUCCESS}_resourceList` && resourceList && resourceList.data && resourceList.data.rows && resourceList.data.rows.length > 0) {
    setLoader(false)
    let array = []
    resourceList.data && resourceList.data.rows.map(e => { return array.push({ value: e.id, label: e.name + "(" + e.email + ")" }) })
    array.length > 0 && setFilterResourceList(array)
    array.length > 0 && setOptions({ ...options, resource_id: array })
  }

  useEffect(() => {
    dispatch(getTeamAttendance(filterQuery))
    dispatch(allResources())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (modalView === true && status === `${STATUSES.SUCCESS}_attendaceDetailsById` && attendaceDetailsById && attendaceDetailsById.data && attendaceDetails.length === 0) {
    const attenance_details_data = attendaceDetailsById.data && attendaceDetailsById.data.rows;
    setAttendaceDetails(attenance_details_data)
  }

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    setLimit(limit)
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getTeamAttendance(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getTeamAttendance(JSON.stringify(newFilterData)))
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(list && list.rows && list.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // filter
  const onFilterChange = (filterModel) => {
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
      setFieldName(filterModel.items[0].columnField)
    }
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "equals" ? 'ieq' :
          filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
              filterModel.items[0].operatorValue === "is" ? 'eq' :
                filterModel.items[0].operatorValue
      const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.TEAM_ATTENDANCE_LIST_COLUMNS}]}`
      setFilterQuery(filterData)
      setCurrentPage(0)
      dispatch(getTeamAttendance(filterData))
    }
    if (filterModel.items && filterModel.items.length === 0) {
      setCurrentPage(0)
      // setPageSize(10)
      setDefaultValue({})
      setFilterQuery(`{order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.TEAM_ATTENDANCE_LIST_COLUMNS}]}`)
      dispatch(getTeamAttendance(`{"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.TEAM_ATTENDANCE_LIST_COLUMNS}]}`))
      setDefaultValue("")
    }
  }

  const viewDetails = async (data) => {
    setAttendaceDetails([])
    if (data && data.id) {
      setModelView(true)
      dispatch(attandanceDetailById(data.id))
    }
  }

  const closeModal = () => {
    setModelView(false)
    setAttendaceDetails([])
    dispatch(attandanceDetailById(false))
  }


  const teamAttendanceColumns = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    {
      field: "emp_code",
      headerName: "Emp Code",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {params.row.resource && params.row.emp_code ? params.row.emp_code : ""}
          </div>
        );
      },
    },
    {
      field: "resource_id",
      headerName: "Name",
      width: 200,
      type: 'singleSelect',
      valueOptions: filterResourceList,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus`}>
            {params.row.resource && params.row.resource ? params.row.resource : ""}
          </div>
        );
      },
    },
    { field: "punchin_date", headerName: "Punch In Date", width: 200, type: 'date' },
    { field: "punchin_time", headerName: "Punch In Time", width: 200 },
    { field: "break_duration_minutes", headerName: "Break Duration", width: 230 },
    { field: "work_duration_minutes", headerName: "Work Duration", width: 230 },
    { field: "punch_out_date", headerName: "Punch Out Date", width: 200 },
    { field: "punch_out_time", headerName: "Punch Out Time", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              moduleName="teamAttendance"
              rowid={params.row.id}
              editLinkUrl=""
              viewLinkUrl=""
              isDelete={false}
              deleteIconName=""
              editButton={false}
              viewButton={viewDetails}
              addMoreData={params.row}
              deleteButton={false} />
          </div>
        );
      },
    },
  ]

  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
    );
  }

  const columns = useMemo(() =>
    teamAttendanceColumns.map((col) => {
      if (col.field === "resource_id") {
        return {
          ...col,
          filterOperators: getGridSingleSelectOperators()
            .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                && CustomFilterInputValue

            }))
        };
      }
      return col;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [teamAttendanceColumns]
  );

  return (
    <div className="listing">
      <Sidebar />
      <div className="listingContainer">
        <Navbar />

        <div className="datatable">

          <div className="datatableTitle">
            <h3>Team Attendance</h3>
            <div>
              <RefreshButton api={getTeamAttendance} filter={filterQuery} style={{ marginRight: 5 }} />
              <Tooltip title="Back">
                <button onClick={() => navigate(-1)} className="btn-add-new "><ArrowBackIcon /></button>
              </Tooltip>
            </div>
          </div>
          <ToastContainer />
          {(loader === true || status === STATUSES.LOADING) && <LoaderForm />}

          <DataGrid
            className="datagrid"
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            rows={list && list.rows ? list.rows : []}
            columns={columns}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            onFilterModelChange={onFilterChange}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowsOverlay
            }}
          />

        </div>

        {modalView === true &&
          <Dialog
            hideBackdrop
            fullWidth={true}
            maxWidth={"md"}
            open={modalView}
            onClose={closeModal}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeModal}
              aria-label="close"
              style={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <CloseIcon />
            </IconButton>

            <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Attendance Details</DialogTitle>
            <DialogContent>
              <AttendanceDetails attendaceDetails={attendaceDetails} />
            </DialogContent>

          </Dialog>
        }
      </div>
    </div>
  )
}

export default TeamAttendanceList