import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';
import { Add, ArrowBack } from "@mui/icons-material";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from "../../redux/common-status/constant";
import { availabilityColumns } from "../../components/datatable-coulms/tableCoulmns";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import "./manageAvailability.scss"
import { availabiltyDetails, availabiltyEdit, getAvailabiltyList } from "../../redux/features/interviewSlice";
import ViewAvailability from "./ViewAvailability";
import { TablePagination, Tooltip } from "@mui/material";
import CreateAvailability from "./CreateAvailability";
import { RefreshButton } from "../../components/common-function/RefreshButton";

const user = LocalStorageDataService.userDetail();
const userId = user && user.id

function addZero(n) {
    return n > 9 ? "" + n : "0" + n;
}
const timeArray = (i) => {
    var timeArrayForTo = [];
    for (i; i <= 23; i++) {
        timeArrayForTo.push(addZero(i));
    }
    return timeArrayForTo
}

const MyAvailability = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { status, error, manageAvailability_List, manageAvailability_Details_View, manageAvailability_Edit } = useSelector(state => state.interview)
    const [currentPage, setCurrentPage] = useState(0);
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"resource_id":{"value":${userId},"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":25}`)

    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    //view availability
    const [activeViewMode, setActiveViewMode] = useState(false)
    const [viewData, setViewData] = useState({})
    const [slotDetails, setSlotDetails] = useState([])

    //edit availability
    const [editData, setEditData] = useState({})
    const [ID, setID] = useState("")
    const [fromDate, setFromDate] = useState("")

    useEffect(() => {
        dispatch(getAvailabiltyList(filterQuery))
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getAvailabiltyList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getAvailabiltyList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(manageAvailability_List && manageAvailability_List.data && manageAvailability_List.data.rows && manageAvailability_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getAvailabiltyList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{"filter":{"resource_id":{"value":${userId},"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
        }
    }

    // action columns
    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="source-type"
                            rowid={params.row.id}
                            addMoreData={params.row.id}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={false}
                            editButton={params.row.is_editable === true ? handleClickEdit : false}
                            viewButton={handleClickView}
                            deleteButton={false} />
                    </div>
                );
            },
        },
    ];

    //filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                        filterModel.items[0].operatorValue === "is" ? 'eq' :
                            filterModel.items[0].operatorValue
            // const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            const filterData = `{"filter":{"resource_id":{"value":${userId},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit}}`

            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getAvailabiltyList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"filter":{"resource_id":{"value":${userId},"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getAvailabiltyList(`{"filter":{"resource_id":{"value":${userId},"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`))
        }
    }

    const handleOpen = () => {
        setOpen(true)
        dispatch(availabiltyEdit('type'))
        setEditData([])
        setSlotDetails([])
        setFromDate("")
    };

    const handleClose = () => {
        if (ID) { setID(0) }
        // setSelectTechnology("");
        // clearStates();
        setOpen(false);
        setActiveViewMode(false)
        dispatch(availabiltyEdit('type'))
        setEditData([])
        setSlotDetails([])

    }

    const handleClickView = (id) => {
        dispatch(availabiltyDetails(`{"filter":{"interviewer_schedule_setting_id":{"value":${id},"operation": "eq"}},"order_by":{"column":"-id"},"no_limit":True}`))
        setReload('view_data')
        setActiveViewMode(true)
        const filterViewData = manageAvailability_List && manageAvailability_List.data && manageAvailability_List.data.rows.find((ele) => ele.id === id)
        let data = filterViewData
        data && setViewData(data)

    }

    const closeViewMode = () => {
        setActiveViewMode(false)
    }

    if (reload === 'view_data' && status === `${STATUSES.SUCCESS}_manageAvailability_Details_View` && manageAvailability_Details_View && manageAvailability_Details_View.data) {
        let datas = manageAvailability_Details_View.data && manageAvailability_Details_View.data.rows
        datas && setSlotDetails(datas)
        setActiveViewMode(true)
        setReload(false)
    }

    // edit availability section
    const handleClickEdit = (id) => {
        dispatch(availabiltyEdit(id))
        setReload('edit_data')
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_manageAvailability_Edit` && manageAvailability_Edit && manageAvailability_Edit.data) {
        let datas = manageAvailability_Edit.data && manageAvailability_Edit.data
        datas && setFromDate(datas.from_date)
        // setActiveEditMode(true)
        setReload(false)
        setOpen(true)
        setID(datas.id)

        let slots = { schedule_details: [] };
        let tempDaysArr = []

        // eslint-disable-next-line
        datas.schedule_details && datas.schedule_details.length > 0 && datas.schedule_details.map((e, index) => {
            // e['is_week_day_seleceted'] = true

            let dayObject = {}
            let dayArray = []

            let newScheduleTime = []
            // let filterDays = LocalStorageDataService.get_week_days().filter(itm => itm.value !== item.value)
            // return array

            // eslint-disable-next-line
            e.days.map((item, i) => {
                dayObject = Object.assign({
                    // ...dayObject,
                    'label': item,
                    'value': item,
                    'is_checked': true,
                    'p_index': index,
                    'disabled': true
                })
                dayArray.push(dayObject)
                tempDaysArr.push(item)
            })

            // eslint-disable-next-line
            e.schedule_time.map((item, i) => {
                let to_hour = item.availability_to.split(':')[0]
                let to_min = item.availability_to.split(':')[1]
                let from_hour = item.availability_from.split(':')[0]
                let from_min = item.availability_from.split(':')[1]
                let hourList = timeArray(Number(from_hour))
                let hourListFrom = timeArray(Number(from_hour))

                let timeObject = {
                    ...item,
                    disabled: true,
                    availability_from_hours: from_hour,
                    availability_from_minutes: from_min,
                    availability_to_hours: to_hour,
                    availability_to_minutes: to_min,
                    hourList: hourList,
                    hourList_from: hourListFrom,
                    edit_mode: false
                }
                newScheduleTime.push(timeObject)

            })

            slots.schedule_details.push(
                { days: dayArray, schedule_time: newScheduleTime, disabled: true, is_week_day_seleceted: true }
            )
        })

        let filterDays = LocalStorageDataService.get_week_days().filter(o1 => !tempDaysArr.some(o2 => o1.value === o2))
        let tempDayObj

        if (filterDays && filterDays.length > 0 && slots.schedule_details && slots.schedule_details.length > 0) {
            // eslint-disable-next-line
            filterDays.map((item, i) => {
                tempDayObj = Object.assign({
                    'label': item.label,
                    'value': item.value,
                    'is_checked': false,
                    'p_index': slots.schedule_details.length - 1,
                    'disabled': true
                })
                slots.schedule_details[slots.schedule_details.length - 1].days.push(tempDayObj)
            })
        }
        setEditData(slots.schedule_details)
    }

    return (
        <div className="manage-availability">
            <Sidebar />
            <div className="manage-availability-container">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    {/* heading */}
                    <div className="top-headings">
                        <h3>Manage Availability</h3>
                        <div>
                            <Tooltip title={"Create your availability"}>
                                <button onClick={handleOpen} className="btn-add-new" style={{ marginRight: "5px" }}>
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getAvailabiltyList} filter={filterQuery} style={{ marginTop: 0 }} />
                            <Tooltip title="Back">
                                <button onClick={() => navigate(-1)} className="btn-add-new" style={{ marginLeft: "4px" }}>
                                    <ArrowBack />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {/* data grid (table) */}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={manageAvailability_List && manageAvailability_List.data && manageAvailability_List.data.rows ? manageAvailability_List.data.rows : []}
                        columns={availabilityColumns.concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                {/* view section */}
                <ViewAvailability
                    openModal={activeViewMode}
                    setOpenModal={closeViewMode}
                    viewData={viewData}
                    slotDetails={slotDetails}
                />

                {/* add section */}
                <CreateAvailability
                    openModal={open}
                    closeModal={handleClose}
                    listApi={getAvailabiltyList}
                    filters={filterQuery}
                    ID={ID}
                    setID={setID}
                    editData={editData}
                    fromDateL={fromDate}
                />

            </div >
        </div >
    )
}
export default MyAvailability