import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./manage-client.scss"
import { toast, ToastContainer } from "react-toastify";
import { FormControlLabel, Radio, RadioGroup, Tooltip, } from "@mui/material";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryList, getPurchesdpackage, getStateList, getIndustryList, ClientcrudCreat, getClientcrud, getIndustryDepartmentListData, getIndusDepSpecListData, getServiceTypeList, getLocationList, ClientcrudEdit, getIndsDepSpecSkill, ClientcrudUpdate, getIndustryDepartmentList, getIndustryDepSpecList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
// import Button from '@mui/material/Button';
import LoaderForm from "../../components/common-function/LoaderForm";
import { InputFields } from "../../components/common-function/InputFields";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
// import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Select from 'react-select';
import dataService from "../../redux/services/data.service";
import { AddCircleOutline, ArrowBack, Attachment, RemoveCircleOutline } from "@mui/icons-material";
const initialFormData = Object.freeze({
    name: "",
    address: "",
    country: "",
    state: "",
    city: "",
    client_status: null,
    service_type: "",
    purchased_package: "",
    contact_person: [{
        name: "",
        contact: "",
        email: "",
    }],
    skills_data: [{
        industry: null,
        department: null,
        specialization: null,
        skills: null,
        industry_data: null,
        department_data: null,
        specialization_data: null,
        skill_datas: null

    }],

});

const initialFormDataForIDS = {
    industry: null,
    department: null,
    specialization: null,
    industry_data: null,
    department_data: null,
    specialization_data: null,
    skills: null,
    skill_datas: null
}


const formErrData = Object.freeze({
    nameErr: "Name is required",
    addressErr: "Client Address is required",
    countryErr: "Country upto date is required",
    stateErr: "State is required",
    cityErr: "City is required",
    contact_Person_NameErr: "City is required",
    contact_Person_Email_IdErr: "Contact Person email is required",
    contact_Person_Phone_NumberErr: "Contact Person Phone Number is required",
    // departmentsErr: "Department is required",
    client_statusErr: "Status is required",
    industryErr: "Industry is required",
    // specializationErr: "specialization is required",
    // skillsErr: "Skills is required",
    serviceErr: "service is required",
    purchasePackageErr: "purchasePackage is required"
});


const AddnewClient = ({ title }) => {
    const { REACT_APP_ENV } = process.env;
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [open, setOpen] = useState(false);
    const [formErr, setFormErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, clientCrud_Edit, country_List, state_List, industry_List, clientCrud_Update, clientCrud_creat, industryDeprt_List, industryDepartmentSpecialisation_List, industryDepSpeci_List, servicetype_List, purchasedPackeges_List, location_List, indsDepSpecSkill_List } = useSelector(state => state.master)
    const [formValues, setFormValues] = useState(initialFormData)
    const [formValuesForIDS, setFormValuesForIDS] = useState([initialFormDataForIDS])

    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True","columns":[${DefaultFilterColumns.PURCHASED_PACKAGE_LIST_COLUMNS}]}`)
    const [countryfilterQuery, setCountryFilterQuery] = useState(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True","columns":[${DefaultFilterColumns.COUNTRY_LIST_COLUMNS}]}`)

    const [updatedStateList, setUpdatedStateList] = useState([])
    const [updatedDepList, setUpdatedDepList] = useState([])
    const [updatedspecList, setUpdatedspecList] = useState([])
    const [updatedSkillList, setUpdatedSkillList] = useState([])
    const [industryId, setIndustyId] = useState(null)
    const [addContactPerson, setAddContactPerson] = useState({
        name: "",
        contact: "",
        email: "",
    })
    const [skillData, setSkillData] = useState({
        industry: null,
        department: null,
        specialization: null,
        skills: null,
    })
    const [skillShowData, setSkillShowData] = useState({
        industry: null,
        department: null,
        specialization: null,
        skills: null,
    })
    const [countryId, setCountryId] = useState(null)
    const [updatedCityList, setUpdatedCityList] = useState([])
    const [logo, setLogo] = useState(null);


    const location = useLocation()
    const pathName = location?.pathname || "";
    const isEdit = location?.state?.isEdit || "";

    useEffect(() => {
        dispatch(getLocationList(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        dispatch(getPurchesdpackage(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        dispatch(getServiceTypeList(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        // dispatch(getClientcrud(`{"filter":{"client_status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        dispatch(getIndustryList(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True","columns":[${DefaultFilterColumns.INDUSTRY_LIST_COLUMNS}]}`))
        dispatch(getIndustryDepartmentListData(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        dispatch(getIndusDepSpecListData(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        dispatch(getIndsDepSpecSkill(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        dispatch(getStateList(`{"filter":{"status":{'value':"True","operation": "eq"}},"no_limit":"True","order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.STATE_LIST_COLUMNS}]}`))
        dispatch(getCountryList(countryfilterQuery))
        dispatch(getPurchesdpackage(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
        // if (id) {
        //     loadData();
        // }
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        if (id && industryDepSpeci_List?.data) {
            loadData();
        }
    }, [id, industryDepSpeci_List])

    if (reload === true && status === `${STATUSES.SUCCESS}_clientCrud_Update` && clientCrud_Update && clientCrud_Update.message) {
        if (clientCrud_Update.status === 200) {
            toast.success(clientCrud_Update.message)
            setTimeout(() => {
                navigate("/all-client")
            }, 2000)


        } else {
            toast.error(clientCrud_Update.message)
        }
        setReload(false)
    }


    if (reload === true && status === `${STATUSES.SUCCESS}_clientCrud_creat` && clientCrud_creat && clientCrud_creat.message) {

        if (clientCrud_creat.status === 200) {
            toast.success(clientCrud_creat.message)

            setTimeout(() => {
                navigate("/all-client")
            }, 2000)
        } else {
            toast.error(clientCrud_creat.message)
        }
        setReload(false)
    }

    // const handleClose = () => {
    //     if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
    //     setErrStatus(false);
    // }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const handleCountryChange = (e) => {
        const statelist = state_List?.data?.rows?.filter((options) => options.country === e.value)
        setUpdatedStateList(statelist)
        setCountryId(e.value)
    }

    const handleStateChange = (e) => {
        const citylist = location_List?.data?.rows.map((options) => {
            if (e.value == options?.state_id && options?.country_id == countryId) {
                return options
            } else {
                return [];
            }
        }).flat()
        setUpdatedCityList(citylist ? citylist : [])
    }

    const handleIndustrychange = (evt, index) => {
        setUpdatedDepList([])

        const deplist = industryDeprt_List?.data?.rows?.filter((options) => options?.industry?.id === evt.value)
        setUpdatedDepList(deplist[0]?.department)

        const exectingData = [...formValuesForIDS]
        exectingData[index] = {
            ...formValuesForIDS[index],
            industry: evt.value,
            industry_data: evt,
            department: null,
            department_data: null,
            specialization: null,
            specialization_data: null,
            departmentList: deplist[0]?.department ?? [],
        }
        setFormValuesForIDS(exectingData)
    }
    const handleDepartmentChange = (evt, index) => {
        const speclist = industryDepSpeci_List?.data?.rows.filter((options) =>
            evt.value === options?.department_id && options.industry_id === formValuesForIDS[index].industry
        );

        setUpdatedspecList(speclist[0]?.specialization_data ?? [])

        const exectingData = [...formValuesForIDS]
        exectingData[index] = {
            ...formValuesForIDS[index],
            department: evt.value,
            department_data: evt,
            specialization: null,
            specialization_data: null,
            specializationList: speclist[0]?.specialization ?? [],
        }
        setFormValuesForIDS(exectingData)

    }

    const handleSpecializationChange = (evt, index) => {
        const skilllist = indsDepSpecSkill_List?.data?.rows.filter((options) => evt.value === options?.specialization_id);

        setUpdatedSkillList(skilllist?.map(each => each.skill))

        const exectingData = [...formValuesForIDS]
        exectingData[index] = {
            ...formValuesForIDS[index],
            specialization: evt.value,
            specialization_data: evt,
            skills: null,
            skill_datas: null
        }
        setFormValuesForIDS(exectingData)


    }

    const handleCheckboxChange = (evt) => {
        setFormValues({ ...formValues, is_internal: evt.target.checked })
    };

    const addMoreIndustryDepartment = () => {
        setFormValuesForIDS([...formValuesForIDS, initialFormDataForIDS])
    }

    const removeMoreIndustryDepartment = (index) => {
        setFormValuesForIDS(formValuesForIDS.filter((itm, i) => i != index))
    }

    const handleLogoChange = (event) => {
        const selectedFile = event.target.files[0];
        setLogo(URL.createObjectURL(selectedFile));
    };
    const handleSkill = (evt, index) => {
        // dispatch(getIndsDepSpecSkill(`{"filter":{"industry_id":{"value":${formValuesForIDS[index].industry},"operation": "eq"} ,"department_id":{"value":${formValuesForIDS[index].department},"operation": "eq"} ,"status":{"value":True,"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`))
        // setSkillData({ ...skillData, skills: evt.map((skill) => skill.value), skill_datas: evt })

        const exectingData = [...formValuesForIDS]
        exectingData[index] = {
            ...formValuesForIDS[index],
            skills: evt.map((skill) => skill.value),
            skill_datas: evt,
        }
        setFormValuesForIDS(exectingData)
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.address === "" || formValues.name === "" || formValues.client_status === null || formValues.country === "" || formValues.state === "" || formValues.city === "" || formValues.contact_person.name === "" || formValues.contact_person.email === "" && formValues.contact_person.contact === "") {
            setErrStatus(true)
        }
        if (formValues.address !== "" && formValues.name !== "" && formValues.client_status !== null && formValues.country !== "" && formValues.state !== "" && formValues.city !== "" && formValues.contact_person.name !== "" && formValues.contact_person.email !== "" && formValues.contact_person.contact !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id

            const { client_status, ...rest } = formValues
            const skills_data = formValuesForIDS.map(item => {
                let { industry, department, specialization, skills } = item;
                // let dep = Number(department);
                // let spec = Number(specialization);
                let skill = skills?.map(each => Number(each))
                // return {industry,department: [dep],specialization: [spec], skills: skill}
                return { industry, department, specialization, skills: skill ?? [] }

            })

            let addvalues = {
                ...formValues,
                client_status: client_status * 1,
                // created_by: userId,
                contact_person: [addContactPerson],
                skills_data: skills_data,
                country: formValues.country && formValues.country.label ? formValues.country.value : formValues.country,
                state: formValues.state && formValues.state.label ? formValues.state.value : formValues.state,
                city: formValues.city && formValues.city.label ? formValues.city.value : formValues.city,
                purchased_package: formValues.purchased_package && formValues.purchased_package.label ? formValues.purchased_package.value : formValues.purchased_package,
                service_type: formValues.service_type && formValues.service_type.label ? formValues.service_type.value : formValues.service_type,

            }
            if (logo) {
                addvalues = { ...addvalues, logo: logo }
            }
            setErrStatus(false)

            setReload(true)

            if (ID) {
                dispatch(ClientcrudUpdate({ ID, addvalues }))
                // if (res?.status === 200) {
                //     toast.success("Client Updated Succesfully")
                //     navigate("/all-client")
                // }
            } else {
                dispatch(ClientcrudCreat(addvalues))
                // if (res?.status === 200) {
                //     toast.success("Client Added Succesfully")
                //     navigate("/all-client")
                // }
            }
        }

    }

    useEffect(() => {
        const exectingData = [...formValuesForIDS]

        const deplist = industryDepSpeci_List?.data?.rows.filter((options) =>
            options.industry_id === exectingData[0]?.industry_data?.value
        );
        exectingData[0] = {
            ...formValuesForIDS[0],

            departmentList: deplist ? deplist[0]?.department : [],
        }
        setFormValuesForIDS(exectingData)
    }, [industryDepSpeci_List])

    const loadData = async () => {
        setReload(true)
        const res = await dataService.client_crud_edit(id).catch(error => {
            setReload(false)
            return error;
        })

        if (res && res.data && res.data.data) {
            const editData = res.data.data
            setID(editData.id)
            setFormValues({
                name: editData.name,
                address: editData.address,
                country: {
                    label: editData?.country?.name,
                    value: editData?.country?.id,
                    name: "country"
                },
                state: {
                    label: editData?.state?.name,
                    value: editData?.state?.id,
                    name: "state"
                },
                city: {
                    label: editData?.city?.city_name,
                    value: editData?.city?.id,
                    name: "city"
                },
                zip: editData?.zip,
                client_status: editData?.client_status,
                service_type: {
                    label: editData?.service_type?.name,
                    value: editData?.service_type?.id,
                    name: "service_type"
                },
                purchased_package: {
                    label: editData?.purchased_package?.name,
                    value: editData?.purchased_package?.id,
                    name: "purchased_package"
                },
                contact_person: [{
                    name: editData?.contact_person[0]?.name,
                    contact: editData?.contact_person[0]?.contact,
                    email: editData?.contact_person[0]?.email,
                }],
                skills_data: [{
                    industry: editData?.skills_data[0]?.industry,
                    department: editData?.skills_data[0]?.department,
                    specialization: editData?.skills_data[0]?.specialization,
                    skills: editData?.skills_data[0]?.skill,
                }],
                industry: {
                    label: editData?.skills_data[0]?.industry.industry_name,
                    value: editData?.skills_data[0]?.industry.id,
                    name: "industry"
                },
                department: {
                    label: editData?.skills_data[0]?.department?.name,
                    value: editData?.skills_data[0]?.department?.id,
                    name: "department",
                },
                specialization: editData?.skills_data[0]?.specialization,
                skills: editData?.skills_data[0]?.skill,
                is_internal: editData.is_internal ? editData.is_internal : false,
                logo: editData?.logo

            })

            const deplist = industryDepSpeci_List?.data?.rows.filter((options) =>
                options.industry_id === editData?.skills_data[0]?.industry.id,
            );

            const speclist = industryDepSpeci_List?.data?.rows.filter((options) =>
                editData?.skills_data[0]?.department?.id === options?.department_id
            );

            setUpdatedspecList(speclist[0]?.specialization_data ?? [])

            const skilllist = indsDepSpecSkill_List?.data?.rows.filter((options) => editData?.skills_data[0]?.specialization?.id === options?.specialization_id);

            setUpdatedSkillList(skilllist?.map(each => each.skill))
            const exectingData = [...formValuesForIDS]
            exectingData[0] = {
                ...formValuesForIDS[0],
                industry_data: {
                    label: editData?.skills_data[0]?.industry.industry_name,
                    value: editData?.skills_data[0]?.industry.id,
                    name: "industry"
                },

                department_data: {
                    label: editData?.skills_data[0]?.department?.name,
                    value: editData?.skills_data[0]?.department?.id,
                    name: "department",
                },
                specialization_data: { value: editData?.skills_data[0]?.specialization?.id, label: editData?.skills_data[0]?.specialization?.name, name: "specialization" },
                departmentList: [industryDepSpeci_List?.data?.rows?.filter((options) => options?.industry_id === editData?.skills_data[0]?.industry?.id)[0]?.department],
                industry: editData?.skills_data && editData?.skills_data[0]?.industry?.id,
                department: editData?.skills_data && editData?.skills_data[0]?.department?.id,
                specialization: editData?.skills_data && editData?.skills_data[0]?.specialization?.id,
                skills: editData?.skills_data && editData?.skills_data[0]?.skill,
                skill_datas: editData?.skills_data && editData?.skills_data[0]?.skills?.map(i => ({ value: i.id, label: i.skill_name, name: "skills" }))
            }

            // const deplist = industryDeprt_List?.data?.rows?.filter((options) => options?.industry?.id === evt.value)
            // setUpdatedDepList(deplist[0]?.department)
            setFormValuesForIDS(exectingData)

            setAddContactPerson({
                name: editData?.contact_person[0]?.name,
                contact: editData?.contact_person[0]?.contact,
                email: editData?.contact_person[0]?.email,
            })

            setSkillData({
                industry: editData?.skills_data && editData?.skills_data[0]?.industry,
                industry_data: editData?.skills_data && editData?.skills_data[0]?.industry_data,
                department: editData?.skills_data && editData?.skills_data[0]?.department,
                department_data: editData?.skills_data && editData?.skills_data[0]?.department_data,
                specialization: editData?.skills_data && editData?.skills_data[0]?.specialization,
                specialization_data: editData?.skills_data && editData?.skills_data[0]?.specialization_data,
                skills: editData?.skills_data && editData?.skills_data[0]?.skills,
                skill_datas: editData?.skills_data && editData?.skills_data[0]?.skill_datas
            })
            setReload(false)
        } else {
            setReload(false)
        }
    }

    const mobileNumberRegex = /^[6-9]\d{9}$/;

    function isValidMobileNumber(mobileNumber) {
        if (mobileNumber.length < 10) {
            return setFormErr({ ...formErr, contact_Person_Phone_NumberErr: "Mobile number should have at least 10 digits." });
        } else if (mobileNumber.length > 10) {
            return setFormErr({ ...formErr, contact_Person_Phone_NumberErr: "Mobile number should not exceed 10 digits." });
        } else if (!mobileNumberRegex.test(mobileNumber)) {
            return setFormErr({ ...formErr, contact_Person_Phone_NumberErr: "Invalid mobile number. The first digit should be between 6 and 9." });
        } else {
            return setFormErr({ ...formErr, contact_Person_Phone_NumberErr: "Valid mobile number." });
        }
    }

    function handleContactChange(e) {
        isValidMobileNumber(e.target.value)
        setAddContactPerson({ ...addContactPerson, contact: e.target.value })

    }

    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="top-headings">
                    <h3>{title}</h3>
                    <Tooltip title="Back">
                        <Link to={"/all-client"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                {/* {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />} */}
                <div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') ? <LoaderForm /> :
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <InputFields
                                        label={"Client Name "}
                                        name="name"
                                        defaultValue={formValues.name}
                                        type="text"
                                        placeholder="Name"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.nameErr}
                                    />
                                </div>
                                <div className="formInput">
                                    <label >Client Logo:</label>
                                    <input
                                        type="file"
                                        id="logoInput"
                                        accept="image/*"
                                        onChange={handleLogoChange}
                                    />
                                </div>
                                <div className="formInput">
                                    <InputFields
                                        label={"Client Address"}
                                        name="address"
                                        defaultValue={formValues.address}
                                        type="text"
                                        placeholder="Client Address"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.addressErr}
                                    />
                                </div>
                                <div className="formInput">
                                    <label >Select Country</label>
                                    <Select
                                        // className="basic-single"
                                        classNamePrefix="select"
                                        placeholder={'Select Country'}
                                        name={'country'}
                                        defaultValue={formValues?.country}
                                        onChange={(e) => {
                                            handleChange(e)
                                            handleCountryChange(e)
                                        }}
                                        isSearchable={true}
                                        options={
                                            country_List && country_List.data && country_List.data.rows.map((option) => {
                                                return {
                                                    label: option.name,
                                                    value: option.id,
                                                    name: "country"
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues.country === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.countryErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label >Select State</label>
                                    <Select
                                        classNamePrefix="select"
                                        label={"Select State"}
                                        placeholder={'Select State'}
                                        name={'state'}
                                        onChange={(e) => {
                                            handleChange(e)
                                            handleStateChange(e)
                                        }}
                                        defaultValue={formValues.state}
                                        isSearchable={true}
                                        options={
                                            updatedStateList && updatedStateList.map((option) => {
                                                return {
                                                    label: option.name,
                                                    value: option.id,
                                                    name: "state"
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues.state === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.stateErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label >Select City</label>
                                    <Select
                                        classNamePrefix="select"
                                        label={"City"}
                                        placeholder={'Select City'}
                                        name={'city'}
                                        onChange={handleChange}
                                        defaultValue={formValues.city}
                                        isSearchable={true}
                                        options={
                                            updatedCityList && updatedCityList.map((option) => {
                                                return {
                                                    label: option.city_name,
                                                    value: option.id,
                                                    name: "city"
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues.city === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.CityErr}</p>}
                                </div>
                                <div className="formInput">
                                    <InputFields
                                        label={"Zip Code  (Optional)"}
                                        name="zip"
                                        defaultValue={formValues.zip}
                                        type="number"
                                        placeholder="Zip Code"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="formInput">
                                    <InputFields
                                        label={"Contact Person Name "}
                                        name="name"
                                        defaultValue={formValues.contact_person[0].name}
                                        type="text"
                                        placeholder="Contact Person Name"
                                        onChange={(e) => setAddContactPerson({ ...addContactPerson, name: e.target.value })}
                                        errStatus={errStatus}
                                        formErr={formErr.contact_Person_NameErr}
                                    />
                                </div>
                                <div className="formInput">
                                    <InputFields
                                        label={"Contact Person Email Id"}
                                        name="email"
                                        defaultValue={formValues.contact_person[0].email}
                                        type="Email"
                                        placeholder="Email Id"
                                        onChange={(e) => setAddContactPerson({ ...addContactPerson, email: e.target.value })}
                                        errStatus={errStatus}
                                        formErr={formErr.contact_Person_Email_IdErr}
                                    />
                                </div>
                                <div className="formInput">
                                    <InputFields
                                        label={"Contact Person Phone Number "}
                                        name="contact"
                                        defaultValue={formValues.contact_person[0].contact}
                                        type="number"
                                        placeholder="Contact Person Phone Number"
                                        onChange={handleContactChange}
                                        errStatus={errStatus}
                                        formErr={formErr.contact_Person_Phone_NumberErr}
                                    />
                                </div>

                                {
                                    formValuesForIDS.map((item, index) => (
                                        <>
                                            <div className="formInput">
                                                <label >Select Industry</label>
                                                {
                                                    index === 0 ?
                                                        <AddCircleOutline onClick={() => addMoreIndustryDepartment()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} />
                                                        :
                                                        <RemoveCircleOutline onClick={() => removeMoreIndustryDepartment(index)} style={{ marginLeft: "5px", color: "red", cursor: "pointer" }} />
                                                }
                                                <Select
                                                    classNamePrefix="select"
                                                    placeholder={'Select Industry'}
                                                    name={'industry'}
                                                    onChange={(e) => {
                                                        handleIndustrychange(e, index)
                                                    }}
                                                    defaultValue={formValues.industry}
                                                    isSearchable={true}
                                                    options={
                                                        industry_List && industry_List.data && industry_List.data.rows.map((option) => {
                                                            return {
                                                                label: option.industry_name,
                                                                value: option.id,
                                                                name: "industry"
                                                            }
                                                        })


                                                    }
                                                />
                                                {errStatus === true && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                            </div>

                                            {formValuesForIDS[index].industry && <div className="formInput">
                                                <label >Select Departments</label>
                                                <Select
                                                    // isMulti
                                                    classNamePrefix="select"
                                                    placeholder={'Select Departments'}
                                                    name={'department'}
                                                    onChange={(e) => {
                                                        handleDepartmentChange(e, index)
                                                    }}
                                                    defaultValue={formValuesForIDS[index].department_data}
                                                    isSearchable={true}
                                                    options={
                                                        formValuesForIDS[index]?.departmentList && formValuesForIDS[index]?.departmentList.length > 0 ? formValuesForIDS[index]?.departmentList?.map((option) => {
                                                            return {
                                                                label: option.name,
                                                                value: option.id,
                                                                name: "department"
                                                            }
                                                        }) : []

                                                    }
                                                />
                                                {/* {errStatus === true && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentsErr}</p>} */}
                                            </div>}

                                            {formValuesForIDS[index].department && <div className="formInput">
                                                <label >Select Specialization</label>
                                                <Select
                                                    // isMulti
                                                    classNamePrefix="select"
                                                    placeholder={'Select Specialization'}
                                                    name={'specialization'}
                                                    onChange={(e) => {
                                                        handleSpecializationChange(e, index)
                                                    }}
                                                    defaultValue={formValuesForIDS[index].specialization_data}
                                                    isSearchable={true}
                                                    options={
                                                        updatedspecList && updatedspecList.length > 0 ? updatedspecList.map((option) => {
                                                            return {
                                                                label: option.name,
                                                                value: option.id,
                                                                name: "specialization"
                                                            }
                                                        }) : []

                                                    }
                                                />
                                                {/* {errStatus === true && formValues.specialization === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.specializationErr}</p>} */}
                                            </div>}

                                            {formValuesForIDS[index].specialization && <div className="formInput">
                                                <label >Select Skills</label>
                                                <Select
                                                    isMulti
                                                    classNamePrefix="select"
                                                    placeholder={'Select Skills'}
                                                    name={'skills'}
                                                    onChange={(evt) => handleSkill(evt, index)}
                                                    defaultValue={formValuesForIDS[index].skill_datas}
                                                    isSearchable={true}
                                                    options={
                                                        updatedSkillList && updatedSkillList.length > 0 ? updatedSkillList.map((option) => {
                                                            return {
                                                                label: option.skill_name,
                                                                value: option.id,
                                                                name: "skills"
                                                            }
                                                        }) : []

                                                    }
                                                />
                                                {/* {errStatus === true && formValues.skills === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.skillsErr}</p>} */}
                                            </div>}
                                        </>
                                    ))
                                }

                                <div className="formInput">
                                    <label >Type of Service</label>
                                    <Select
                                        classNamePrefix="select"
                                        placeholder={'Type of Service'}
                                        name={'service_type'}
                                        onChange={handleChange}
                                        defaultValue={formValues.service_type}
                                        isSearchable={true}
                                        options={
                                            servicetype_List && servicetype_List.data && servicetype_List.data.rows.map((option) => {
                                                return {
                                                    label: option.name,
                                                    value: option.id,
                                                    name: "service_type"
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues.service_type === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.serviceErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label >Purchase Pakage</label>
                                    <Select
                                        classNamePrefix="select"
                                        name={'purchased_package'}
                                        placeholder={'Purchase Package'}
                                        onChange={handleChange}
                                        defaultValue={formValues.purchased_package}
                                        isSearchable={true}
                                        options={
                                            purchasedPackeges_List && purchasedPackeges_List.data && purchasedPackeges_List.data.rows.map((option) => {
                                                return {
                                                    label: option.name,
                                                    value: option.id,
                                                    name: "purchased_package"
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues.purchased_package === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.purchasePackageErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label>Status</label>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="client_status"
                                        value={formValues.client_status}
                                        onChange={handleChange}
                                        type="number"
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Active" />
                                        <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                        {/* <FormControlLabel value={2} control={<Radio />} label="Defualter" /> */}
                                    </RadioGroup>
                                </div>
                                <div className="formInput">
                                    <label htmlFor="isInternal">
                                        <input
                                            id="isInternal"
                                            type="checkbox"
                                            checked={formValues.is_internal}
                                            onChange={handleCheckboxChange}
                                        />
                                        Is Internal
                                    </label>
                                </div>
                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    }
                </div>

            </div>
        </div >
    )
}

export default AddnewClient