import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";
// const { REACT_APP_ENV } = process.env;

//////default screeningform apis///////
export const getAllScreeningFormList = createAsyncThunk("screeningform/all-list", async (data) => {
    const res = await dataService.all_screeningform_list(data);
    return res.data;
});

export const getScreeningFormList = createAsyncThunk("screeningform/list", async (data) => {
    const res = await dataService.screening_form_list(data);
    return res.data;
});

export const screeningFormCreate = createAsyncThunk("screeningForm/create", async (data) => {
    const res = await dataService.screening_form_create(data);
    return res.data;
});

export const screeningFormEdit = createAsyncThunk("screeningForm/edit", async (id) => {
    if (id === 'typre') {
        return []
    }
    const res = await dataService.screening_form_edit(id);
    return res.data;
});

export const screeningFormUpdate = createAsyncThunk("screeningForm/update", async (data) => {
    const res = await dataService.screening_form_update(data.ID, data.addvalues);
    return res.data;
});

export const screeningFormArchive = createAsyncThunk("screeningForm/archive", async (id) => {
    const res = await dataService.screening_form_archive(id);
    return res.data;
});

export const jobScreeningForm = createAsyncThunk("screeningForm/job-post", async (id) => {
    const res = await dataService.jobpost_screeningForm(id);
    return res.data;
});

export const jobScreeningFormEdit = createAsyncThunk("screeningForm/job-post-edit", async (id) => {
    if (id === 'type') {
        return [];
    } else {
        const res = await dataService.jobpost_screeningForm_edit(id);
        return res.data;
    }
});

export const jobScreeningFormUpdate = createAsyncThunk("screeningForm/job-post-update", async (data) => {
    const res = await dataService.jobpost_screeningForm_update(data.ID, data.addvalues);
    return res.data;
});

export const storyScreeningFormCreate = createAsyncThunk("screeningForm/story-form", async (id) => {
    // const data = { "data": { "id": 4, "job": 7, "epic": 11625, "story": 11624, "project": 45, "candidate": 6676, "candidate_email": "N/A", "candidate_phone": "7618977395", "tts_call_details": null, "call_date": null, "call_time": null, "status": true, "resource": 131, "comment": "<p>saSD</p>", "created_on": "2023-03-20T08:53:27.930493Z", "created_by": 131, "updated_on": "2023-03-20T08:53:27.952133Z", "updated_by": null }, "message": "Record has been saved", "status": 200 }
    // return data
    const res = await dataService.story_screeningForm(id);
    return res.data;
});

export const storyScreeningFormUpdate = createAsyncThunk("screeningForm-update/story-form", async (data) => {
    const res = await dataService.update_story_screeningForm({ id: data.id, data: data.addvalues });
    return res.data;
});

export const getByIdStoryScreeningForm = createAsyncThunk("screeningForm-get-by-id/story-form", async (id) => {
    const res = await dataService.get_story_screeningForm_byId(id);
    return res.data;
});

export const storyScreeningFormAnswerUpdate = createAsyncThunk("screeningForm-update-answer/story-form", async (data) => {
    const res = await dataService.update_story_screeningForm_data({ id: data.id, data: data });
    return res.data;
});

const candidateScreeningFormSlice = createSlice({
    name: "candidateScreeningForm",
    initialState: {
        screeningForm_AllList: [],
        screeningForm_Create: [],
        screeningForm_List: [],
        screeningForm_Edit: [],
        screeningForm_Update: [],
        screeningForm_Archive: [],
        defaultLoader: false,

        defaultScreeningForm_dropDown: [],
        jobScreeningForm_Create: [],
        jobScreeningForm_getbyID: [],
        jobScreeningForm_Update: [],

        storyScreeningForm_Create: [],
        storyScreeningForm_Update: [],
        storyScreeningForm_GetById: [],
        candidateScreeningStatus: false,
        storyScreeningFormAnswer_Update: [],


    },
    reducers: {},
    extraReducers(builder) {
        builder

            ////////// default screeningForm store section/////////
            ///all screeningForm  list
            .addCase(getAllScreeningFormList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.defaultLoader = true
            })
            .addCase(getAllScreeningFormList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.screeningForm_AllList = action.payload;
                state.defaultScreeningForm_dropDown = !action.payload.data.rows ? [] : action.payload.data.rows;
                state.defaultLoader = false

            })
            .addCase(getAllScreeningFormList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
                state.defaultLoader = false
            })
            /// get filterd screeningForm list
            .addCase(getScreeningFormList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getScreeningFormList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.screeningForm_List = action.payload;
            })
            .addCase(getScreeningFormList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///screeningForm create
            .addCase(screeningFormCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(screeningFormCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_screeningForm_Create`;
                state.screeningForm_Create = action.payload;
            })
            .addCase(screeningFormCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///screeningForm edit
            .addCase(screeningFormEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(screeningFormEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.screeningForm_Edit = action.payload;
            })
            .addCase(screeningFormEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///screeningForm Update
            .addCase(screeningFormUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(screeningFormUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_screeningForm_Update`;
                state.screeningForm_Update = action.payload;
            })
            .addCase(screeningFormUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///screeningForm archive
            .addCase(screeningFormArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(screeningFormArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_screeningForm_Archive`;
                state.screeningForm_Archive = action.payload;
            })
            .addCase(screeningFormArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ////////// candidate screeningForm for jobpost store section/////////
            ///job screeningForm create
            .addCase(jobScreeningForm.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobScreeningForm.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_jobScreeningForm_Create`;
                state.jobScreeningForm_Create = action.payload;
            })
            .addCase(jobScreeningForm.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///job screeningForm edit
            .addCase(jobScreeningFormEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobScreeningFormEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_jobScreeningForm_getbyID`;
                state.jobScreeningForm_getbyID = action.payload;
            })
            .addCase(jobScreeningFormEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///job screeningForm update
            .addCase(jobScreeningFormUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(jobScreeningFormUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_jobScreeningForm_Update`;
                state.jobScreeningForm_Update = action.payload;
            })
            .addCase(jobScreeningFormUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///story screeningForm create
            .addCase(storyScreeningFormCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(storyScreeningFormCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_storyScreeningForm_Create`;
                state.storyScreeningForm_Create = action.payload;
            })
            .addCase(storyScreeningFormCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///story screeningForm update
            .addCase(storyScreeningFormUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(storyScreeningFormUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_storyScreeningForm_Update`;
                state.storyScreeningForm_Update = action.payload;
            })
            .addCase(storyScreeningFormUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get story screeningForm By Id
            .addCase(getByIdStoryScreeningForm.pending, (state, action) => {
                state.candidateScreeningStatus = STATUSES.LOADING;
            })
            .addCase(getByIdStoryScreeningForm.fulfilled, (state, action) => {
                state.candidateScreeningStatus = `${STATUSES.SUCCESS}_storyScreeningForm_GetById`;
                state.storyScreeningForm_GetById = action.payload;
            })
            .addCase(getByIdStoryScreeningForm.rejected, (state, action) => {
                state.candidateScreeningStatus = STATUSES.FAILURE
                state.error = action.error
            })

            ///story screeningForm NA answer upadte
            .addCase(storyScreeningFormAnswerUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(storyScreeningFormAnswerUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_storyScreeningFormAnswer_Update`;
                state.storyScreeningFormAnswer_Update = action.payload;
            })
            .addCase(storyScreeningFormAnswerUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


    },
});

export default candidateScreeningFormSlice.reducer;