import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./master.scss";
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    SpecialisationsCreate,
    SpecialisationsEdit,
    SpecialisationsUpdate,
    getSpecialisationsList,
} from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import {
    InputFields,
    InputRadioGroupFields,
    InputTextareaField,
} from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";

const initialFormData = Object.freeze({
    name: "",
    description: "",
    tech_type: "",
    specialization_key: "",
    status: "",
});

const formErrData = Object.freeze({
    specialisationErr: "Specialisation is required",
    descriptionErr: "Specialisation description is required",
    techErr: "Technology type is required",
    specialization_keyErr: "Specialization key is required",
    statusErr: "Status is required",
});

const Specialisations = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData);
    const [formErr] = useState(formErrData);
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0);
    const [archiveOpen, setArchiveOpen] = useState(false);
    const {
        status,
        error,
        specialisations_List,
        specialisations_Create,
        specialisations_Edit,
        specialisations_Update,
    } = useSelector((state) => state.master);
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false);
    const [filterQuery, setFilterQuery] = useState(
        `{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS}]}`
    );
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        id: false,
    });
    const [changeStatus, setChangeStatus] = useState("");

    useEffect(() => {
        dispatch(getSpecialisationsList(filterQuery));
        if (status === STATUSES.FAILURE && error) {
            setReload(false);
            errorHandling(error, navigate);
        }
        // eslint-disable-next-line
    }, []);

    if (
        reload === true &&
        status === `${STATUSES.SUCCESS}_specialisations_Update` &&
        specialisations_Update &&
        specialisations_Update.message
    ) {
        if (specialisations_Update.status === 200) {
            toast.success(specialisations_Update.message);
            dispatch(getSpecialisationsList(filterQuery));
        } else {
            toast.error(specialisations_Update.message);
        }
        setReload(false);
    }

    if (
        reload === true &&
        status === `${STATUSES.SUCCESS}_specialisations_Create` &&
        specialisations_Create &&
        specialisations_Create.message
    ) {
        if (specialisations_Create.status === 200) {
            toast.success(specialisations_Create.message);
            dispatch(getSpecialisationsList(filterQuery));
        } else {
            toast.error(specialisations_Create.message);
        }
        setReload(false);
    }

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) {
            setID(0);
            setFormValues(initialFormData);
        }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false);
    };

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value });
    };

    const submitData = (e) => {
        e.preventDefault();

        if (
            formValues.name === "" ||
            formValues.description === "" ||
            formValues.tech_type === "" ||
            formValues.specialization_key === "" ||
            formValues.status === ""
        ) {
            setErrStatus(true);
        }

        if (
            formValues.name !== "" &&
            formValues.description !== "" &&
            formValues.tech_type !== "" &&
            formValues.specialization_key !== "" &&
            formValues.status !== ""
        ) {
            const rtm_user = JSON.parse(
                localStorage.getItem(`${REACT_APP_ENV}_rtms`)
            );
            let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id;
            let addvalues = { ...formValues, created_by: userId };
            setErrStatus(false);
            setReload(true);
            handleClose();
            if (ID) {
                dispatch(SpecialisationsUpdate({ ID, addvalues }));
            } else {
                dispatch(SpecialisationsCreate(addvalues));
            }
        }
    };

    if (
        reload === "edit_data" &&
        status === `${STATUSES.SUCCESS}` &&
        specialisations_Edit &&
        specialisations_Edit.data
    ) {
        let editData = specialisations_Edit.data;
        setFormValues({
            name: editData.name,
            description: editData.description,
            tech_type: editData.tech_type,
            specialization_key: editData.specialization_key,
            status: editData.status,
            id: editData?.id,
        });
        setID(editData.id);
        setOpen(true);
        setReload(false);
    }

    const handleClickEdit = (id) => {
        dispatch(SpecialisationsEdit(id));
        setReload("edit_data");
    };

    const handleArchive = (id) => {
        setArchiveId(id);
        setArchiveOpen(true);
        if (id.status === true) {
            setChangeStatus("InActive");
        } else {
            setChangeStatus("Active");
        }
    };

    const handleClickArchive = (data) => {
        setReload(true);
        setArchiveOpen(false);
        let addvalues = {
            name: data.name,
            description: data.description,
            tech_type: data.tech_type,
            specialization_key: data.specialization_key,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id,
        };
        let ID = data.id;
        setReload(true);
        dispatch(SpecialisationsUpdate({ ID, addvalues }));
    };

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData["skip"] = data * pageSize;
        newFilterData["limit"] = limit;
        setLimit(limit);
        setFilterQuery(JSON.stringify(newFilterData));
        dispatch(getSpecialisationsList(JSON.stringify(newFilterData)));
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value);
        setCurrentPage(0);
        setLimit(newPageSize.target.value);
        let newFilterData = JSON.parse(filterQuery);
        newFilterData["skip"] = 0;
        newFilterData["limit"] = newPageSize.target.value;
        setFilterQuery(JSON.stringify(newFilterData));
        dispatch(getSpecialisationsList(JSON.stringify(newFilterData)));
    };

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={
                    (specialisations_List &&
                        specialisations_List.data &&
                        specialisations_List.data.rows &&
                        specialisations_List.data.count) ||
                    0
                }
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) => (
                    <TablePaginationActions
                        {...subProps}
                        handlePagination={handlePagination}
                    />
                )}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData["skip"] = 0;
            newFilterData["limit"] = limit;
            newFilterData["order_by"] = {
                column: sort[0].sort === "desc" ? "-" + sort[0].field : sort[0].field,
            };
            setFilterQuery(JSON.stringify(newFilterData));
            dispatch(getSpecialisationsList(JSON.stringify(newFilterData)));
        } else {
            setFilterQuery(
                `{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS}] }`
            );
        }
    };

    const actionColumn = [
        {
            field: "name",
            headerName: "Specialization",
            width: 280,
            renderCell: (params) => {
                return <div>{params.row.name}</div>;
            },
        },
        {
            field: "description",
            headerName: "Description",
            width: 280,
            renderCell: (params) => {
                return <div>{params.row.description}</div>;
            },
        },
        {
            field: "tech_type",
            headerName: "Technology Type",
            width: 280,
            renderCell: (params) => {
                return <div>{params.row.tech_type}</div>;
            },
        },
        {
            field: "specialization_key",
            headerName: "Specialization key",
            width: 280,
            renderCell: (params) => {
                return <div>{params.row.specialization_key}</div>;
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? "Active" : "Inactive"}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="specialization"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)}
                        />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (
            filterModel.items &&
            filterModel.items.length > 0 &&
            filterModel.items[0].columnField &&
            filterModel.items[0].value
        ) {
            let operatorValue =
                filterModel.items[0].operatorValue === "contains"
                    ? "icontains"
                    : filterModel.items[0].operatorValue === "startsWith"
                        ? "istartswith"
                        : filterModel.items[0].operatorValue === "endsWith"
                            ? "iendswith"
                            : filterModel.items[0].operatorValue;
            const filterData = `{"filter":{"${[
                filterModel.items[0].columnField,
            ]}":{"value":"${filterModel.items[0].value
                }","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS
                }]}`;
            setFilterQuery(filterData);
            setCurrentPage(0);
            dispatch(getSpecialisationsList(filterData));
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0);
            //setPageSize(10)
            setFilterQuery(
                `{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS}]}`
            );
            dispatch(
                getSpecialisationsList(
                    `{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.SPECIALISATIONS_LIST_COLUMNS}]}`
                )
            );
        }
    };
    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Specialisations List</h3>
                        <div>
                            <Tooltip title="Add Specialisations">
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton
                                api={getSpecialisationsList}
                                filter={filterQuery}
                                style={{ marginTop: 0 }}
                            />
                        </div>
                    </div>
                    {(status === STATUSES.LOADING ||
                        reload === true ||
                        reload === "edit_data") && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={
                            specialisations_List &&
                                specialisations_List.data &&
                                specialisations_List.data.rows
                                ? specialisations_List.data.rows
                                : []
                        }
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                    >
                        <Close />
                    </Button>
                    <DialogTitle
                        style={{
                            background: "#2196f3",
                            color: "#fff",
                            marginBottom: "20px",
                        }}
                    >
                        {ID ? "Edit" : "Add"} Specialisations
                    </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Specialisation"}
                                    name="name"
                                    defaultValue={formValues.name}
                                    type="text"
                                    placeholder="Specialisation"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.specialisationErr}
                                />

                                <InputFields
                                    label={"Technology Type"}
                                    name="tech_type"
                                    defaultValue={formValues.tech_type}
                                    type="text"
                                    placeholder="Technology Type"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.techErr}
                                />

                                <InputTextareaField
                                    label={"Specialisation Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Specialisation Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />

                                <InputFields
                                    label={"Specialization key"}
                                    name="specialization_key"
                                    defaultValue={formValues.specialization_key}
                                    type="text"
                                    placeholder="Specialization key"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.specialization_keyErr}
                                />

                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                            </div>
                            <button className="submit-modal" onClick={submitData}>
                                {ID
                                    ? CONSTANTS.COMMON_BUTTONS.UPDATE
                                    : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} specialisations`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div>
    );
};

export default Specialisations;
