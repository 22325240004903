import { memo } from "react"
import Select from 'react-select';

const SkillsHiring = ({ specialistation_id, formValues, skill_List, handleSkillChange, index, showSkillData }) => {

    const handleChange = (e, index) => {
        handleSkillChange(specialistation_id, e, index)
    }

    return (
        <div className="formInput">
            <label> Skills  </label>
            <Select
                isMulti
                className="basic-single"
                classNamePrefix="select"
                placeholder="Skills"
                defaultValue={showSkillData && showSkillData[specialistation_id] ? showSkillData[specialistation_id].skill_data : []}
                isSearchable={true}
                name={"skill_data"}
                options={
                    skill_List && skill_List.length > 0 ? skill_List.map((option) => {
                        return {
                            label: option.skill && option.skill.skill_name,
                            value: option.skill && option.skill.id,
                            technology_id: specialistation_id,
                            name: "skill_data"
                        }
                    }) : []
                }
                onChange={(e) => handleChange(e, index)}

            />
        </div>
    )
}
export default memo(SkillsHiring)