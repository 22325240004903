import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./evaluation.scss"
import { toast, ToastContainer } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import { EvaluationProjectColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import { DialogTitle, Button, Dialog, DialogContent, TablePagination, Rating, Checkbox, Tooltip } from '@mui/material';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { ArrowBack, Close, InfoRounded } from "@mui/icons-material";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { myProjectResources } from "../../redux/features/dashboardSlice";
import { getCESQuestionTypeList, getQuestionTypePEList } from "../../redux/features/masterSlice";
import Select from 'react-select';
import dataService from "../../redux/services/data.service";
import { evaluationReviewAttchechmentUpload, evaluationReviewCreate, getEvaluationQuestSetList } from "../../redux/features/evaluationSlice";
import moment from "moment";
import { getProjectFilterList } from "../../redux/features/projectSlice";
import { projectsListOption } from "../../redux/features/optionsSlice";
import CustomeTooltip from "../../components/common-function/CustomeTooltip";
import { Link } from "react-router-dom";


const initialFormData = Object.freeze({
    //rating_value: "",
    review_text: "",
    not_applicable: false,
    //comment: "",
    status: true,
    project_resource: ""
});

const formErrData = Object.freeze({
    rating_valueErr: "Rating value is required",
    quesErr: "Question is required",
    project_resourceErr: 'Resource is required',
    commetErr: 'Comment is required'
});

const EvaluationQuestionReview = () => {
    const dispatch = useDispatch();
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [uploadedDocs, setUploadedDocs] = useState([])
    const [uploadedQuestionFiles, setUploadedQuestionFiles] = useState([])
    const [uploadedQuestionDocs, setUploadedQuestionDocs] = useState([])
    const [, setFileErr] = useState("")
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);

    const { myProjectList } = useSelector(state => state.dashboardCard)
    const { projectsOptions } = useSelector(state => state.dropdownOptions)
    const { status, error, evaluationQuest_list, evaluationReview_create, evaluationReviewAttchechment_upload } = useSelector(state => state.evaluation)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState({ "filter": { "status": { "value": "True", "operation": "eq" }, "resource_id": { "value": GetLocalstorage.userDetail().id, "operation": "eq" } }, "skip": 0, "limit": 25, "order_by": { "column": "-id" } })
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [tempData, setTemData] = useState("")
    const [tempProjectResource, setTempProjectResource] = useState([])
    //const [addQues, setAddQues] = useState([])
    const [activeModalType, setActiveModalType] = useState("")

    useEffect(() => {
        dispatch(projectsListOption({ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True", "order_by": { "column": "-id" } }))
        dispatch(getProjectFilterList(`{"filter":{"status":{"value":True, "operation":"eq"}}, "no_limit":True, "order_by":{"column":"-id"}}`))
        dispatch(getCESQuestionTypeList())
        dispatch(myProjectResources({ "filter": { "status": { "value": "True", "operation": "eq" }, "resource_id": { "value": GetLocalstorage.userDetail().id, "operation": "eq" } }, "skip": 0, "limit": limit, "order_by": { "column": "-id" } }))
        // eslint-disable-next-line
    }, [])

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    //const handleOpen = () => setOpen(true);
    const handleClose = () => {
        dispatch(getQuestionTypePEList('type'))
        dispatch(getEvaluationQuestSetList('type'))
        setUploadedDocs([])
        setUploadedQuestionDocs([])
        setTempProjectResource([])
        if (ID) { setID(0); } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false)
    }

    const handleChange = (evt, id, mainId) => {
        const { name, value, checked } = evt.target || evt;
        // if (name === 'q_type') {
        //     (value === 'pe' || value === 'PE') ?
        //         dispatch(getQuestionTypePEList(`{"filter":{"question_type__question_type_key":{"value":"${value}","operation": "eq"}}}`))
        //         :
        //         dispatch(getQuestionTypePEList('type'))
        // }
        if (name === 'not_applicable') {
            setTimeout(() => {
                setFormValues({ ...formValues, [name]: checked, "review_text": '' });
            }, 500);

        }
        if (name > 0) {
            setFormValues({ ...formValues, [name]: checked });
        } else {
            if (name === 'project_resource') {
                //setReload('already_set_ques')
                setFormValues({ ...formValues, [name]: value });
                let evalutor_id = tempData && tempData.role_id
                let evalutee_id = tempProjectResource.filter(x => x.resource_id === value)[0].role_id
                if (evalutor_id && evalutee_id) {
                    setReload("selectedQuesyion")
                    dispatch(getEvaluationQuestSetList({
                        "filter": {
                            "evaluators_project_role": { value: tempData && tempData.role_id, operation: 'eq' },
                            "evaluatees_project_role": { value: tempProjectResource.filter(x => x.resource_id === value)[0].role_id, operation: 'eq' }
                        }, "no_limit": "True"
                    }))

                } else {
                    toast.error("Please assign project-role for these project our resources")
                }
            }

            if (id) {
                if (name === 'rating_value') {
                    let comment = formValues[id] && formValues[id].comments ? formValues[id].comments : ''
                    let attachement = formValues[id] && formValues[id].attachments ? formValues[id].attachments : ''

                    setFormValues({ ...formValues, [id]: { rating_value: value, question: mainId, comments: comment, attachments: attachement } });
                }
                if (name === 'comments') {
                    let rating_value = formValues[id] && formValues[id].rating_value ? formValues[id].rating_value : 0;
                    let attachement = formValues[id] && formValues[id].attachments ? formValues[id].attachments : '';
                    let parent_Id = formValues[id] && formValues[id].question ? formValues[id].question : '';

                    setFormValues({ ...formValues, [id]: { rating_value: rating_value, question: parent_Id, comments: value, attachments: attachement } });
                }

            } else {
                setFormValues({ ...formValues, [name]: value });
            }

        }
    }

    if (reload === 'selectedQuesyion' && evaluationQuest_list && evaluationQuest_list.status) {
        if (evaluationQuest_list.data && evaluationQuest_list.data.rows && evaluationQuest_list.data.rows.length > 0) {
            // eslint-disable-next-line
            evaluationQuest_list.data.rows.map(e => {
                if (e.questions && e.questions.length > 0) {
                    // eslint-disable-next-line
                    e.questions.map(itm => {
                        setFormValues({ ...formValues, [itm.id]: true });
                    })
                }
            })
        }
        setReload(false)
    }

    // const callFileUploadApi = async (attachment) => {
    //     let api_path_for_download = "";
    //     const formData = new FormData();
    //     formData.append("file", attachment);
    //     const request = await dataService.evaluation_question_attchement_post(formData)
    //     const response = await request.data
    //     if (response.data) {
    //         api_path_for_download = response.data.api_path_for_download
    //     }
    //     if (response.status === 400) {
    //         toast.error(response.message)
    //         api_path_for_download = "error"

    //     }
    //     //return api_path_for_download

    // }

    if (reload === 'review' && evaluationReview_create && evaluationReview_create.status) {
        setReload(false)
        if (evaluationReview_create.status === 200) {
            toast.success(evaluationReview_create.message)
            handleClose()
        } else {
            dispatch(evaluationReviewCreate('type'))
            toast.error(evaluationReview_create.message)
        }
    }

    const handleQuestionCreate = async (data, key) => {
        setActiveModalType(key)
        const req = await dataService.myEvaluationByProjectId_list({ "filter": { "status": { "value": "True", "operation": "eq" }, "project_id": { "value": data.project_id, "operation": "eq" } }, "no_limit": "True" })
        const res = await req.data
        if (res && res.data && res.data.rows) {
            let array = []
            res.data.rows.map(e => {
                if (e.resource_id !== GetLocalstorage.userDetail().id) {
                    array.push(e)
                }
                return array
            })
            setTempProjectResource(array)
        } else {
            toast.error(res.message)
            setTempProjectResource([])
        }
        dispatch(getQuestionTypePEList(`{"filter":{"question_type__question_type_key":{"value":"pe","operation": "eq"}, "status":{"value":"True", "operation":"eq"}}}`))
        setTemData(data)
        setOpen(true)
    }
    // const addMoreQuestion = () => {
    //     let newArray = addQues.length > 0 ? structuredClone(addQues) : []
    //     newArray.push({ question: "" })
    //     setAddQues(newArray)
    // }

    // const handleQuestionChange = (evt, index = null) => {
    //     const { name, value } = evt.target || evt;
    //     const valueArray = addQues.length > 0 && addQues.map((item, i) => i === index ?
    //         { ...item, [name]: value } : item)
    //     index !== null && setAddQues([...valueArray])
    // }
    // const removeAddQues = (i) => {
    //     const updateformData = addQues.filter((item, index) => index !== i);
    //     setAddQues(updateformData)
    // }

    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        // eslint-disable-next-line
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                const formData = new FormData();
                formData.append("file", file);
                setReload('attachement')
                dispatch(evaluationReviewAttchechmentUpload(formData))
                if (uploaded.length > 5) {
                    setErrStatus(true)
                    setFileErr(`You can only add a maximum of 5 files`)
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) {
            setUploadedFiles(uploaded); setFormValues({ ...formValues, attachment: uploaded })
        }
    }

    const handleQuestionUploadFiles = (files, id) => {
        // const uploaded = [...uploadedQuestionFiles];
        let newUploadedArray = []
        const uploaded = uploadedQuestionFiles[id] ? [...uploadedQuestionFiles[id]] : newUploadedArray;
        let limitExceeded = false;
        // eslint-disable-next-line
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                const formData = new FormData();
                formData.append("file", file);
                setReload(id)
                dispatch(evaluationReviewAttchechmentUpload(formData))
                if (uploaded.length > 5) {
                    setErrStatus(true)
                    setFileErr(`You can only add a maximum of 5 files`)
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) {
            setUploadedQuestionFiles({ ...uploadedQuestionFiles, [id]: uploaded })
            if (formValues[id]) {
                let rating_values = formValues[id] && formValues[id].rating_value ? formValues[id].rating_value : 0;
                let comment = formValues[id] && formValues[id].comments ? formValues[id].comments : '';
                let parent_Id = formValues[id] && formValues[id].question ? formValues[id].question : '';

                setFormValues({ ...formValues, [id]: { rating_value: rating_values, question: parent_Id, comments: comment, attachments: uploaded } });
            } else {
                setFormValues({ ...formValues, [id]: { rating_value: 0, question: id, comments: '', attachments: uploaded } });
            }
        }
    }

    const onFileChange = (event, id) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files)
        if (id > 0) {
            handleQuestionUploadFiles(chosenFiles, id);
        } else {
            handleUploadFiles(chosenFiles);
        }
    }

    if (reload === 'attachement' && evaluationReviewAttchechment_upload && evaluationReviewAttchechment_upload.status) {
        setReload(false)
        dispatch(evaluationReviewAttchechmentUpload('type'))

        const uploaded = [...uploadedDocs]

        if (evaluationReviewAttchechment_upload.data && evaluationReviewAttchechment_upload.data.api_path_for_download) {
            uploaded.push(evaluationReviewAttchechment_upload.data.api_path_for_download)
            setUploadedDocs(uploaded)
        }
        if (evaluationReviewAttchechment_upload.status === 400) {
            toast.error(evaluationReviewAttchechment_upload.message)
        }
    }


    if (reload > 0 && evaluationReviewAttchechment_upload && evaluationReviewAttchechment_upload.status) {
        setReload(false)
        dispatch(evaluationReviewAttchechmentUpload('type'))

        let newUploadedArray = []
        const uploaded = uploadedQuestionDocs[reload] ? [...uploadedQuestionDocs[reload]] : newUploadedArray;

        if (evaluationReviewAttchechment_upload.data && evaluationReviewAttchechment_upload.data.api_path_for_download) {
            uploaded.push(evaluationReviewAttchechment_upload.data.api_path_for_download)
            setUploadedQuestionDocs({ ...uploadedQuestionDocs, [reload]: uploaded })
        }
        if (evaluationReviewAttchechment_upload.status === 400) {
            toast.error(evaluationReviewAttchechment_upload.message)
        }
    }

    const submitData = (e) => {
        e.preventDefault()
        let peFlag = false

        if (activeModalType === 'review') {
            let question_rating_details = []
            let rating_min_val = 1
            let rating_max_val = 5
            if (evaluationQuest_list && evaluationQuest_list.data && evaluationQuest_list.data.rows && evaluationQuest_list.data.rows.length > 0) {
                evaluationQuest_list.data.rows.map(item => {
                    if (item.questions && item.questions.length > 0) {
                        item.questions.map(e => {
                            if (formValues[e.id]) {
                                if (formValues[e.id].rating_value === 0) {
                                    peFlag = true
                                }
                                //let filterAttchemnt = uploadedQuestionDocs[e.id] === formValues[e.id]
                                if (uploadedQuestionDocs[e.id] && uploadedQuestionDocs[e.id].length > 0) {
                                    formValues[e.id]['attachment'] = uploadedQuestionDocs && uploadedQuestionDocs[e.id] ? uploadedQuestionDocs[e.id] : []
                                }
                                //  else {
                                //     formValues[e.id]['attachment'] = []
                                // }

                                question_rating_details.push(formValues[e.id])
                            }
                            if (!formValues[e.id]) {
                                peFlag = true
                            }
                            return question_rating_details
                        })
                    }
                    return question_rating_details

                })
            }
            if (formValues.not_applicable === false && ((question_rating_details.length === 0 || peFlag === true) || !formValues.project_resource)) {
                setErrStatus(true)
            }
            if (formValues.not_applicable === true && formValues.review_text === '') {
                peFlag = true
                setErrStatus(true)
            } else if (formValues.not_applicable === true && formValues.review_text) {
                peFlag = false
            }
            if (peFlag === false && (question_rating_details.length > 0 || formValues.not_applicable === true) && formValues.project_resource) {
                // let attachement = []
                // let flagIs = false
                // if (formValues.attachment && formValues.attachment.length > 0) {
                //     flagIs = true
                //     setReload(`${formValues.attachment.length}`)
                //     formValues.attachment.map(e => {
                //         // const formData = new FormData();
                //         // formData.append("file", e);
                //         // dispatch(evaluationReviewAttchechmentUpload(formData))
                //         // let filepath = callFileUploadApi(e)
                //         // if (api_path_for_download && api_path_for_download !== 'error') {
                //         //     attachement.push(api_path_for_download)
                //         // }
                //     })
                // }


                // if (question_rating_details && question_rating_details.length > 0) {
                //     question_rating_details.map(e => {
                //         let attachement = []
                //         e.attachment && e.attachment.length > 0 && e.attachment.map(item => {
                //             let filepath = callFileUploadApi(item)
                //             if (filepath) {
                //                 attachement.push(filepath)
                //             }
                //         })
                //         e['attachment'] = attachement
                //     })
                // }

                if (question_rating_details && question_rating_details.length > 0) {
                    question_rating_details.map(e => {
                        delete e['attachments']
                        return e
                    })
                }

                let addValues = {
                    "evaluatee": formValues.project_resource && tempProjectResource.filter(x => x.resource_id === formValues.project_resource)[0].role_id,
                    "evaluator": tempData && tempData.role_id,
                    "review_text_done": formValues.review_text,
                    "evaluation_date": moment().format('YYYY-MM-DD'),
                    "month": moment().month() + 1,
                    "year": moment().year(),
                    "rating_min_val": rating_min_val,
                    "rating_max_val": rating_max_val,
                    "attachment": uploadedDocs,
                    "question_rating_details": formValues.not_applicable === true ? [] : question_rating_details
                }
                if (formValues.not_applicable === true) {
                    addValues['not_applicable'] = formValues.not_applicable
                }
                setErrStatus(false)
                setReload('review')
                dispatch(evaluationReviewCreate(addValues))
            }
        }
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        //let newFilterData = JSON.parse(filterQuery);
        let newFilterData = filterQuery;

        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(newFilterData)
        dispatch(myProjectResources(newFilterData))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = filterQuery;

        //let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(newFilterData)
        dispatch(myProjectResources(newFilterData))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(myProjectList && myProjectList.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = filterQuery;

            //let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(newFilterData)
            dispatch(myProjectResources(newFilterData))
        } else {
            setFilterQuery({ "filter": { "status": { "value": "True", "operation": "eq" }, "resource_id": { "value": GetLocalstorage.userDetail().id, "operation": "eq" } }, "skip": 0, "limit": limit })
        }
    }

    const actionColumn = [
        {
            field: "project_id", headerName: "Project Name", width: 400,
            type: 'singleSelect',
            valueOptions: projectsOptions,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        <CustomeTooltip title={params.row.project && params.row.project.project_name}
                            data={params.row.project && params.row.project.project_name}
                        />
                    </div>
                );
            },
        },
        // {
        //     field: "role_id", headerName: "Project Role", width: 230,
        //     type: 'singleSelect',
        //     valueOptions: projectRoleOption,
        //     renderCell: (params) => {
        //         return (
        //             <div className={`cellWithStatus`}>
        //                 {params.row.role && params.row.role.role_name}
        //             </div>
        //         );
        //     }
        // },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="third-party"
                            rowid={params.row.id}
                            addMoreData={params.row}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={false}
                            editButton={false}
                            viewButton={false}
                            extraButton={handleQuestionCreate}
                            additionalActionIcon="Add"
                            additionalAction={[{ title: "Resource Evaluation Question Review", key: 'review' }]}
                            deleteButton={false}
                        />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = { "filter": { [filterModel.items[0].columnField]: { "value": filterModel.items[0].value, "operation": operatorValue }, "status": { "value": "True", "operation": "eq" }, "resource_id": { "value": GetLocalstorage.userDetail().id, "operation": "eq" } }, "skip": 0, "limit": limit, "order_by": { "column": "-id" } }
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(myProjectResources(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery({ "filter": { "status": { "value": "True", "operation": "eq" }, "resource_id": { "value": GetLocalstorage.userDetail().id, "operation": "eq" } }, "skip": 0, "limit": limit, "order_by": { "column": "-id" } })
            dispatch(myProjectResources({ "filter": { "status": { "value": "True", "operation": "eq" }, "resource_id": { "value": GetLocalstorage.userDetail().id, "operation": "eq" } }, "skip": 0, "limit": limit, "order_by": { "column": "-id" } }))
        }
    }
    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>My Evaluation Project List</h3>
                        <div>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                            <RefreshButton api={myProjectResources} filter={filterQuery} style={{ marginTop: 0 }} />
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={myProjectList && myProjectList.rows ? myProjectList.rows : []}
                        columns={EvaluationProjectColumns.concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"lg"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{'Review for evaluation questions'}</DialogTitle>

                    <DialogContent style={{ height: '500px' }}>
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label> Project Name </label>
                                    <input
                                        name="project_id"
                                        value={tempData.project && tempData.project.project_name}
                                        type="text"
                                        placeholder="Project Name"
                                        disabled={true}
                                    />
                                </div>
                                <div className="formInput">
                                    <label >
                                        Your Project Role
                                    </label>
                                    <input
                                        name="resource_role_id"
                                        value={tempData.role && tempData.role.role_name}
                                        type="text"
                                        placeholder="Name"
                                        disabled={true}
                                    />
                                </div>
                                <div className="formInput">
                                    <label >
                                        Select Project Resources
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder={'Select Project Resource'}
                                        defaultValue={formValues.project_resource}
                                        isSearchable={true}
                                        name={'project_resource'}
                                        onChange={handleChange}
                                        options={
                                            tempProjectResource && tempProjectResource.length > 0 ? tempProjectResource.map((option) => {
                                                return { label: option.resource.display_name, value: option.resource_id, name: 'project_resource' }
                                            }) : []
                                        }
                                    />
                                    {/* <input
                                        name="q_type"
                                        defaultValue={formValues.q_type}
                                        type="text"
                                        placeholder="question type"
                                        onChange={handleChange}
                                    /> */}
                                    {errStatus === true && formValues && !formValues.project_resource && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.project_resourceErr}</p>}
                                </div>
                                <div className="formInput">
                                    {formValues.project_resource &&
                                        <>
                                            <label >
                                                Resource Project Role
                                            </label>
                                            <input
                                                name="project_role_id"
                                                value={tempProjectResource.filter(x => x.resource_id === formValues.project_resource)[0]?.role?.role_name}
                                                type="text"
                                                placeholder="Name"
                                                disabled={true}
                                            />
                                        </>
                                    }
                                </div>
                                {activeModalType === 'review' &&
                                    <div className="formInput">
                                        <label >
                                            Is Not Applicable
                                        </label>
                                        <Checkbox name="not_applicable" checked={formValues.not_applicable} onChange={handleChange} />
                                        {/* {errStatus === true && formValues && formValues.code === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.categoryErr}</p>} */}
                                    </div>}
                                {/* {activeModalType === 'create' &&
                                    <div className="formInput">
                                        <label >
                                            Question Type
                                        </label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder={'Select'}
                                            defaultValue={formValues.q_type}
                                            isSearchable={true}
                                            name={'q_type'}
                                            onChange={handleChange}
                                            options={
                                                cesQuestionTypeList && cesQuestionTypeList.data && cesQuestionTypeList.data.rows && cesQuestionTypeList.data.rows.length > 0 ? cesQuestionTypeList.data.rows.map((option) => {
                                                    return { label: option.question_type, value: option.question_type_key, name: 'q_type' }
                                                }) : []
                                            }
                                        />
                                    
                                        {errStatus === true && formValues && formValues.q_type === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.status_nameErr}</p>}
                                    </div>} */}
                                {activeModalType === 'review' && formValues.not_applicable === false && evaluationQuest_list && evaluationQuest_list.data && evaluationQuest_list.data.rows && evaluationQuest_list.data.rows.length > 0 &&
                                    <div className="formInput">
                                        <label >
                                            Attachement <Tooltip title={"Allow files (Doc, DOCX, XL, XLS, PPT, PDF, PNG, JPEG,GIF, MP3 and MP4)"}><InfoRounded style={{ marginBottom: '-5px' }} /></Tooltip>
                                        </label>
                                        <input
                                            id='fileUpload'
                                            //ref={uploadInputRef}
                                            type='file'
                                            multiple
                                            accept='application/pdf, video/mp4, video/mp3, image/png,image/jpeg, image/gif,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                            //style={{ display: "none" }}
                                            onChange={onFileChange}
                                            name="file"
                                        />
                                        {/* 
                                        <Tooltip title="Attach File">
                                            <span>
                                                <AttachFile
                                                    className={`attach_con`}
                                                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                                />
                                            </span>
                                        </Tooltip> */}
                                    </div>
                                }

                                {activeModalType === 'review' && formValues.not_applicable !== true && evaluationQuest_list && evaluationQuest_list.data && evaluationQuest_list.data.rows &&
                                    <>
                                        {evaluationQuest_list.data.rows.length > 0 ?
                                            <div>
                                                <br />
                                                {evaluationQuest_list.data.rows.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {item.questions && item.questions.length > 0 && item.questions.map((e, i) => {
                                                                return (
                                                                    <div key={i}>
                                                                        <p style={{ marginTop: "15px" }}><span style={{ fontWeight: 'bold', marginRight: '5px', marginTop: "10px" }}>Question {i + 1} : </span>{e.question}</p>
                                                                        <table>
                                                                            <thead />
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="text-dark">Star Rating * </td>
                                                                                    <td>
                                                                                        <Rating
                                                                                            value={formValues[e.id] && Number(formValues[e.id].rating_value)}
                                                                                            // value={Number(formValues[`rating_value_${e.id}`])}
                                                                                            name={`rating_value`}
                                                                                            onChange={(evt) => {
                                                                                                handleChange(evt, e.id, item.id);
                                                                                            }}
                                                                                        />
                                                                                        {errStatus === true && formValues &&
                                                                                            ((!formValues[e.id]) || (formValues[e.id] && formValues[e.id].rating_value === 0)) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.rating_valueErr}</p>}

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-dark">Comment(optional) </td>
                                                                                    <td>
                                                                                        <textarea style={{ width: '600px', padding: '5px', borderRadius: '5px' }} name={`comments`} onChange={(evt) => handleChange(evt, e.id)} rows="3" placeholder="Comments"></textarea>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-dark">Attachment(optional)<Tooltip title={"Allow files (Doc, DOCX, XL, XLS, PPT, PDF, PNG, JPEG,GIF, MP3 and MP4)"}><InfoRounded style={{ marginBottom: '-5px' }} /></Tooltip> </td>
                                                                                    <td>
                                                                                        <input
                                                                                            id='fileUpload'
                                                                                            //ref={uploadInputRef}
                                                                                            type='file'
                                                                                            multiple
                                                                                            accept='application/pdf, video/mp4, video/mp3,image/png,image/jpeg,image/gif,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                                                            //style={{ display: "none" }}
                                                                                            onChange={(evt) => onFileChange(evt, e.id)}
                                                                                            name={`file_${e.id}`}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>)
                                                            })}
                                                        </div>
                                                    )
                                                })}
                                                <div className="formInputDescription" style={{ marginTop: "10px" }}>
                                                    <label >
                                                        Overall Review (optional)
                                                    </label>

                                                    <textarea defaultValue={formValues.review_text} name="review_text" onChange={handleChange} rows="3" placeholder="Over all comments"></textarea>
                                                </div>
                                            </div>

                                            :
                                            <p style={{ color: "#96332c", fontSize: 13 }}>{'Question Set is not available'}</p>}
                                    </>
                                }
                                {formValues.not_applicable === true && activeModalType === 'review' &&
                                    <div className="formInputDescription">
                                        <label >
                                            Comment *
                                        </label>

                                        <textarea defaultValue={formValues.review_text} name="review_text" onChange={handleChange} rows="3" placeholder="comments"></textarea>
                                        {errStatus === true && formValues && formValues.review_text === '' && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.commetErr}</p>}

                                    </div>}

                                {/* <div className="formInput">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formValues.status}
                                            onChange={(e) => { handleChange(e) }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                        {errStatus === true && formValues && formValues.status === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.statusErr}</p>}
                                    </FormControl>
                                </div> */}


                                {/* <div className="formInputDescription">
                                    <h6>Create Questions <AddCircleOutline style={{ cursor: 'pointer', marginBottom: '-5px' }} onClick={() => addMoreQuestion(addQues)} /></h6>
                                    {addQues && addQues.length > 0 &&
                                        addQues.map((e, i) => {
                                            return (
                                                <p>
                                                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}> Question {i + 1}</span>
                                                    <textarea
                                                        style={{ minWidth: '91%' }}
                                                        name={'question'}
                                                        value={e.question}
                                                        type="text"
                                                        rows="3"
                                                        placeholder="Question"
                                                        onChange={(e) => handleQuestionChange(e, i)} />
                                                    <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeAddQues(i)} />
                                                </p>
                                            )
                                        })}
                                    {errStatus === true && addQues && addQues.length === 0 && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.quesErr}</p>}

                                </div> */}

                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>

            </div>
        </div >
    )
}

export default EvaluationQuestionReview