import './resourceCredential.scss'
import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import dataService from '../../redux/services/data.service';
import { toast, ToastContainer } from 'react-toastify'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import LoaderForm from '../../components/common-function/LoaderForm';
import Select from 'react-select';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";

import { Tooltip, Checkbox } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { allResources, allcredentialHeads } from '../../redux/features/cedentialHeadSlice';
import { useDispatch, useSelector } from 'react-redux';


export default function AddResourceCredential() {

  const urlKey = useLocation().state;
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { resourceList, credentialHeadList } = useSelector(state => state.credentialHead)

  const [credentialHead, setCredentialHead] = useState([])
  const [resourceData, setResourceData] = useState([])

  const [showLoader, setShowLoader] = useState(false)
  const [showResource, setShowResource] = useState(true)

  const [formData, setFormData] = useState({
    credentails_head: "",
    resource: "",
    // status: "false",
    is_global: false,
    value: [
      {
        key: "",
        value: "",
        status: false
      }
    ]
  })


  useEffect(() => {
    dispatch(allResources())
    dispatch(allcredentialHeads())
    // setTimeout(() => {
    //   setShowLoader(false)
    // }, 800)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (resourceData && resourceData.length === 0 && resourceList && resourceList.data) {
    let array = []
    array = resourceList.data && resourceList.data.rows
    array.length > 0 && setResourceData(array)
  }

  if (credentialHead && credentialHead.length === 0 && credentialHeadList && credentialHeadList.data) {
    let array = []
    array = credentialHeadList.data && credentialHeadList.data.rows
    array.length > 0 && setCredentialHead(array)
  }

  const handleChange = (evt, index = null) => {
    let { name, type, value, checked } = evt.target || evt;

    value = type === "checkbox" ? checked : value;
    // console.log(name, checked, value, "evt")
    if (name === 'is_global' && checked === true) {
      setShowResource(false)
    } else {
      setShowResource(true)
    }

    const valueArray = formData.value && formData.value.map((item, i) => i === index ?
      { ...item, [name]: value } : item);
    index !== null ? setFormData({ ...formData, value: [...valueArray] }) : setFormData({ ...formData, [name]: value })

  }

  const addMoreCredential = () => {
    setFormData({
      ...formData, value: [...formData.value,
      {
        key: "",
        value: "",
        status: false
      }]
    })
  }

  const removeCredential = (i) => {
    const updateformData = formData && formData.value.filter((item, index) => index !== i);
    setFormData({ ...formData, value: updateformData })
  }

  const loadDataById = async () => {
    setShowLoader(true)

    const response = await dataService.resourceCredentialById(id).catch(error => {
      setShowLoader(false)
      return error;
    });
    if (response && response.data && response.data.data) {
      setShowLoader(false)
      const valueArr = response.data.data.value;
      const result = response.data.data
      const is_global = result.is_global;
      is_global === true ? setShowResource(false) : setShowResource(true)

      // const status = response.data.data.status;
      setFormData({
        ...formData,
        credentails_head: { "value": result.credentails_head_id, "label": result.credentails_head && result.credentails_head.head_name },
        resource: result.resource_id === null ? "" : { "value": result.resource_id, "label": result.resource && result.resource.name },
        is_global: is_global,
        // status: status,
        value: valueArr
      })
    } else {
      setShowLoader(false)
    }
  }

  useEffect(() => {
    id && loadDataById()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  //submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const request = {
      ...formData,
      created_by: LocalStorageDataService.userDetail().id,
      credentails_head: formData.credentails_head.value ? formData.credentails_head.value : formData.credentails_head,
      resource: formData.resource.value ? formData.resource.value : formData.resource,
    }
    if (request.is_global === true) {
      request.resource = null
    }

    // validation
    if (!formData.credentails_head) {
      return toast.error('Credential head is required')
    } else if (request.resource === '') {
      return toast.error('Resource is required')
    } else if (formData.status === "") {
      return toast.error('Status is required')
    }
    // api calling post and put
    const response = !id ?
      await dataService.addResourceCredential(request) : await dataService.updateResourceCredential(id, request)

    if (response && response.data && response.data.status === 200) {
      toast.success(response.data.message);
      setTimeout(() => {
        if (urlKey && urlKey === "myResource") {
          navigate('/my-resource-credential')
        } else {
          navigate('/all-resource-credential')
        }
      }, 800)
    } else {
      toast.error(response.data.message)
    }
  }

  return (
    <div className="newCredential">
      <Sidebar />

      <div className="newCredentialContainer">
        <Navbar />

        <div className="top-headings">
          <h3>{!id ? "Add Resource Credential" : "Edit Resource Credential"}</h3>
          
          <Tooltip title="Back">
              <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
          </Tooltip>

        </div>

        {
          showLoader ? <LoaderForm />
            : <form onSubmit={handleSubmit}>

              <ToastContainer />

              <div className="add-new-user-form">
                <div className="formInput">
                  <label>
                    Credential Head
                  </label>
                  <Select
                    className="select-a"
                    classNamePrefix="select"
                    defaultValue={formData.credentails_head}
                    isDisabled={id && formData.credentails_head}
                    isSearchable={true}
                    name={"credentails_head"}
                    onChange={(e) => handleChange(e, null)}
                    options={
                      credentialHead && credentialHead.map((option) => {
                        return { label: option.head_name, value: option.id, name: "credentails_head" }
                      })
                    }
                  />

                </div>
                {
                  urlKey !== "myResource" &&
                  <div className="formInputCheckBox">
                    <label >
                      Is Global
                    </label>
                    <Checkbox
                      className="checkbox"
                      inputProps={{ 'aria-label': 'controlled' }}
                      name={"is_global"}
                      defaultChecked={formData.is_global}
                      onChange={(e) => handleChange(e, null)}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                    />
                  </div>
                }


                <div className="formInput">
                  {
                    showResource && formData.is_global === false &&
                    <>
                      <label>
                        Resource Name
                      </label>
                      <Select
                        className="select-a"
                        classNamePrefix="select"
                        defaultValue={formData.resource ? formData.resource : ''}
                        // isDisabled={id && formData.resource}
                        isSearchable={true}
                        name={"resource"}
                        options={
                          resourceData && resourceData.map((option) => {
                            return { label: option.name + "(" + option.email + ")", value: option.id, name: "resource" }
                          })
                        }
                        onChange={(e) => handleChange(e, null)}
                      />
                    </>
                  }
                </div>

                {/* <div className="formInput">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="status"
                      value={formData.status}
                      onChange={(e) => { handleChange(e) }}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Active" />
                      <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                    </RadioGroup>

                  </FormControl> 
                </div> */}

              </div>

              <div className="add-new-user-form">
                <div className="formInput">
                  <div className="form-credencial-row">
                    <h3>Credentials</h3>
                    <span onClick={() => addMoreCredential()}><AddCircleOutlineIcon /> Add More</span>
                  </div>
                </div>
              </div>

              {
                formData && formData.value && formData.value.map((item, i) => {
                  return (
                    <div className="add-new-user-form" key={i}>

                      <div className="formInput20">
                        <label >
                          Credential Key
                        </label>
                        <input
                          name={"key"}
                          onChange={(e) => handleChange(e, i)}
                          type="text"
                          placeholder="Credential Key"
                          value={item.key}
                        />
                      </div>

                      <div className="formInput20">
                        <label >
                          Credential Value
                        </label>
                        <input
                          name={"value"}
                          onChange={(e) => handleChange(e, i)}
                          type="text"
                          placeholder="Credential Value"
                          value={item.value}
                        />
                      </div>

                      <div className="formInput20">
                        <label >
                          Status
                        </label>
                        <select
                          name={"status"}
                          defaultValue={item.status}
                          onChange={(e) => handleChange(e, i)}>
                          <option value={true} label="">Active</option>
                          <option value={false} label="">Inactive</option>
                        </select>
                      </div>

                      <div className="formInput10">
                        {
                          i !== 0 ? <div className="form-credencial-row-remove">
                            <span onClick={() => removeCredential(i)}><RemoveCircleOutlineIcon /> Remove</span>
                          </div> : ""
                        }
                      </div>

                    </div>
                  )
                })
              }

              <button type="submit" className="btn btn-submit">Submit</button>
            </form>
        }

      </div >

    </div >
  )
}
