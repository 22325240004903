import * as React from 'react';
import "../master.scss"
import Navbar from "../../../components/navbar/Navbar"
import Sidebar from "../../../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, DialogTitle, TablePagination, Tooltip, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { ReportMasterColumns } from "../../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportMasterList, reportMasterUpdate, reportMasterEdit, reportMasterCreate } from "../../../redux/features/masterSlice";
import { Add, ArrowBack, Close } from "@mui/icons-material";
import { STATUSES } from "../../../redux/common-status/constant";
import { InputFields, InputRadioGroupFields, InputTextareaField } from "../../../components/common-function/InputFields";
import CONSTANTS from "../../../components/constant/constantComponents";
import LoaderForm from "../../../components/common-function/LoaderForm";
import UcActionComponent from "../../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../../components/TablePaginationActions";
import CustomNoRowsOverlay from '../../../components/CustomEmptyOverlay';

const initialFormData = Object.freeze({
    report_name: "",
    report_key: "",
    description: "",
    status: "",
});

const formErrData = Object.freeze({
    nameErr: "Report name is required",
    descErr: "Description is required",
    statusErr: "Status is required"
});

const Qualification = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);

    const { status, error, reportMaster_List, reportMaster_Create, reportMaster_Edit, reportMaster_update } = useSelector(state => state.master)
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)

    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25}`)
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        dispatch(getReportMasterList(filterQuery))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    if (reload === true && status === `${STATUSES.SUCCESS}_reportMaster_update` && reportMaster_update && reportMaster_update.message) {
        if (reportMaster_update.status === 200) {
            toast.success(reportMaster_update.message)
            dispatch(getReportMasterList(filterQuery))
        } else {
            toast.error(reportMaster_update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_reportMaster_Create` && reportMaster_Create && reportMaster_Create.message) {
        if (reportMaster_Create.status === 200) {
            toast.success(reportMaster_Create.message)
            dispatch(getReportMasterList(filterQuery))
        } else {
            toast.error(reportMaster_Create.message)
        }
        setReload(false)
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.report_name === "" || formValues.description === "" || formValues.status === "") {
            setErrStatus(true)
        }
        if (formValues.report_name !== "" && formValues.description !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user && rtm_user.user && rtm_user.user.data && rtm_user.user.data.id
            let addvalues = { ...formValues, created_by: userId }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(reportMasterUpdate({ ID, addvalues }))
            } else {
                dispatch(reportMasterCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && reportMaster_Edit && reportMaster_Edit.data) {
        let editData = reportMaster_Edit.data
        setFormValues({
            report_name: editData.report_name,
            report_key: editData.report_key,
            description: editData.description,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(reportMasterEdit(id))
        setReload('edit_data')
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getReportMasterList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getReportMasterList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(reportMaster_List && reportMaster_List.data && reportMaster_List.data.rows && reportMaster_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: "100",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            moduleName="report-master"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={false}
                            editButton={handleClickEdit}
                            viewButton={false}
                        />
                    </div>
                );
            },
        },
    ];

    //custom filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getReportMasterList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getReportMasterList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getReportMasterList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
        }
    }

    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />
                <ToastContainer />
                {/* table section */}
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Report List</h3>
                        <div>
                            <Tooltip title="Add New Report">
                                <button onClick={handleOpen} className="btn-add-new"  >
                                    <Add />
                                </button>
                            </Tooltip>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"  >
                                    <ArrowBack />
                                </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={reportMaster_List && reportMaster_List.data && reportMaster_List.data.rows ? reportMaster_List.data.rows : []}
                        columns={ReportMasterColumns.concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                {/* form section */}
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description">
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add New'} Report</DialogTitle>
                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div className='formInput' style={{ flexBasis: "100%" }}>
                                    <InputFields
                                        label={"Report Name"}
                                        name="report_name"
                                        defaultValue={formValues.report_name}
                                        type="text"
                                        placeholder="Report Name"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.nameErr}
                                    />
                                </div>
                                <InputTextareaField
                                    label={"Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descErr}
                                />
                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </div >
    )
}
export default Qualification