import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

//////Interview apis///////
export const getAllInterviewList = createAsyncThunk("interview/all-list", async (data) => {
    const res = await dataService.all_interview_list(data);
    return res.data;
});

export const getInterviewList = createAsyncThunk("interview/list", async (data) => {
    const res = await dataService.interview_list(data);
    return res.data;
});

export const interviewCreate = createAsyncThunk("interview/create", async (data) => {
    const res = await dataService.interview_create(data);
    return res.data;
});

export const interviewEdit = createAsyncThunk("interview/edit", async (id) => {
    const res = await dataService.interview_edit(id);
    return res.data;
});

export const interviewUpdate = createAsyncThunk("interview/update", async (data) => {
    const res = await dataService.interview_update(data.ID, data.addvalues);
    return res.data;
});

export const interviewFeedbackUpdate = createAsyncThunk("interview-feedback/update", async (data) => {
    // const res = await dataService.interview_feedback_update(data.ID, data.addvalues);
    const res = await dataService.interview_feedback_add(data);
    return res.data;
});


export const interviewArchive = createAsyncThunk("interview/archive", async (id) => {
    const res = await dataService.interview_archive(id);
    return res.data;
});

export const checkManageAvlList = createAsyncThunk("check-manage-avl/list", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.check_manage_avl_list(data);
    return res.data;
});

export const getcommentInterviewerAgentList = createAsyncThunk("interviewer-agent-comment/list", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.comment_interviewer_list(data);
    return res.data;
});
// reschedule interview
export const resheduleInterview = createAsyncThunk("interview/reschedule", async (data) => {
    const res = await dataService.interview_reschedule(data.ID, data.addvalues);
    return res.data;
});

export const availabiltyCreate = createAsyncThunk("availabilty/create", async (data) => {
    const res = await dataService.create_availability(data);
    return res.data;
});

export const getAvailabiltyList = createAsyncThunk("availabilty/list", async (data) => {
    const res = await dataService.get_availability(data);
    return res.data;
});

export const availabiltyEdit = createAsyncThunk("availabilty/edit", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.edit_availability(data);
    return res.data;
});

export const availabiltyUpdate = createAsyncThunk("availabilty/upadte", async (data) => {
    const res = await dataService.update_availability(data.ID, data.addvalues);
    return res.data;
});

export const availabiltyFromDateFilter = createAsyncThunk("availabilty/from-date-filter", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.availability_by_fromDate(data);
    return res.data;
});

export const availabiltyDetails = createAsyncThunk("availabilty-details/view", async (data) => {
    const res = await dataService.get_availability_details_ById(data);
    return res.data;
});

export const getUserDetails = createAsyncThunk("user-details-api", async () => {
    const res = await dataService.getUserDetailsApi();
    return res.data;
});

//get feedback details by id
export const getHeadsDetalsOnFeedback = createAsyncThunk("get-feed-back-details", async (data) => {
    if (data === 'type') {
        return []
    }
    const res = await dataService.get_feedback_heads(data);
    return res.data;
});

const interviewSlice = createSlice({
    name: "interview",
    initialState: {
        status: 'idle',
        error: '',

        interview_AllList: [],
        interview_Create: [],
        interview_List: [],
        interview_Edit: [],
        interview_Update: [],
        interview_Archive: [],
        interview_feedback_Update: [],
        interview_reshedule: [],
        interview_reschedule_request_count_list: [],
        manageAvlList: [],
        commentInterviewerAgentList: [],
        manageAvailability_List: [],
        manageAvailability_Create: [],
        manageAvailability_status: STATUSES.IDLE,
        manageAvailability_Edit: [],
        manageAvailability_Update: [],
        manageAvailability_Details_View: [],
        manageAvailability_fromDate: [],

        user_Details: [],
        headDetails_feedBack: [],
    },
    reducers: {},
    extraReducers(builder) {
        builder
            ///all interview  list
            .addCase(getAllInterviewList.pending, (state, action) => {
                state.status = `${STATUSES.LOADING}__interview`;
            })
            .addCase(getAllInterviewList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.interview_AllList = action.payload;
                state.interview_reschedule_request_count_list = !action.payload.data.rows ? 0 : action.payload.data.rows.filter((e) => { return (e.interview_status__status_key === 'request_to_reschedule' || e.interview_status__status_key === 'reschedule_request') })
            })
            .addCase(getAllInterviewList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd interview list
            .addCase(getInterviewList.pending, (state, action) => {
                state.status = `${STATUSES.LOADING}_interview`;
            })
            .addCase(getInterviewList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.interview_List = action.payload;
            })
            .addCase(getInterviewList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///interview create
            .addCase(interviewCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(interviewCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_interview_Create`;
                state.interview_Create = action.payload;
            })
            .addCase(interviewCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///interview edit
            .addCase(interviewEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(interviewEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.interview_Edit = action.payload;
            })
            .addCase(interviewEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///interview Update
            .addCase(interviewUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(interviewUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_interview_Update`;
                state.interview_Update = action.payload;
            })
            .addCase(interviewUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///interview archive
            .addCase(interviewArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(interviewArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_interview_Archive`;
                state.interview_Archive = action.payload;
            })
            .addCase(interviewArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///interview feedback Update
            .addCase(interviewFeedbackUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(interviewFeedbackUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_interview_feedback_Update`;
                state.interview_feedback_Update = action.payload;
            })
            .addCase(interviewFeedbackUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///check manage availability
            .addCase(checkManageAvlList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(checkManageAvlList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_manageAvlList`;
                state.manageAvlList = action.payload;
            })
            .addCase(checkManageAvlList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///Interviewer agent comment list
            .addCase(getcommentInterviewerAgentList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getcommentInterviewerAgentList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_commentInterviewerAgentList`;
                state.commentInterviewerAgentList = action.payload;
            })
            .addCase(getcommentInterviewerAgentList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///interview reshedule Update
            .addCase(resheduleInterview.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resheduleInterview.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_interview_reshedule`;
                state.interview_reshedule = action.payload;
            })
            .addCase(resheduleInterview.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///create you availability
            .addCase(availabiltyCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(availabiltyCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_manageAvailability_Create`;
                state.manageAvailability_Create = action.payload;
                state.error = ""
            })
            .addCase(availabiltyCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get you availabilityuser_Details
            .addCase(getAvailabiltyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAvailabiltyList.fulfilled, (state, action) => {
                // state.manageAvailability_List = action.payload;
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_manageAvailability_List`;
                    // state.manageAvailability_List = action.payload;
                    let newArray = [];
                    let newArray2 = []
                    let result = action.payload;
                    let res = result && result.data && result.data.rows
                    let count = result && result.data && result.data.count
                    res.map(e => {
                        return newArray.push({
                            ...e,
                            id: e.id,
                            resource_id: e.interviewer_details && e.interviewer_details.resource_id,
                            from_date: e.from_date && e.from_date,
                            status: e.status
                        })
                    })
                    if (newArray.length > 0) {
                        newArray2 = { "data": { "rows": newArray, "count": count } }
                    }
                    state.manageAvailability_List = newArray2 && newArray2.data ? newArray2 : action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(getAvailabiltyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///availability edit
            .addCase(availabiltyEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(availabiltyEdit.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_manageAvailability_Edit`;
                state.manageAvailability_Edit = action.payload;
            })
            .addCase(availabiltyEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///availability Update
            .addCase(availabiltyUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""
            })
            .addCase(availabiltyUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_manageAvailability_Update`;
                state.manageAvailability_Update = action.payload;
                state.error = ""
            })
            .addCase(availabiltyUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///availability details view
            .addCase(availabiltyDetails.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(availabiltyDetails.fulfilled, (state, action) => {
                // state.status = `${STATUSES.SUCCESS}_manageAvailability_Details_View`;
                // state.manageAvailability_Details_View = action.payload;
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_manageAvailability_Details_View`;
                    let newArray = [];
                    let newArray2 = []
                    let res = result && result.data && result.data.rows
                    res.map(e => {
                        let filterData = newArray.filter((item) => item.day_code === e.day_code)
                        let availabilityArray = { 'availability_from': e.availability_from, 'availability_to': e.availability_to }
                        let availabilityToArray = []

                        if (filterData && filterData.length > 0) {
                            // eslint-disable-next-line
                            newArray.map((ele) => {
                                if (ele.day_code === e.day_code) {
                                    availabilityToArray.push({ 'availability_from': e.availability_from, 'availability_to': e.availability_to })
                                    // eslint-disable-next-line
                                    ele.availability_fromArr.map((itm) => {
                                        availabilityToArray.push(itm)
                                    })
                                    ele.availability_fromArr = availabilityToArray
                                }
                            })
                        } else if (filterData.length === 0) {
                            newArray.push({
                                ...e,
                                id: e.id,
                                day_code: e.day_code,
                                day_name: e.day_code === 0 ? "Monday" :
                                    e.day_code === 1 ? "Tuesday" :
                                        e.day_code === 2 ? "Wednesday" :
                                            e.day_code === 3 ? "Thursday" :
                                                e.day_code === 4 ? "Friday" :
                                                    e.day_code === 5 ? "Saturday" :
                                                        e.day_code === 6 ? "Sunday" :
                                                            "N/A",
                                availability_fromArr: [availabilityArray]
                            })
                        }
                        return newArray
                    })
                    if (newArray.length > 0) {
                        newArray2 = { "data": { "rows": newArray } }
                    }
                    state.manageAvailability_Details_View = newArray2 && newArray2.data ? newArray2 : action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(availabiltyDetails.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///get user details(technology)
            .addCase(getUserDetails.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getUserDetails.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_user_Details`;
                    state.user_Details = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(getUserDetails.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///availability based on from_date and resource_id
            .addCase(availabiltyFromDateFilter.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(availabiltyFromDateFilter.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_manageAvailability_fromDate`;
                state.manageAvailability_fromDate = action.payload;
            })
            .addCase(availabiltyFromDateFilter.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///heads details based on stroy_id
            .addCase(getHeadsDetalsOnFeedback.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getHeadsDetalsOnFeedback.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_headDetails_feedBack`;
                state.headDetails_feedBack = action.payload;
                // state.headDetails_feedBack = !action.payload.data.rows ? [] : action.payload.data.rows
            })
            .addCase(getHeadsDetalsOnFeedback.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

    },
});


export default interviewSlice.reducer;