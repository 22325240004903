import { Refresh } from "@mui/icons-material"
import { Tooltip } from "@mui/material"
import { useDispatch } from "react-redux"

export const RefreshButton = ({ api, filter, style }) => {
    const dispatch = useDispatch()
    const refresh = () => {
        dispatch(api(filter))
    }
    return (
        <button onClick={() => refresh()} style={style ? style : { marginTop: 12 }} className="btn-add-new ">
            <Tooltip title="Reload">
                <Refresh />
            </Tooltip>
        </button>
    )
}