import React, { memo } from 'react'
import { CopyBlock, Snippet } from 'react-code-blocks'

function ViewScreeningTest({ viewQuestionData, setOpenViewQuestion, setOpenScreenDetails, skipRowData }) {
    // console.log(skipRowData, "skipRowData")

    let candidateExamData = viewQuestionData && viewQuestionData.data && viewQuestionData.data?.rows[0]

    let questionArray = []
    let answerArray = {}

    if (candidateExamData && candidateExamData.all_question_data) {
        // eslint-disable-next-line
        candidateExamData.all_question_data.map(itm => {
            questionArray.push(itm)
        })
    }

    if (candidateExamData && candidateExamData.candidate_question_answer_data) {
        // eslint-disable-next-line
        candidateExamData.candidate_question_answer_data?.mcq.map(itm => {
            // answerArray.push(itm)
            let newObj = Object.entries(itm)
            answerArray[newObj[0][0]] = newObj[0][1]
        })
    }

    return (
        <div style={{ overflow: "scroll", height: "500px" }} className="container m-4 p-4 mx-auto h-min-screen grid grid-rows-1 grid-cols-1 items-center">
            {skipRowData.ces_candidate_schedule_id !== null && skipRowData.hiring_story_screenflow_status === 3 && skipRowData.is_skipped === true ?
                <div
                    style={{ backgroundColor: "revert", width: "100%", border: "revert", color: "#575757" }}
                >
                    This test is skipped by  <strong>{skipRowData?.skipped_by?.name}</strong><br />
                    <strong>Skipped Comment : </strong> {skipRowData?.remark}
                </div>
                : ""}
            <div className="submit-btn-sticky-screening">
                <button
                    className="btn-add-new"
                    onClick={() => {
                        setOpenViewQuestion(false)
                        setOpenScreenDetails(false)
                    }}
                >
                    Back
                </button>
            </div><br /><br />
            {questionArray && questionArray?.map((item, i) => {
                return (
                    <div className="wrapper" key={i}>
                        <p className="question-wrapper">
                            <strong>Question  {i + 1}
                                : </strong>{" "}
                            <span style={{ lineBreak: 'anywhere', color: '#000' }}>
                                <CopyBlock
                                    language="java"
                                    text={item.title}
                                    codeBlock
                                    theme={Snippet}
                                    showLineNumbers={false}
                                />
                            </span>
                        </p>
                        <p><strong>Answer : </strong></p>
                        {(
                            item && item?.answer_option.map((variant, ansIndex) => (
                                <div key={ansIndex} className="m-2 h-14 border-2 border-black mx-auto">
                                    <p style={{ paddingLeft: "70px" }}>
                                        <input
                                            disabled={true}
                                            type="radio"
                                            name={`site_name_${i}`}
                                            className="radio"
                                            checked={
                                                answerArray[questionArray[i]?.id] === variant.id
                                            }
                                            value={"ss"}
                                        />
                                        {" " + variant.answer_option}
                                    </p>
                                </div>
                            ))
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default memo(ViewScreeningTest)