import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { ThirdPartyColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { thirdPartyCreate, thirdPartyEdit, thirdPartyUpdate, getThirdPartyList } from "../../redux/features/thirdPartySlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, DialogTitle, Button, Tooltip, Dialog, DialogContent, TablePagination } from '@mui/material';
import * as React from 'react';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";

const initialFormData = Object.freeze({
    t_name: "",
    description: "",
    code: "",
    status: ""
});

const formErrData = Object.freeze({
    status_nameErr: "Third party name is required",
    descriptionErr: "Description is required",
    categoryErr: "Code is required",
    statusErr: "Status is required"
});


const ThirdParty = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, thirdParty_List, thirdParty_Create, thirdParty_Edit, thirdParty_Update, thirdParty_Archive } = useSelector(state => state.thirdParty)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.THIRD_PARTY_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');

    useEffect(() => {
        dispatch(getThirdPartyList(filterQuery))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (status === STATUSES.FAILURE && error) {
        setReload(false)
        errorHandling(error, navigate)
    }


    if (reload === true && status === `${STATUSES.SUCCESS}_thirdParty_Update` && thirdParty_Update && thirdParty_Update.message) {
        if (thirdParty_Update.status === 200) {
            toast.success(thirdParty_Update.message)
            dispatch(getThirdPartyList(filterQuery))
        } else {
            toast.error(thirdParty_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_thirdParty_Create` && thirdParty_Create && thirdParty_Create.message) {
        if (thirdParty_Create.status === 200) {
            toast.success(thirdParty_Create.message)
            dispatch(getThirdPartyList(filterQuery))
        } else {
            toast.error(thirdParty_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_thirdParty_Archive` && thirdParty_Archive && thirdParty_Archive.message) {
        if (thirdParty_Archive.status === 200) {
            toast.success(thirdParty_Archive.message)
            dispatch(getThirdPartyList(filterQuery))
        } else {
            toast.error(thirdParty_Archive.message)
        }
        setReload(false)
    }


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.t_name === "" || formValues.description === "" || formValues.code === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.t_name !== "" && formValues.description !== "" && formValues.code !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id
            let addvalues = { ...formValues, name: formValues.t_name, created_by: userId }
            delete addvalues.t_name;
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(thirdPartyUpdate({ ID, addvalues }))
            } else {
                dispatch(thirdPartyCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && thirdParty_Edit && thirdParty_Edit.data) {
        let editData = thirdParty_Edit.data
        setFormValues({
            t_name: editData.name,
            description: editData.description,
            code: editData.code,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(thirdPartyEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            name: data.name,
            code: data.code,
            description: data.description,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        let ID = data.id;
        setReload(true)
        dispatch(thirdPartyUpdate({ ID, addvalues }))
    }
    const archiveModalClose = () => {
        setArchiveOpen(false)
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(thirdParty_List && thirdParty_List.data && thirdParty_List.data.rows && thirdParty_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getThirdPartyList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.THIRD_PARTY_LIST_COLUMNS}] }`)
        }
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="third-party"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.THIRD_PARTY_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getThirdPartyList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"} ,"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.THIRD_PARTY_LIST_COLUMNS}]}`)
            dispatch(getThirdPartyList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.THIRD_PARTY_LIST_COLUMNS}]}`))
        }
    }
    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Third Party List</h3>
                        <div>

                            <Tooltip title="Add Third Party">
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getThirdPartyList} filter={filterQuery} style={{ marginTop: 0 }} />

                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={thirdParty_List && thirdParty_List.data && thirdParty_List.data.rows ? thirdParty_List.data.rows : []}
                        columns={ThirdPartyColumns.concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog

                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Add Third Party</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label >
                                        Third Party Name
                                    </label>
                                    <input
                                        name="t_name"
                                        defaultValue={formValues.t_name}
                                        type="text"
                                        placeholder="Name"
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formValues && formValues.t_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.status_nameErr}</p>}
                                </div>

                                <div className="formInput">
                                    <label >
                                        Code
                                    </label>
                                    <input
                                        name="code"
                                        defaultValue={formValues.code}
                                        type="text"
                                        placeholder="Code"
                                        onChange={handleChange}
                                    />
                                    {errStatus === true && formValues && formValues.code === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.categoryErr}</p>}
                                </div>

                                <div className="formInput">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formValues.status}
                                            onChange={(e) => { handleChange(e) }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                        {errStatus === true && formValues && formValues.status === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.statusErr}</p>}
                                    </FormControl>
                                </div>

                                <div className="formInputDescription">
                                    <label >
                                        Description
                                    </label>

                                    <textarea defaultValue={formValues.description} name="description" onChange={handleChange} rows="3" placeholder="Description"></textarea>

                                    {errStatus === true && formValues && formValues.description === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.descriptionErr}</p>}
                                </div>

                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>


                {/* for delete popup */}
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={archiveModalClose}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} third parrty`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default ThirdParty