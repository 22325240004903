import React from 'react'
import "./phonecallprogressbar.scss"
import MultiProgress from "react-multi-progress";
import { Link } from 'react-router-dom';

function CustomComponent({
	children,
	element,
	...rest
}) {
	return (
		<div
			{...rest} // adds all styles for rendering the progress bar
			style={{
				fontWeight: element.isBold ? 900 : 300,
			}}
		>
			{children}
		</div>
	);
}
export default function PhonecallProgressbar({ details }) {
	// console.log(details, "details")

	return (
		<div className="call-progess" >
			<MultiProgress
				transitionTime={1.2}
				elements={[
					{
						value: details.total_durations_in_and_out_percent,
						color: "green",
						isBold: false,
						showPercentage: true,
						textColor: "white",
						className: "progess-text",
					},
					{
						value: details.remain_in_percent,
						// color: "#0052cc",
						color: "gray",
						showPercentage: true,
						textColor: "#fff",
						isBold: false,
						className: "progess-text",
					},
				]}
				height={25}
				border="1px solid #ddd"
				className="custom-progressbar"
				component={CustomComponent}
			/>
			<div className="progress-overlay">
				<h6 className='progress-bar-hyperlink'>
					<Link to={"/my-call-details?key=total_call"} state={{ key: "total_call", isProgressBar: true }}>
						<span className="inbound">
							Total Call : {details.total_durations_in_and_out_percent}%
							({details.total_durations_in_and_out_hrs})
						</span>
					</Link>
				</h6>
				<h6 className='progress-bar-hyperlink'>
					<Link to={"/my-call-details?key=in-bound"} state={{ key: "in-bound", isProgressBar: true }}>
						<span className="outbound">
							In-bound Call : {details.total_durations_in_percent}%
							({details.total_durations_in_hrs})
						</span>
					</Link>
				</h6>
				<h6 className='progress-bar-hyperlink'>
					<Link to={"/my-call-details?key=out-bound"} state={{ key: "out-bound", isProgressBar: true }}>
						<span className="outbound1">
							Out-bound Call : {details.total_effective_durations_out_percent}%
							({details.total_effective_durations_out_hrs})
						</span>
					</Link>
				</h6>
				<h6>
					{/* Remain Call Percent */}
					<span className="totalbound">
						Remain Call : {details.remain_in_percent}%
						({details.remaining_total_effective_call_durations_in_and_out_hrs})
					</span>
				</h6>
			</div>
		</div>
	)
}
